export default class Popover {

    public static readonly PopoverSelector = ".as__popover";
    private static readonly ActiveClassname: string = 'as__popover--active';
    private static readonly IconSelector: string = '.as__popover__icon';
    private static readonly IconClassnameActive: string = 'as__popover__icon--active';
    private static readonly NoJsClassname: string = 'as__popover--noJs';

    private readonly element: HTMLElement;
    private readonly trigger: HTMLElement | null;
    private readonly triggerIcon: HTMLElement | null;
    private readonly elementId: string | null;
    private isActive: boolean = false;
    private readonly documentEventListener: EventListener;

    constructor(element : HTMLElement) {
        this.element = element;
        this.elementId = element.getAttribute('data-popover-id');
        this.trigger = document.querySelector(`[data-popover-trigger='${this.elementId}']`) || null;
        this.trigger ? this.triggerIcon = this.trigger.querySelector(Popover.IconSelector) : this.triggerIcon = null;
        this.documentEventListener = this.triggerClickHandler.bind(this);

        this.init();
    }

    private init (): void {
        this.element.classList.remove(Popover.NoJsClassname);
        this.addEvents();
    }

    private addEvents(): void {
        if(this.trigger === null) {
            return;
        }



        this.trigger.addEventListener('click', (event: MouseEvent) => {
            this.isActive = !this.isActive;
            if(this.isActive) {
                this.element.classList.add(Popover.ActiveClassname);
                this.triggerIcon ? this.triggerIcon.classList.add(Popover.IconClassnameActive) : null;
                setTimeout(()=>{
                    document.addEventListener('click', this.documentEventListener);
                },500);

                this.setComponentWidth();

            } else {
                this.element.classList.remove(Popover.ActiveClassname);
                this.triggerIcon ? this.triggerIcon.classList.remove(Popover.IconClassnameActive) : null;
                setTimeout(()=>{
                    document.removeEventListener('click', this.documentEventListener);
                },500);
                this.resetComponentWidth();
            }
        });
    }

    private clickedOutsideContainer (element: HTMLElement | null): boolean {

        if(element === null) {
            return false;
        }

        while (element = element.parentElement) {
            if(element === this.element) {
                return false;
            }
        }
        return true;
    };

    private setComponentWidth(): void {
        let offset: number = 26;
        let elementBoundaries = this.element.getBoundingClientRect();

        if(window.innerWidth - offset < elementBoundaries.right) {
            let newWidth = elementBoundaries.width - (window.innerWidth - elementBoundaries.right + offset);
            this.element.style.width = `${newWidth}px`;
            this.element.style.minWidth = '0';
        }
    }

    private resetComponentWidth(): void {
        this.element.removeAttribute('style');
    }

    private triggerClickHandler(event: any) {
        if(!event) {
            return;
        }

        const target = event.target as HTMLElement;
        if(target && this.clickedOutsideContainer(target) && target !== this.trigger) {
            this.element.classList.remove(Popover.ActiveClassname);
            this.triggerIcon ? this.triggerIcon.classList.remove(Popover.IconClassnameActive) : null;
            document.removeEventListener('click', this.documentEventListener);
            this.isActive = false;
            this.resetComponentWidth();
        }

    }
}

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll(Popover.PopoverSelector).forEach(element => {
        if(!(element instanceof HTMLElement)) {
            return;
        }
        new Popover(element);
    });
});
