import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import axios from "axios";

export default class LazyProductCard {
    protected readonly productCardElement: HTMLElement | null;
    protected readonly dataAttribute: string = 'data-product-card-product-id';
    protected readonly productId: number | null;

    public constructor(element: HTMLElement) {
        this.productCardElement = element;
        this.productId = parseInt(element.getAttribute(this.dataAttribute) ?? '');
        this.init()
    }

    private init() {
        if (!this.productCardElement || !this.productId || this.productId === 0) {
            return;
        }

        this.renderProductCard();
    }

    private renderProductCard() {
        const baseUrl = window.location.origin + '/shop/product-card';
        let requestOptions = {
            url: baseUrl + '?product=' + this.productId,
            config: { responseType: 'json' },
        };

        axios(requestOptions)
            .then((response) => {
                if (response.status === 200) {
                    return response.data;
                }
            })
            .then(resultHtml => {
                if (resultHtml.length === 0) {
                    return;
                }

                this.appendChildrenHtml(resultHtml);
            }).catch(e => {
                console.error(e);
            });
    }

    private appendChildrenHtml(childrenHtml: any): void {
        let element = this.htmlToElement(childrenHtml);
        if (!element) {
            return;
        }

        this.productCardElement?.appendChild(element);
    }

    private htmlToElement(html: String) {
        let div = document.createElement('div');
        div.innerHTML = html.trim();

        return div.firstChild;
    }
}

onDOMContentLoaded(() => {
    document.querySelectorAll('.dws__m14-product-card-lazy').forEach((element) => {
        if (element instanceof HTMLElement) {
            new LazyProductCard(element);
        }
    });
});