import BaseSlider, {BaseSliderOptions} from "../../components/base-slider/base-slider";
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import BaseSliderItem from "../../components/base-slider/base-slider-item";

onDOMContentLoaded(() => {
    document.querySelectorAll('.dws__m6-carousel-cross-selling__slider').forEach((carouselElement) => {
        if(!(carouselElement instanceof HTMLElement)) {
            return;
        }

        let isNotInfinite = Boolean(carouselElement.getAttribute('data-not-infinite'))

        const carousel = new BaseSlider(carouselElement, {
            selectors: {
                container: '.dws__m6-carousel-cross-selling__slider-container',
                items: '.dws__m6-carousel-cross-selling__slide'
            },
            core: {
                itemPosition: isNotInfinite ? 'start' : 'center',
            },
            grid: {
                adaptItemHeight: true,
                itemHeightCalculator: (item: BaseSliderItem, options: BaseSliderOptions) => {
                    const height = BaseSlider.DefaultOptions.grid.itemHeightCalculator(item, options);
                    if(options.grid.adaptItemHeight) {
                        return height + 10;
                    }
                    return height;
                }
            },
            nav: {
                enabled: true,
            },
            arrows: {
                selectors: {
                    prev: '.as__carousel__arrow--prev',
                    next: '.as__carousel__arrow--next'
                },
                classes: {
                    hidden: 'as__carousel__arrow--hidden'
                },
                stepType: 'item'
            },
            swipe: {
                stepType: 'item'
            },
            infinite: {
                enabled: !isNotInfinite
            }
        });
    });
});
