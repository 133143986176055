import FlashMessage from '../../components/flash-message/flash-message';
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

onDOMContentLoaded(() => {
    window.AS = window.AS || {};

    const options = {
        selectors: {
            container: '.dws__m87-flash-message'
        },
        states: {
            hasItems: 'dws__m87-flash-message--has-items'
        },
        template: (message: string, type: string) => `<div class="dws__m87-flash-message__item  dws__m87-flash-message__item--${type}"><div class="dws__m87-flash-message__icon-wrapper as__pa-2"><svg role="img" class="as__icon as__show dws__m87-flash-message__icon"><use xlink:href="#as__icon__${type}"></use></svg></div><span class="as__py-2 as__px-3">${message}</span></div>`
    };

    const containerElement = document.querySelector(options.selectors.container);

    if(!(containerElement instanceof HTMLElement)) return;

    const allowMultiple = Boolean(containerElement.hasAttribute(FlashMessage.DefaultOptions.attributes.allowMultiple));

    window.AS.FlashMessage = new FlashMessage(containerElement, {...options, allowMultiple: allowMultiple});
});
