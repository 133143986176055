import Autosuggestion from "../../components/autosuggestion/autosuggestion";
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import HeroSearch from "../m1-hero/hero";

interface SearchbarDom {
    wrapper: HTMLElement | null,
    input: HTMLInputElement | null,
}

interface SearchbarSelector {
    wrapper: string,
    input: string,
}

export default class Searchbar {
    protected readonly dom: SearchbarDom
    protected readonly selectors: SearchbarSelector

    public constructor(element: HTMLElement) {
        this.selectors = {
            wrapper: '.dws__m24-search__autosuggest-wrapper',
            input: '.dws__m24-search__text',
        }

        this.dom = {
            wrapper: element.querySelector(this.selectors.wrapper),
            input: element.querySelector(this.selectors.input),
        }

        this.init()
    }

    private init() {
        if (!(this.dom.wrapper instanceof HTMLElement) || !(this.dom.input instanceof HTMLInputElement)) {
            throw new Error("Container or Input HTML not found. " + 1661395356);
        }

        let autosuggestion = new Autosuggestion(this.dom.wrapper, this.dom.input);
        autosuggestion.filterOption = this.dom.input.getAttribute('data-search-document');
    }
}

onDOMContentLoaded(() => {
    const searchbar = document.querySelector('.dws__m24-search');
    if (!(searchbar instanceof HTMLElement)) {
        return;
    }

    new Searchbar(searchbar);
});