// @ts-ignore
import {polyfill} from 'smoothscroll-polyfill';

// initialize the smoothscroll polyfill
polyfill();

// region INTERFACES
export interface TopButtonElements {
    containerElement: Element | null,
    topButtonElement: Element | null
}
// endregion INTERFACES

/**
 * To-top button
 * scrolls to top of page onclick
 */
export default class TopButton {
    private static readonly States = {
        Visible: 'as__top-button--visible',
        Frozen: 'as__top-button--frozen'
    };

    public static readonly Selectors = {
        TopButton: '.as__top-button',
        Container: '.as__top-button__container'
    };

    private static readonly Attributes = {
        VisibilityOffset: 'data-visible-after',
        Target: 'href'
    };

    private elements : TopButtonElements;
    private visibilityOffset : number;
    private frozenOffset : number;
    private targetElement : Element | null;

    constructor(element : Element | null) {
        if (!(element instanceof Element)) {
            throw new Error('could not find top button container element');
        }

        this.elements = {
            containerElement: element,
            topButtonElement: element.querySelector(TopButton.Selectors.TopButton)
        };

        if (!(this.elements.topButtonElement instanceof Element)) {
            throw new Error('could not find top button element');
        }

        this.visibilityOffset = parseInt(this.elements.topButtonElement.getAttribute(TopButton.Attributes.VisibilityOffset) || '0', 10);
        this.frozenOffset = this.getBottomStyleVal(this.elements.topButtonElement);
        this.targetElement = document.querySelector(this.elements.topButtonElement.getAttribute(TopButton.Attributes.Target) || '');

        window.addEventListener('scroll', this.onScroll.bind(this), false);
        this.elements.topButtonElement.addEventListener('click', (event) => this.onClick(event), false);
        this.elements.topButtonElement.addEventListener('keydown', (event) => this.onKeydown(<any>event), false);
    }

    /**
     * Get computed value of 'bottom' style prop of element
     *
     */
    private getBottomStyleVal(element : Element) : number {
        const elementStyles = window.getComputedStyle(element),
              elementBottomString = elementStyles.getPropertyValue('bottom').match(/\d+/);

        return elementBottomString ? parseInt(elementBottomString[0], 10) : 0;
    }

    /**
     * Wrapper function for window scroll related functions
     *
     */
    private onScroll() : void {
        const currentScrollTop = window.pageYOffset;
        const topButton =  this.elements.topButtonElement;

        if (!(topButton instanceof Element)) {
            return;
        }

        this.toggleTopButton(topButton, currentScrollTop);
        this.freezeTopButton(topButton);
    }

    /**
     * Toggle top button element if current scroll top is higher than visibility offset
     *
     */
    private toggleTopButton(topButton : Element, currentScrollTop : number) : void {
        if (currentScrollTop > this.visibilityOffset) {
            topButton.classList.add(TopButton.States.Visible);
        } else {
            topButton.classList.remove(TopButton.States.Visible);
        }
    }

    /**
     * Freeze top button element in place if container element is in view
     *
     */
    private freezeTopButton(topButton : Element) : void {
        const containerElement = this.elements.containerElement;

        if (!(containerElement instanceof Element)) {
            return;
        }

        if (this.isWithinViewPort(containerElement, this.frozenOffset)) {
            topButton.classList.add(TopButton.States.Frozen);
        } else {
            topButton.classList.remove(TopButton.States.Frozen);
        }
    }

    /**
     * Check if element is in view based on element top
     *
     */
    private isWithinViewPort (element : Element, offset : number = 0) : boolean {
        const top = element.getBoundingClientRect().top,
              bottom = element.getBoundingClientRect().bottom;

        return (top > 0 || bottom > 0) && top + offset < window.innerHeight;
    }

    /**
     * Wrapper function for top button click related functions
     *
     */
    private onClick(event : Event) : void {
        event.preventDefault();

        if (!(this.targetElement instanceof Element)) {
            throw new Error('could not find target button element');
        }

        this.targetElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start'
        });
    }

    /**
     * Execute onClick-handler if "enter" key is pressed
     *
     */
    private onKeydown(event : KeyboardEvent) : void {
        if (!(event instanceof KeyboardEvent) || event.keyCode !== 13) {
            return;
        }

        this.onClick(event);
    }
}

document.addEventListener('DOMContentLoaded', () => {
    let topButtonContainers = document.querySelectorAll(TopButton.Selectors.Container);

    topButtonContainers.forEach(containerElement => {
        new TopButton(containerElement);
    });
}, false);
