export class BodyNoScroll {
    private static readonly Classes = {
        NoScroll: 'as__no-scroll'
    };

    private readonly noScrollStacks : {
        [key : string] : boolean
    };

    constructor() {
        this.noScrollStacks = {};
    }

    /**
     * Add or remove the as__no-scroll class to the body for a given identifier.
     */
    public toggleNoScroll(identifier : string, force ?: boolean) {
        if(typeof force !== "boolean") {
            force = !this.noScrollStacks[identifier];
        }

        if(force) {
            this.addNoScroll(identifier);
        } else {
            this.removeNoScroll(identifier);
        }
    }

    /**
     * Add the as__no-scroll class to the body for a given identifier
     *
     */
    public addNoScroll(identifier : string) {
        this.noScrollStacks[identifier] = true;
        this.toggleNoScrollBasedOnStacks();
    };

    /**
     * Removes the as__no-scroll class to the body for a given identifier
     *
     */
    public removeNoScroll(identifier : string) {
        this.noScrollStacks[identifier] = false;
        this.toggleNoScrollBasedOnStacks();
    };

    /**
     * Adds the as__no-scroll class if at least one noScrollStack is set to true, otherwise removes the class
     */
    private toggleNoScrollBasedOnStacks() {
        let hasNoScrollStacks = Object.keys(this.noScrollStacks).find(identifier => this.noScrollStacks[identifier]);
        document.body.classList.toggle(BodyNoScroll.Classes.NoScroll, Boolean(hasNoScrollStacks));
    };
}

export default new BodyNoScroll();
