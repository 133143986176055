import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import EventTarget, {createCustomEvent} from "../../base/util/dom/EventTarget";
import FlashMessage from '../../components/flash-message/flash-message';
import ProductCarouselPopup from "../../components/product-carousel-tile/product-carousel-tile"
import Mark from 'mark.js'

// region INTERFACES
export interface FactsheetToolbarSearchElements {
    containerElement: HTMLElement,
    inputElement: HTMLInputElement | null,
    toggleElement: HTMLImageElement | null,
    contentElement: HTMLElement | null,
    markElements: Array<HTMLElement>
}
// endregion INTERFACES

export default class FactsheetToolbarSearch extends EventTarget {
    public static readonly Selectors = {
        Container: '.dws__m88-factsheet-toolbar',
        Input: '.dws__m88-factsheet-toolbar__textsearch-input',
        Toggle: '.dws__m88-factsheet-toolbar__textsearch-toggle',
        Content: '.dws__m88-factsheet-toolbar__textsearch-content',
        Mark: 'mark'
    };

    private static readonly States = {
        MarkActive: 'active'
    }

    private elements: FactsheetToolbarSearchElements;
    private textsearchShown: boolean = false
    private isTypingTimeout: any
    private isTypingDelay: number = 300
    private markInstance: Mark|null = null
    private currentSearchTerm: string|null = null
    private searchTermMinimumCharCount: number = 3
    private textHasBeenMarked: boolean = false
    private currentScrollToMarkIndex: number = -1

    constructor(element : HTMLElement) {
        super();

        this.elements = {
            containerElement: element,
            inputElement: element.querySelector(FactsheetToolbarSearch.Selectors.Input),
            toggleElement: element.querySelector(FactsheetToolbarSearch.Selectors.Toggle),
            contentElement: document.querySelector(FactsheetToolbarSearch.Selectors.Content),
            markElements: []
        }

        window.AS = window.AS || {}

        if (window.AS.FlashMessage instanceof FlashMessage) {
            this.addEventListener('showFlashMessage', event => {
                window.AS.FlashMessage.insertFlashMessage(event.message, event.messageType)
            })
        }

        try {
            if (!this.elements.inputElement) throw new Error('1658743897 FactSheetToolbar JS – input element not found');
            if (!this.elements.toggleElement) throw new Error('1658744928 FactSheetToolbar JS – toggle element not found');
            if (!this.elements.contentElement) throw new Error('1658744943 FactSheetToolbar JS – content element not found');

            this.elements.toggleElement.addEventListener('change', this.onToggleElementChange.bind(this));
            this.elements.inputElement.addEventListener('keydown', () => { clearTimeout(this.isTypingTimeout) });
            this.elements.inputElement.addEventListener('keyup', this.onInputKeyUp.bind(this));
            this.elements.inputElement.addEventListener('keypress', (e) => { e.keyCode == 13 && this.onInputEnterKey(e) });
            this.markInstance = new Mark(this.elements.contentElement)
        } catch (error) {
            this.handleError(error)
        }
    }

    private onToggleElementChange(event: Event) {
        if (!event || !event.target || !(event.target instanceof HTMLInputElement)) throw new Error('1658744123 FactSheetToolbar JS – toggle element not found');

        this.textsearchShown = event.target.checked;

        !this.textsearchShown && this.resetTextsearchInput();
    }

    private resetTextsearchInput() {
        if (!this.elements.inputElement || !(this.elements.inputElement instanceof HTMLInputElement)) throw new Error('1658744278 FactSheetToolbar JS – input element not found');
        if (!this.markInstance) throw new Error('1658746248 FactSheetToolbar JS – no mark js instance');

        this.elements.inputElement.value = ''
        this.markInstance.unmark({
            done: () => {
                this.currentSearchTerm = null
                this.textHasBeenMarked = false
                this.elements.markElements = []
                this.currentScrollToMarkIndex = -1
            }
        })
    }

    private onInputKeyUp(event: Event) {
        clearTimeout(this.isTypingTimeout)
        this.isTypingTimeout = setTimeout(() => {
            if (!event || !(event.target instanceof HTMLInputElement)) throw new Error('1658744639 FactSheetToolbar JS – input element not found');
            if (!this.markInstance) throw new Error('1658746438 FactSheetToolbar JS – no mark js instance');

            if (event.target.value.length >= this.searchTermMinimumCharCount) {
                try {
                    this.markSearchedTerm(event.target.value)
                } catch (error) {
                    this.handleError(error)
                }
            } else {
                this.markInstance.unmark({
                    done: () => {
                        this.textHasBeenMarked = false
                        this.elements.markElements = []
                        this.currentScrollToMarkIndex = -1
                    }
                })
            }
        }, this.isTypingDelay);
    }

    private markSearchedTerm(searchedTerm: string = '') {
        if (!searchedTerm) throw new Error('1658745247 FactSheetToolbar JS – no search term given');
        if (!(this.elements.contentElement instanceof HTMLElement)) throw new Error('1658745418 FactSheetToolbar JS – no content element found');
        if (!this.markInstance) throw new Error('1658745901 FactSheetToolbar JS – no mark js instance');

        // Skip if search term is same
        if (searchedTerm === this.currentSearchTerm) {
            return;
        }

        this.markInstance.unmark({
            done: () => {
                if (!this.markInstance) throw new Error('1658745904 FactSheetToolbar JS – no mark js instance');

                this.markInstance.mark(searchedTerm, {
                    done: () => {
                        if (!this.elements.contentElement) throw new Error('1658747087 FactSheetToolbar JS – no content element found');

                        this.currentSearchTerm = searchedTerm
                        this.textHasBeenMarked = true
                        this.elements.markElements = Array.prototype.slice.call(this.elements.contentElement.querySelectorAll(FactsheetToolbarSearch.Selectors.Mark));
                        this.currentScrollToMarkIndex = -1
                    }
                });
            }
        })
    }

    private onInputEnterKey(event: KeyboardEvent) {
        if (!event || !(event.target instanceof HTMLInputElement)) throw new Error('1658744639 FactSheetToolbar JS – input element not found');

        if (event.target.value.length >= this.searchTermMinimumCharCount && this.textHasBeenMarked) {
            this.scrollToMarks()
        }
    }

    private scrollToMarks() {
        if (!this.elements.markElements.length) return

        this.scrollToMark(this.elements.markElements[this.currentScrollToMarkIndex + 1])
        this.toggleMarkStates(this.elements.markElements[this.currentScrollToMarkIndex + 1])

        if (this.currentScrollToMarkIndex + 1 >= this.elements.markElements.length -1) {
            this.currentScrollToMarkIndex = -1
        } else {
            this.currentScrollToMarkIndex++
        }
    }

    private scrollToMark(markElement : HTMLElement) {
        markElement.scrollIntoView()
    }

    private toggleMarkStates(currentMarkElement : HTMLElement) {
        if (!this.elements.markElements.length) return
        this.elements.markElements.forEach(markElement => markElement !== currentMarkElement && markElement.classList.remove(FactsheetToolbarSearch.States.MarkActive));
        currentMarkElement.classList.add(FactsheetToolbarSearch.States.MarkActive);
    }


    private handleError(message: string) {
        this.dispatchEvent(createCustomEvent('showFlashMessage', {
            message: message,
            messageType: 'alert-error'
        }));
        console && console.error && console.error(message)
    }
}


onDOMContentLoaded(() => {
    document.querySelectorAll('.dws__m88-factsheet-toolbar').forEach((factSheetToolbar) => {
        if(!(factSheetToolbar instanceof HTMLElement)) {
            return;
        }

        factSheetToolbar.querySelectorAll(ProductCarouselPopup.DefaultOptions.selectors.root).forEach(popupItem => {
            if (!(popupItem instanceof HTMLElement)) {
                return;
            }

            new ProductCarouselPopup(popupItem);
        })
    });

    document.querySelectorAll(FactsheetToolbarSearch.Selectors.Container).forEach((factSheetToolbar) => {
        if(!(factSheetToolbar instanceof HTMLElement)) {
            return;
        }

        new FactsheetToolbarSearch(factSheetToolbar)
    })
});
