import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

export default class M90bAssignLicense {
    public static readonly Selectors = {
        container: '.dws__m90b-assign-license',
        employeeCheckboxes: '.dws__m90b-assign-license__employee-checkbox',
        errorContainer: '.dws__m90b-assign-license__error-container',
        submitBtn: '.dws__m90b-assign-license__submit',
        errorText: '.dws__m90b-assign-license__error-text',
        productSelect: 'select',
        licenseInputs: '.dws__m90b-license'
    }

    public static readonly Attributes = {
        errorText: 'data-error-text',
        licenseProductId: 'data-product-id',
        licenseCount: 'data-license-count'
    }

    public static readonly States = {
        disabled: 'as__btn--disabled'
    }

    private elements: {
        container: HTMLFormElement,
        employeeCheckboxes: Array<HTMLInputElement>,
        errorContainer: HTMLElement|null,
        submitBtn: HTMLElement|null,
        productSelect: HTMLSelectElement|null,
        licenseInputs: Array<HTMLInputElement>
    }

    private static readonly ErrorStates = 'as__field-error';
    private static readonly ValidStates = 'as__field-valid';

    private static readonly ErrorText = (errorText : string) => `<span class="dws__m90b-assign-license__error-text as__info-error as__mt-8 as__mt-lg-6">${errorText}</span>`;

    private isFormValid: boolean = false
    private hasBeenValidated: boolean = false
    constructor(element: HTMLFormElement) {
        this.elements = {
            container: element,
            employeeCheckboxes: Array.prototype.slice.call(element.querySelectorAll(M90bAssignLicense.Selectors.employeeCheckboxes)),
            errorContainer: element.querySelector(M90bAssignLicense.Selectors.errorContainer),
            submitBtn: element.querySelector(M90bAssignLicense.Selectors.submitBtn),
            productSelect: element.querySelector(M90bAssignLicense.Selectors.productSelect),
            licenseInputs: Array.prototype.slice.call(element.querySelectorAll(M90bAssignLicense.Selectors.licenseInputs))
        }

        if (!(this.elements.submitBtn instanceof HTMLElement)
            || !this.elements.employeeCheckboxes.length
            || !(this.elements.productSelect instanceof HTMLElement)) {
            return;
        }

        this.elements.employeeCheckboxes.forEach(employeeCheckbox => {
            employeeCheckbox.addEventListener('input', this.employeeCheckboxInputHandler.bind(this));
        });

        this.elements.productSelect.addEventListener('change', this.productSelectChangeHandler.bind(this));
    }

    private productSelectChangeHandler(event: Event): void {
        const element = event.target;
        if (!(element instanceof HTMLSelectElement)) return;

        this.elements.employeeCheckboxes.forEach(employeeCheckbox => {
            employeeCheckbox.checked = false;
            employeeCheckbox.disabled = false;
        });
    }

    private formSubmitHandler(event: Event): void {
        if (!event) {
            return;
        }

        event.preventDefault();

        this.hasBeenValidated = true;
        this.employeeCheckboxInputHandler();
    }

    private employeeCheckboxInputHandler(): void {
        this.updateEmployeeCheckboxesAvailability();
        if (!this.hasBeenValidated || !(this.elements.submitBtn instanceof HTMLElement)) {
            return;
        }

        this.setFormIsValidState();
        this.elements.employeeCheckboxes.forEach(employeeCheckbox => {
            this.toggleErrorStateOfInput(employeeCheckbox);
            this.toggleValidStateOfInput(employeeCheckbox);
        });
        this.toggleErrorMsg();
        this.toggleSubmitStateOfButton(this.elements.submitBtn);
    }

    private updateEmployeeCheckboxesAvailability(): void {
        const selectedProductId = Number(this.elements.productSelect?.value);
        if (selectedProductId === 0) return;

        const selectedCount = (this.elements.employeeCheckboxes.filter(employeeCheckbox => employeeCheckbox.checked)).length;

        this.elements.licenseInputs.forEach(licenseInput => {
            const productId = Number(licenseInput.getAttribute(M90bAssignLicense.Attributes.licenseProductId));
            const licenseCount = Number(licenseInput.getAttribute(M90bAssignLicense.Attributes.licenseCount));

            if ((productId === 0) || (licenseCount === 0) || (productId !== selectedProductId)) return;

            let limitReached = false;
            if (selectedCount >= licenseCount) {
                limitReached = true;
            }

            this.elements.employeeCheckboxes.forEach(employeeCheckbox => {
               if (employeeCheckbox.checked) return;

               employeeCheckbox.disabled = limitReached;
            });
        });
    }

    // Check if at least one checkbox is checked
    private setFormIsValidState() {
        this.isFormValid = Boolean(this.elements.employeeCheckboxes.filter(employeeCheckbox => { return employeeCheckbox.checked }).length);
    }

    private toggleSubmitStateOfButton(button : HTMLElement): void {
        button.classList.toggle(M90bAssignLicense.States.disabled, !this.isFormValid);
    }

    private toggleErrorStateOfInput(input : HTMLInputElement) : void {
        input.classList.toggle(M90bAssignLicense.ErrorStates, !this.isFormValid);
    }

    private toggleValidStateOfInput(input : HTMLInputElement) : void {
        input.classList.toggle(M90bAssignLicense.ValidStates, this.isFormValid);
    }

    private toggleErrorMsg() : void {
        let errorText = this.elements.container.querySelector(M90bAssignLicense.Selectors.errorText),
            errorContainer = this.elements.container.querySelector(M90bAssignLicense.Selectors.errorContainer),
            errorTextString = this.getErrorText();

        if (!this.isFormValid && !errorText) {
            if (errorContainer) {
                errorContainer.insertAdjacentHTML('beforeend', errorTextString);
            } else {
                this.elements.container.insertAdjacentHTML('beforeend', errorTextString);
            }
        }

        if (this.isFormValid && errorText && errorText.parentNode) {
            errorText.parentNode.removeChild(errorText);
        }
    }

    private getErrorText() : string {
        let errorText = this.elements.container.getAttribute(M90bAssignLicense.Attributes.errorText),
            errorTextHML = '';

        if (errorText) {
            errorTextHML = M90bAssignLicense.ErrorText(errorText);
        }

        return errorTextHML;
    }
}


onDOMContentLoaded(() => {
    document.querySelectorAll(M90bAssignLicense.Selectors.container).forEach((element) => {
        if(!(element instanceof HTMLFormElement)) {
            return;
        }

        new M90bAssignLicense(element);
    });
});
