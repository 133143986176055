import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

export interface SumBoxStickyElements {
    sumBoxElement: HTMLElement | null,
    stickyElement: HTMLElement | null
}

export default class SumBoxSticky {
    public static readonly Selectors = {
        SumBoxContainer: '.dws__m27-sum-box',
        SumBoxElement: '.dws__m27-sum-box__wrapper',
        StickyContainer: '.dws__m27-sum-box-sticky'
    };

    private static readonly States = {
        hidden: 'dws__m27-sum-box-sticky--hidden'
    }

    private elements : SumBoxStickyElements;
    private scrollListener : any;
    private breakPoint : number;

    constructor(element: HTMLElement) {
        this.elements = {
            stickyElement: element.querySelector(SumBoxSticky.Selectors.StickyContainer),
            sumBoxElement: element.querySelector(SumBoxSticky.Selectors.SumBoxElement)
        }

        this.scrollListener = false
        this.breakPoint = 768;

        this.onResize();
        window.addEventListener('resize', this.onResize.bind(this))
    }

    private init() : void {
        this.onScroll();

        if (this.scrollListener) {
            return;
        }

        this.scrollListener = this.onScroll.bind(this);

        window.addEventListener('scroll', this.scrollListener, false);
    }

    private onResize() : void {
        if (window.innerWidth < this.breakPoint) {
            this.init();
        } else {
            this.destroy();
        }
    }

    private onScroll() : void {
        if (!(this.elements.sumBoxElement instanceof HTMLElement)) {
            throw new Error('could not find sum box element');
        }

        if (!(this.elements.stickyElement instanceof HTMLElement)) {
            throw new Error('could not find sticky element');
        }

        this.elements.stickyElement.classList.toggle(SumBoxSticky.States.hidden, window.pageYOffset + window.innerHeight >= this.elements.sumBoxElement.offsetTop);
    }

    private destroy() : void {
        if (!this.scrollListener) {
            return
        }

        window.removeEventListener('scroll', this.scrollListener);
        this.scrollListener = false
    }
}

onDOMContentLoaded(() => {
    const sumBoxContainer = document.querySelector(SumBoxSticky.Selectors.SumBoxContainer);
    if (!(sumBoxContainer instanceof HTMLElement)) {
        return;
    }
    new SumBoxSticky(sumBoxContainer);
});
