import onDOMContentLoaded from "./util/dom/onDOMContentLoaded";

declare global {
    interface Window {
        AS: any;
        dataLayer: Record<string, any>[];
        _mtm: Record<string, any>[];
        Cookiebot: any;
    }
}

onDOMContentLoaded(() => {
    const noJsElement = document.querySelector('.as__no-js');

    if(noJsElement) {
        noJsElement.classList.remove('as__no-js');
    }
});
