import axios, {AxiosResponse} from "axios";
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

export default class M90AddSubAccount {
    public static readonly Selectors = {
        container: '.dws__m90a-add-subaccount',
        toggles: '.dws__m90a-add-subaccount__product-toggle',
        additionalFields: '.dws__m90a-add-subaccount__product-additional-fields',
        productCheckboxes: '.dws__m90a-add-subaccount__product-checkbox'
    }

    private elements: {
        container: HTMLElement,
        toggles: Array<HTMLElement>,
        additionalFields: HTMLElement|null,
        productCheckboxes: Array<HTMLInputElement>,
    }

    constructor(element: HTMLElement) {
        this.elements = {
            container: element,
            toggles: Array.prototype.slice.call(element.querySelectorAll(M90AddSubAccount.Selectors.toggles)),
            additionalFields: element.querySelector(M90AddSubAccount.Selectors.additionalFields),
            productCheckboxes: Array.prototype.slice.call(element.querySelectorAll(M90AddSubAccount.Selectors.productCheckboxes)),
        }

        if (!this.elements.additionalFields) {
            return
        }

        this.elements.toggles.forEach((toggleElement) => {
            toggleElement.addEventListener('change', this.toggleChangeHandler.bind(this))
        })
    }

    private toggleChangeHandler(event: Event): void {
        if (!event || !(event.target instanceof HTMLInputElement)) {
            return
        }

        this.resetProductCheckboxes();
    }

    private resetProductCheckboxes() {
        this.elements.productCheckboxes.forEach(productCheckbox => {
            productCheckbox.checked = false;
        });
    }
}


onDOMContentLoaded(() => {
    window.AS = window.AS || {}

    document.querySelectorAll(M90AddSubAccount.Selectors.container).forEach((element) => {
        if(!(element instanceof HTMLElement)) {
            return;
        }

        new M90AddSubAccount(element);
    });
});
