import BaseSlider from "../../components/base-slider/base-slider";
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

onDOMContentLoaded(() => {
    document.querySelectorAll('.as__slider').forEach((sliderElement) => {
        if(!(sliderElement instanceof HTMLElement)) {
            return;
        }

        const sliderBreakpoint = 768;
        let isMobile = window.innerWidth < sliderBreakpoint;
        const sliderOptions = {
            arrows: {
                adaptHeightTo: '.dws__footer__service'
            },
            infinite: {
                enabled: false
            }
        }

        let footerSlider = new BaseSlider(sliderElement, sliderOptions);
    });
});