import { M85LearnControlQuizSlideColumnLabels, M85LearnControlQuizQuestion, M85LearnControlQuizFinishData, M85LearnControlQuizLabels } from "./learn-control-quiz";

export const questionTextTemplate = (questionText : string) =>
    `<div class="as__rte dws__m85-learn-control-quiz__question-text">
        <p class="as__strong">${questionText}</p>
    </div>`;

export const questionRadioTemplate = (question : M85LearnControlQuizQuestion) => {
    const checkedSubStr = question.checked ? ' checked="checked"' : '';

    return `<div class="as__radio">
                <input type="radio" class="as__radio__control" id="${question.id}" name="${question.name}" value="${question.value}"${checkedSubStr}>
                <label class="as__label as__radio__label as__radio__icon" for="${question.id}">${question.label}</label>
            </div>`
};

export const questionCheckboxTemplate = (question : M85LearnControlQuizQuestion) => {
    const checkedSubStr = question.checked ? ' checked="checked"' : '';

    return `<div class="as__checkbox">
                <input type="checkbox" class="as__checkbox__control" id="${question.id}" name="${question.name}" value="${question.value}"${checkedSubStr}>
                <label class="as__label as__checkbox__label as__checkbox__icon" for="${question.id}">${question.label}</label>
            </div>`
};

export const questionTrueFalseTemplate = (question : M85LearnControlQuizQuestion, columnLabels : M85LearnControlQuizSlideColumnLabels) => {
    function checkedSubStr(optionId: number) {
        return question.checked == `option-${optionId}` ? ' checked="checked"' : ''
    }

    return `<div class="dws__m85-learn-control-quiz__true-false-question">
                <span class="dws__m85-learn-control-quiz__true-false-question-label">${question.label}</span>
                <div class="as__radio-group">
                    <div class="as__radio">
                        <input type="radio" class="as__radio__control" id="${question.id}-option-1" name="${question.name}" value="${question.value}-option-1"${checkedSubStr(1)}>
                        <label class="as__label as__radio__label as__radio__icon" for="${question.id}-option-1">
                            <span class="dws__m85-learn-control-quiz__true-false-question-text">${columnLabels.firstColumn}</span>
                        </label>
                    </div>
                    <div class="as__radio">
                        <input type="radio" class="as__radio__control" id="${question.id}-option-2" name="${question.name}" value="${question.value}-option-2"${checkedSubStr(2)}>
                        <label class="as__label as__radio__label as__radio__icon" for="${question.id}-option-2">
                            <span class="dws__m85-learn-control-quiz__true-false-question-text">${columnLabels.lastColumn}</span>
                        </label>
                    </div>
                </div>
            </div>`
};

export const questionContentTemplate = (questionContent : string) =>
    `<div class="as__rte dws__m85-learn-control-quiz__question-content">
        <p>${questionContent}</p>
    </div>`;

export const questionFinishTemplate = (finishData : M85LearnControlQuizFinishData, labels: M85LearnControlQuizLabels) => {
    const contentTopStr = finishData.contentTop ? `<p>${finishData.contentTop}</p>` : '';

    let correctAnswersStr = '';
    const correctAnswersTopStr = typeof finishData.correctAnswers == 'number' && finishData.slideCount ? labels.correctAnswers.replace(/\d+|(?:%d)+/, finishData.correctAnswers.toString()).replace(/[\d%d]+(?!.*?[\d%d]+)/, finishData.slideCount.toString()) : ''
    const unansweredQuestionsStr = typeof finishData.unansweredQuestions == 'number' ? labels.unansweredQuestions.replace(/\d+|(?:%d)+/, finishData.unansweredQuestions.toString()) : ''

    correctAnswersStr = correctAnswersTopStr.length ? `<p><strong>${correctAnswersTopStr}</strong>` : '';
    correctAnswersStr += unansweredQuestionsStr.length ? `<br>${unansweredQuestionsStr}</p>` : '';

    let correctAnswersPercentageStr = typeof finishData.correctAnswersPercentage == 'number' ? '<strong>' + labels.correctAnswersPercentage.replace(/\d+|(?:%d)+/, finishData.correctAnswersPercentage.toString()) + '</strong>' : ''

    if (correctAnswersPercentageStr.length) {
        correctAnswersPercentageStr = correctAnswersPercentageStr.replace(/as__text\-color\-\-brand\-([a-z\-]+)/, finishData.showCertificateLink ? 'as__text-color--brand-secondary-green' : 'as__text-color--brand-primary-red');

        if (!finishData.showCertificateLink) {
            correctAnswersPercentageStr += `<br>${labels.certificatePercentage}`;
        }

        correctAnswersPercentageStr = `<p>${correctAnswersPercentageStr}</p>`
    }

    const solutionLink = finishData.solutionLink ? `<p><span class="as__link as__link--icon as__link--text-decoration-inverse" data-modal-trigger="solution-modal"><svg role="img" class="as__icon as__link--icon__icon"><use xlink:href="#as__icon__link-icon"></use></svg>${labels.solutionLinkText}</span></p>` : ''

    return `<div class="dws__m85-learn-control-quiz__finish-content as__rte">
                ${contentTopStr}
                ${correctAnswersStr}
                ${correctAnswersPercentageStr}
                ${solutionLink}
                <p><a href="#evalution" class="as__link--icon as__link--text-decoration-inverse dws__m85-learn-control-quiz__evaluation-trigger" target="_blank"><svg role="img" class="as__icon as__link--icon__icon"><use xlink:href="#as__icon__link-icon"></use></svg>${labels.evaluationLinkText}</a></p>
            </div>`
};
