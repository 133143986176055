export type PosNegSignType = 1 | -1;
export type SignType = 0 | PosNegSignType;

export default function sign(number : any) : SignType {
    if(number > 0) {
        return 1;
    } else if(number < 0) {
        return -1;
    }

    return 0;
}