export interface EventListener<T extends Event> {
    (evt: T): void;
}

export function createCustomEvent(type : string, eventInitDict : object = {}) : Event {
    return Object.assign({ type: type }, eventInitDict) as Event;
}

export default class EventTarget implements EventTarget {
    private _eventListeners : {
        [type: string]: Array<EventListener<any>>
    } = {};

    public addEventListener(type : string, listener: EventListener<any>) {
        this._eventListeners[type] = this._eventListeners[type] || [];

        this._eventListeners[type].push(listener);
    }

    public removeEventListener(type : string, listener: EventListener<any>) {
        if(!(type in this._eventListeners)) {
            return;
        }

        const index = this._eventListeners[type].indexOf(listener);

        if(index !== -1) {
            this._eventListeners[type].splice(index, 1);
        }
    }

    public dispatchEvent(event : Event) {
        const listeners = this._eventListeners[event.type] || [];
        let stopImmediatePropagation = false;

        Object.defineProperties(event, {
            defaultPrevented: {value: false, enumerable: true},
            isTrusted: {value: false, enumerable: true},
            target: {value: this, enumerable: true},
            timeStamp: {value: (new Date()).getTime(), enumerable: true},

            preventDefault: {value: function(this : Event) {
                if(!this.cancelable) {
                    return;
                }

                Object.defineProperty(this, 'defaultPrevented', {value: true, enumerable: true});
            }, enumerable: false},

            stopImmediatePropagation: {value: function() {
                stopImmediatePropagation = true;
            }, enumerable: false}
        });

        for(let i = 0; i < listeners.length; ++i) {
            if(stopImmediatePropagation) {
                break;
            }

            listeners[i].call(this, event);
        }

        return !event.defaultPrevented;
    }

    public destroy() {
        this._eventListeners = {};
    }
}
