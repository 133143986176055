import axios from "axios";
import initCkeditor from "../ckeditor/ckeditor";
import initFileInputs from "../../base/form/file";
import FormValidation from "../formvalidation/formvalidation";
import FormAjax from "../form-ajax/form-ajax";
import FormMultistep from "../form-multistep/form-multistep";
import Accordion from "../base-accordion/accordion";
import Loading from "../loading/loading";
import FlashMessage from '../../components/flash-message/flash-message';
import EventTarget, {createCustomEvent} from "../../base/util/dom/EventTarget";

/**
 * Modal class
 * Toggles modal-container on trigger
 */
export default class Modal extends EventTarget {

    public static readonly Selectors = {
        Modal: '.as__modal',
        ModalWrapper: '.as__modal__wrapper',
        ModalContentFrame: '.as__modal__content-frame',
        ModalTrigger: '[data-modal-trigger]',
        CloseBtn: '.as__modal__close-btn',
        CloseTrigger: '.as__modal__close',
        Content: '.as__model__content',
    }

    private static readonly States = {
        ModalOpen: 'as__modal--opened',
        ModalContentLoaded: 'as__modal--content-loaded',
        ModalNoBackground: 'as__modal--no-background',
        ModalMaxHeight: 'as__modal--max-height'
    }

    private static readonly Attributes = {
        ModalId: 'data-modal-id',
        ModalTrigger: 'data-modal-trigger',
        EnableCloseOnOutsideClick: 'data-enable-outside-click',
        EnableCloseButton: 'data-enable-close',
        ContentUrl: 'data-content-url',
        CloseOnError: 'data-close-on-error'
    }

    private readonly domElements : {
        modal: Element,
        modalWrapper: Element|null,
        closeBtn: Element|null,
        modalFilteredTriggers: Array<HTMLElement>,
        modalContentFrame: Element|null,
        closeTrigger: Array<Element>,
        childModalTrigger: Array<Element>
        childModals: Array<Element>
    };
    private readonly settings : {modalId: string|null, isCloseButtonEnabled: boolean, isCloseOnclickOutsideModalEnabled: boolean, closeOnError: boolean, contentUrl: string|null};

    constructor(element : Element) {
        super();

        this.settings = {
            modalId: element.getAttribute(Modal.Attributes.ModalId),
            isCloseButtonEnabled: !(element.getAttribute(Modal.Attributes.EnableCloseButton) === 'false'),
            isCloseOnclickOutsideModalEnabled: !(element.getAttribute(Modal.Attributes.EnableCloseOnOutsideClick) === 'false'),
            closeOnError: element.hasAttribute(Modal.Attributes.CloseOnError),
            contentUrl: element.getAttribute(Modal.Attributes.ContentUrl)
        };

        this.domElements = {
            modal: element,
            modalWrapper: element.querySelector(Modal.Selectors.ModalWrapper),
            closeBtn: element.querySelector(Modal.Selectors.CloseBtn),
            modalFilteredTriggers: Array.prototype.slice.call(document.querySelectorAll(Modal.Selectors.ModalTrigger))
                .filter(element =>  element.getAttribute(Modal.Attributes.ModalTrigger) === this.settings.modalId),
            modalContentFrame: element.querySelector(Modal.Selectors.ModalContentFrame),
            closeTrigger: Array.prototype.slice.call(element.querySelectorAll(Modal.Selectors.CloseTrigger)),
            childModalTrigger: Array.prototype.slice.call(element.querySelectorAll(Modal.Selectors.ModalTrigger)),
            childModals: []
        };

        this.domElements.childModalTrigger.forEach((element) => {
            const childModalId = element.getAttribute(Modal.Attributes.ModalTrigger);
            if (!childModalId) {
                return;
            }
            const selector = '[' + Modal.Attributes.ModalId + '=' + childModalId +']';
            const childModal = document.querySelector(selector);

            if (!childModal) {
                return;
            }

            childModal.classList.add(Modal.States.ModalNoBackground);
            childModal.classList.add(Modal.States.ModalMaxHeight);
            this.domElements.childModals.push(childModal);
        });

        //binding for event handler
        this.closeModal = this.closeModal.bind(this);
        this.showModal = this.showModal.bind(this);
        this.closeOnClickOutsideHandler = this.closeOnClickOutsideHandler.bind(this);

        this.addCloseBtnEvents();
        this.addModalTriggerEvents();
        this.addCloseTriggerEvents();

        if (!(window.AS.FlashMessage instanceof FlashMessage)) {
            return
        }

        this.addEventListener('showFlashMessage', event => {
            window.AS.FlashMessage.insertFlashMessage(event.message, event.messageType);
            if(this.settings.closeOnError) this.closeModal();
        })
    }
    private addCloseBtnEvents() {
        if(this.settings.isCloseButtonEnabled  && this.domElements.closeBtn){
            this.domElements.closeBtn.addEventListener('click', this.closeModal);
        }
    }
    private addCloseTriggerEvents() {
        this.domElements.closeTrigger.forEach((item) => {
            item.removeEventListener('click', this.closeModal);
            item.addEventListener('click', this.closeModal);
        })
    }
    private addModalTriggerEvents() {
        if(this.domElements.modalFilteredTriggers) {
            this.domElements.modalFilteredTriggers.forEach((item) => {
                item.addEventListener('click', this.showModal);
            });
        }

    }
    public closeModal() {
        this.domElements.modal.classList.remove(Modal.States.ModalOpen);
        if(this.settings.isCloseOnclickOutsideModalEnabled) {
            document.removeEventListener('click', this.closeOnClickOutsideHandler, false);
        }
    }
    public showModal() {
        this.domElements.modal.classList.add(Modal.States.ModalOpen);
        if(this.settings.isCloseOnclickOutsideModalEnabled){
            setTimeout(() => {
                document.addEventListener('click', this.closeOnClickOutsideHandler, false);
            });
        }
        if(this.domElements.modal.classList.contains(Modal.States.ModalContentLoaded)) {
            return;
        }
        if(this.settings.contentUrl && this.settings.contentUrl !== "") {
            let loading = new Loading(this.domElements.modalWrapper, 'triple');
            axios.get(this.settings.contentUrl)
                .then((response: any) => {
                    if (response.status === 200 || response.status === 201) {
                        loading.destroy();
                        this.replaceModalContent(response.data);
                    } else {
                        this.handleAjaxError(response.data.message);
                    }
                })
                .catch((error: any) => {
                    this.handleAjaxError(error.message);
                })
        }
    }
    private handleAjaxError(message: string) {
        this.dispatchEvent(createCustomEvent('showFlashMessage', {
            message: message,
            messageType: 'alert-error'
        }));
        this.closeModal();
    }
    private replaceModalContent(html : string) {
        if(this.domElements.modalContentFrame instanceof Element) {
            this.domElements.modalContentFrame.innerHTML = html;
            this.domElements.closeTrigger = Array.prototype.slice.call(this.domElements.modal.querySelectorAll(Modal.Selectors.CloseTrigger));

            //internal
            this.addCloseTriggerEvents();
            //external
            initCkeditor(this.domElements.modalContentFrame);
            initFileInputs(this.domElements.modalContentFrame);
            this.domElements.modalContentFrame.querySelectorAll(FormValidation.Selectors.Form).forEach((element) => {
                new FormValidation(element);
            });
            this.domElements.modalContentFrame.querySelectorAll(FormMultistep.Selectors.Form).forEach((element) => {
                new FormMultistep(element);
            });
            this.domElements.modalContentFrame.querySelectorAll(FormAjax.Selectors.Form).forEach((element) => {
                const form = new FormAjax(element as HTMLFormElement);
                element.addEventListener('success', () => {
                    if(this.domElements.modalContentFrame) {
                        this.domElements.closeTrigger = Array.prototype.slice.call(this.domElements.modalContentFrame.querySelectorAll(Modal.Selectors.CloseTrigger));
                        this.addCloseTriggerEvents();
                    }
                });
                form.addEventListener('showFlashMessage', () => {
                    this.closeModal();
                })
            });
            this.domElements.modalContentFrame.querySelectorAll(Accordion.DefaultOptions.selectors.accordion).forEach((accordionElement) => {
                new Accordion(accordionElement as HTMLElement);
            });
        }
        this.domElements.modal.classList.add(Modal.States.ModalContentLoaded);
    }
    private findParentModal(element: HTMLElement|null) {
        do {
            if (element === this.domElements.modalWrapper) {
                return true;
            }
        } while( element && (element = element.parentElement));

        return false;
    }
    private childModalOpen() {
        for (let childModal of this.domElements.childModals) {
            if(childModal.classList.contains(Modal.States.ModalOpen)) {
                return true;
            }
        }

        return false;
    }
    private closeOnClickOutsideHandler(event: MouseEvent) {
        if(!(event.target instanceof HTMLElement)){
            return;
        }

        if (!this.findParentModal(event.target) && !this.childModalOpen()) {
            this.closeModal();
        }
    }
}

document.addEventListener('DOMContentLoaded', () => {
    let modals = document.querySelectorAll(Modal.Selectors.Modal);
    modals.forEach(element => {
        new Modal(element);
    });
});
