export default function animateHeightTransition(
    element : HTMLElement,
    open : boolean,
    callback : () => void = () => {}
) : void {
    const maxHeight = element.scrollHeight;
    let transitionDuration = 0;
    let computedStyles = window.getComputedStyle(element);
    let transitionDurationString = computedStyles.getPropertyValue('transition-duration').match(/0\.(\d+)/);

    if (transitionDurationString) {
        transitionDuration = parseInt(transitionDurationString[1], 10) * 100;
    }

    element.style.maxHeight = maxHeight + 'px';

    if (open) {
        setTimeout(() => {
            element.style.maxHeight = 'none';
            callback();
        }, transitionDuration);
    } else {
        setTimeout(() => {
            element.style.removeProperty('max-height');
        });
        setTimeout(callback, transitionDuration);
    }
}