import BaseSlider, {
    BaseSliderGoEvent,
    BaseSliderOptions,
    BaseSliderInitializedItemEvent
} from "../../components/base-slider/base-slider";
import {DeepPartial} from "../../base/util/assignOptions";
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import ProductCarouselPopup from "../../components/product-carousel-tile/product-carousel-tile"
import BaseSliderItem from "../../components/base-slider/base-slider-item";

onDOMContentLoaded(() => {
    document.querySelectorAll('.dws__m63a-favourite-list-factsheet__slider').forEach((carouselElement) => {
        if(!(carouselElement instanceof HTMLElement)) {
            return;
        }

        const settings: DeepPartial<BaseSliderOptions> = {
            selectors: {
                container: '.dws__m63a-favourite-list-factsheet__slider-container',
                items: '.dws__m63a-favourite-list-factsheet__slide',
            },
            core: {
              itemPosition: 'center',
            },
            grid: {
                adaptItemHeight: true,
                itemHeightCalculator: (item: BaseSliderItem, options: BaseSliderOptions) => {
                    const height = BaseSlider.DefaultOptions.grid.itemHeightCalculator(item, options);
                    if(options.grid.adaptItemHeight) {
                        return height + 10;
                    }
                    return height;
                }
            },
            arrows: {
                enabled: false,
            },
            swipe: {
                stepType: 'item',
            },
            infinite: {
                enabled: true
            },
        };

        const carousel = new BaseSlider(carouselElement, settings);

        carousel.addEventListener('initialized', () => {
            carousel.getItems().forEach((sliderItem) => {
                sliderItem.getElement().querySelectorAll(ProductCarouselPopup.DefaultOptions.selectors.root).forEach(popupItem => {
                    if (!(popupItem instanceof HTMLElement)) {
                        return;
                    }

                    new ProductCarouselPopup(popupItem);
                })
            })
        });

        carousel.addEventListener('initialized-item', (event : BaseSliderInitializedItemEvent) => {
            event.item.getElement().querySelectorAll(ProductCarouselPopup.DefaultOptions.selectors.root).forEach(popupItem => {
                if (!(popupItem instanceof HTMLElement)) {
                    return;
                }

                new ProductCarouselPopup(popupItem);
            })
        });
    });
});
