import assignOptions from '../../base/util/assignOptions';
import {DeepPartial} from '../../base/util/deepPartial';
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

export interface TrackingManagerOptions {
    namespace: string
}

export default class TrackingManager {
    public static readonly DefaultOptions: TrackingManagerOptions = {
        namespace: 'dws'
    }

    protected options: TrackingManagerOptions;

    constructor(options: DeepPartial<TrackingManagerOptions> = {}) {
        this.options = assignOptions(options, TrackingManager.DefaultOptions);
    }

    public trackEvent(event: string, action: string , label?: string, value?: number) {
        if (window._mtm) {
            let pushEvent = {
                'event': event,
                [this.getNameSpace() + 'action']: action
            }
            if(label !== undefined) {
                pushEvent = Object.assign(pushEvent, {
                    [this.getNameSpace() + 'label']: label
                })
            }
            if(value !== undefined) {
                pushEvent = Object.assign(pushEvent, {
                    [this.getNameSpace() + 'value']: value
                })
            }
            
            window._mtm.push(pushEvent);
        }
    }

    protected getNameSpace() {
        if(this.options.namespace === '') {
            return '';
        }

        return this.options.namespace + '.';
    }
}

onDOMContentLoaded(() => {
    window.AS = window.AS || {};
    window.AS.TrackingManager = new TrackingManager();
});