import Hammer from 'hammerjs';
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import assignOptions from "../../base/util/assignOptions";
import Search from "../../components/search/search";
import Autosuggestion from "../../components/autosuggestion/autosuggestion";


interface HeroSearchSelectors {
    searchContainer: string
    tab: string
    tabsContainer: string
    formText: string
    formButtons: string
    searchFormWrappers: string
}

interface HeroSearchDOM {
    root: HTMLElement
    searchContainer: HTMLElement | null
    tabs: Array<HTMLElement>
    tabsContainer: HTMLElement | null,
    formText: HTMLElement | null
    formButtons: NodeListOf<HTMLElement> | null
    searchFormWrappers: NodeListOf<HTMLElement> | null
}

interface HeroSearchClassNames {
    tab: string
    tabActive: string
}

interface HeroSearchAttributes {
    tabTheme: string
    searchTheme: string
    formInputText: string
    formInputPlaceholder: string,
    searchDocument: string
}

interface HeroSearchOptions {
    tabInitialOffset: number
    tabViewportOffset: number
}

export default class HeroSearch {
    protected readonly dom: HeroSearchDOM;
    protected readonly selectors: HeroSearchSelectors;
    protected readonly classNames: HeroSearchClassNames;
    protected readonly attributes: HeroSearchAttributes;
    protected readonly options: HeroSearchOptions;

    protected activeTabIndex: number = 0
    public static readonly DefaultOptions : HeroSearchOptions = {
        tabInitialOffset: 26,
        tabViewportOffset: 26
    }
    private activeTabNodes: NodeListOf<Element> | undefined;

    public constructor(element: HTMLElement, options: {}) {
        this.options = assignOptions(options, HeroSearch.DefaultOptions);

        this.selectors = {
            searchContainer: '.dws__m1-hero__search',
            tab: '.dws__tab',
            tabsContainer: '.dws__m1-hero__search__tabs',
            formText: '[data-search-description]',
            formButtons: '.dws__m1-hero__search-btn',
            searchFormWrappers: '.dws__m1-hero__search__form-wrapper'
        }

        this.classNames = {
            tab: 'dws__tab',
            tabActive: 'dws__tab--active'
        }

        this.attributes = {
            tabTheme: 'data-tab-theme',
            searchTheme: 'data-search-theme',
            formInputText: 'data-tab-description',
            formInputPlaceholder: 'data-tab-placeholder',
            searchDocument: 'data-search-document',
        }

        this.dom = {
            root: element,
            searchContainer: element.querySelector(this.selectors.searchContainer),
            tabs: [],
            tabsContainer: null,
            formText: element.querySelector(this.selectors.formText),
            formButtons: element.querySelectorAll(this.selectors.formButtons),
            searchFormWrappers: element.querySelectorAll(this.selectors.searchFormWrappers)
        }

        this.dom.tabs = this.getTabs();
        this.dom.tabsContainer = element.querySelector(this.selectors.tabsContainer)
        this.activeTabIndex = this.getActiveTriggerIndex()

        this.setActiveTabPosition(this.activeTabIndex)
        this.setActiveTabNodes();
        this.initEvents();
    }

    protected getTabs(): Array<HTMLElement> {
        return Array.prototype.slice.call(this.dom.root.querySelectorAll(this.selectors.tab));
    }

    protected initEvents() {
        if (this.dom.tabsContainer === null) {
            return;
        }

        Array.prototype.slice.call(this.dom.searchFormWrappers).forEach(wrapper => {
            let input = wrapper.querySelector('.as__input');
            if (!input) {
                return;
            }

            let autosuggestion = new Autosuggestion(wrapper, input);
            autosuggestion.filterOption = input.getAttribute(this.attributes.searchDocument);
        });

        this.dom.tabs.forEach(tab => tab.addEventListener('click', this.tabsClickHandler.bind(this)))

        const tabsContainer = this.dom.tabsContainer;
        const tabsContainerHammer = new Hammer(tabsContainer);

        tabsContainerHammer.on("panstart tap touch press", (ev: any) => {
            switch (ev.type) {
                case 'panstart':
                    if(ev.additionalEvent === 'panleft') {
                        this.activeTabIndex < (this.dom.tabs.length - 1) ? this.activeTabIndex++ : null
                    } else if (ev.additionalEvent === 'panright') {
                        this.activeTabIndex > 0 ? this.activeTabIndex-- : null
                    }
                    //this.updateTheme();
                    this.setActiveTabPosition(this.activeTabIndex)
                    break
                case 'tap':
                case 'touch':
                case 'press':
                    if(ev.target instanceof HTMLSpanElement) {
                        ev.preventDefault()
                        const spanIndex = this.dom.tabs.indexOf(ev.target)
                        this.activeTabIndex = spanIndex
                        //this.updateTheme();
                        this.setActiveTabPosition(spanIndex)
                    }
                    break
                default:
                    break
            }
        });

        Array.prototype.slice.call(this.dom.formButtons).forEach(button => button.addEventListener('click', this.searchBtnClickHandler.bind(this)))
    }

    private scrollTo(left: number = 0, top: number = 0): void {
        this.dom.tabsContainer!.scrollTo({
            left: left,
            top: top,
            behavior: 'smooth'
        });
    }

    protected getActiveTriggerIndex(): number {
        const  activeTrigger = this.dom.tabs.find((tab)=>{
            return tab.classList.contains(this.classNames.tabActive)
        })

        return activeTrigger !== undefined ? this.dom.tabs.indexOf(activeTrigger) : 0
    }

    protected setActiveTabPosition(index: number, offset: number = this.options.tabViewportOffset): void {
        const  activeLabel = this.dom.tabs[index]
        const activeLabelOffset = activeLabel.offsetLeft
        this.scrollTo(activeLabelOffset - offset)
    }

    private tabsClickHandler(ev: Event): void {
        if(!(ev.target instanceof HTMLSpanElement)) {
            return;
        }
        this.activeTabIndex = this.getActiveTriggerIndex();
        this.dom.tabs[this.activeTabIndex].classList.toggle(this.classNames.tabActive);

        const spanIndex = this.dom.tabs.indexOf(ev.target);
        this.dom.tabs[spanIndex].classList.toggle(this.classNames.tabActive);
        this.activeTabIndex = spanIndex;
        this.setActiveTabPosition(spanIndex);
        this.activateTab();

        let theme = ev.target.getAttribute(this.attributes.tabTheme) as string ?? 'red';
        this.dom.searchContainer?.setAttribute(this.attributes.searchTheme, theme);
    }

    private searchBtnClickHandler(ev: Event): void {
        if (!(ev.target instanceof Element)) {
            return;
        }

        let input = this.dom.searchContainer?.querySelector('.as__input[data-search-document="' + this.getContext() + '"]') as HTMLInputElement;

        let type = ev.target.getAttribute(this.attributes.searchDocument);

        window.location.href = Search.generateLink(type, input.value ?? null);
    }

    private activateTab() {
        const currentTabNodes = this.activeTabNodes ?? [];
        this.setActiveTabNodes();
        Array.prototype.slice.call(currentTabNodes).forEach(node => node.classList.toggle('as__hide'));
        Array.prototype.slice.call(this.activeTabNodes).forEach(node => node.classList.toggle('as__hide'));
    }

    private getContext(): string | null {
        return this.dom.tabs[this.activeTabIndex].getAttribute(this.attributes.searchDocument) ?? null;
    }

    private setActiveTabNodes() {
        this.activeTabNodes = this.dom.root.querySelectorAll('[data-active-tab="' + this.getContext() + '"]');
    }
}

onDOMContentLoaded(() => {

    const heroSearchDOM = document.querySelector('.dws__m1-hero');
    if (!(heroSearchDOM instanceof HTMLElement)) {
        return;
    }
    const heroSearch = new HeroSearch(heroSearchDOM, {});

});