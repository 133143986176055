import axios, {AxiosResponse} from "axios";
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import EventTarget, {createCustomEvent} from "../../base/util/dom/EventTarget";
import FlashMessage from '../../components/flash-message/flash-message';

export default class M91ProductRelease extends EventTarget {
    public static readonly Selectors = {
        container: '.dws__m91-generate-invitation-link',
        toggles: '.dws__m91-generate-invitation-link__product-toggle',
        form: '.dws__m91-generate-invitation-link__product-form',
        productCheckboxes: '.dws__m91-generate-invitation-link__product-checkbox'
    }

    private elements: {
        container: HTMLElement,
        toggles: Array<HTMLElement>,
        form: HTMLFormElement|null,
        productCheckboxes: Array<HTMLInputElement>
    }

    private rootUrl: string;
    private successText: string|null;
    constructor(element: HTMLElement) {
        super();

        this.elements = {
            container: element,
            toggles: Array.prototype.slice.call(element.querySelectorAll(M91ProductRelease.Selectors.toggles)),
            form: element.querySelector(M91ProductRelease.Selectors.form),
            productCheckboxes: Array.prototype.slice.call(element.querySelectorAll(M91ProductRelease.Selectors.productCheckboxes))
        }

        this.rootUrl = [window.location.protocol, '//', window.location.host].join('');
        this.successText = this.elements.container.getAttribute('data-success-text');

        if (!(this.elements.form instanceof HTMLFormElement) || !this.elements.form.action) {
            return;
        }

        this.elements.toggles.forEach((toggleElement) => {
            toggleElement.addEventListener('change', this.toggleChangeHandler.bind(this))
        })

        this.elements.form.addEventListener('submit', this.formSubmitHandler.bind(this))
    }

    private toggleChangeHandler(event: Event): void {
        if (!event || !(event.target instanceof HTMLInputElement) || !(this.elements.form instanceof HTMLFormElement)) {
            return
        }

        this.elements.form.reset();
    }

    private formSubmitHandler(event: Event): void {
        if (!event) {
            return
        }

        event.preventDefault();
        this.submitForm();
    }

    private submitForm() {
        if (!(this.elements.form instanceof HTMLFormElement)) {
            return;
        }

        const selectedProducts = this.elements.productCheckboxes.filter(productCheckbox => {
            if (!(productCheckbox instanceof HTMLInputElement)) {
                return false;
            }

            return productCheckbox.checked
        }).map(productCheckbox => { return productCheckbox.value });

        axios.post(this.elements.form.action, JSON.stringify({products: selectedProducts}), {
            headers: {"Content-Type": "application/json"}
        }).then((response) => {
            if (response.status === 200) {
                if (!response.data || !response.data.link) {
                    throw new Error('Empty response')
                }

                try {
                    this.copyLink(response)
                } catch (err) {
                    throw new Error("Copy error: " + err);
                }
            } else {
                this.handleAjaxError(response.data.message);
            }
        }).catch((error) => {
            this.handleAjaxError(error.message);
        });
    }

    private handleAjaxError(message: string) {
        this.dispatchEvent(createCustomEvent('showFlashMessage', {
            message: message,
            messageType: 'alert-error'
        }));
    }

    private copyLink(response: AxiosResponse) {
        document.body.insertAdjacentHTML('beforeend', '<input id="m91-temp-input" style="position: fixed;z-index: -1;top: 0;left: -9999px;" />');

        const tempInput = document.body.querySelector('#m91-temp-input');

        if (!(tempInput instanceof HTMLInputElement)) {
            throw new Error('Cant copy text')
        }

        tempInput.value = response.data.link;
        tempInput.select();
        tempInput.setSelectionRange(0, 99999);

        let result = false;
        try {
            result = document.execCommand("copy");
        } catch (err) {
            throw new Error("Copy error: " + err);
        }

        if (!tempInput.parentNode) {
            throw new Error("Copy error");
        }

        tempInput.parentNode.removeChild(tempInput);

        if (!this.successText) {
            return
        }

        this.dispatchEvent(createCustomEvent('showFlashMessage', {
            message: this.successText,
            messageType: 'alert-success'
        }));
    }
}


onDOMContentLoaded(() => {
    window.AS = window.AS || {}

    document.querySelectorAll(M91ProductRelease.Selectors.container).forEach((element) => {
        if(!(element instanceof HTMLElement)) {
            return;
        }

        const m91ProductRelease = new M91ProductRelease(element);

        if (!(window.AS.FlashMessage instanceof FlashMessage)) {
            return
        }

        m91ProductRelease.addEventListener('showFlashMessage', event => {
            window.AS.FlashMessage.insertFlashMessage(event.message, event.messageType)
        })
    });
});
