import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

onDOMContentLoaded(() => {

    function collapseSection(element: any) {
      const sectionHeight = element.scrollHeight;

      // temporarily disable all css transitions
      const elementTransition = element.style.transition;
      element.style.transition = '';

      requestAnimationFrame(function() {
        element.style.height = sectionHeight + 'px';
        element.style.transition = elementTransition;

        requestAnimationFrame(function() {
          element.style.height = 147 + 'px';
        });
      });

      element.setAttribute('data-collapsed', 'true');
    }

    function expandSection(element: any) {
      const sectionHeight = element.scrollHeight;
      element.style.height = sectionHeight + 'px';

      // element.addEventListener('transitionend', function(e: Event) {
      //   element.removeEventListener('transitionend', arguments.callee);
      //   element.style.height = null;
      // });

      element.setAttribute('data-collapsed', 'false');
    }

    const teaserTriggers = document.querySelectorAll('.m2a__topic-teaser__action__link');
    teaserTriggers.forEach((trigger) => {
        trigger!.addEventListener('click', function(e: Event) {
            e.preventDefault();
            const sectionId = trigger.getAttribute('data-topic-trigger');
            const section = document.querySelector(`[data-topic-section="${sectionId}"`);
            const isCollapsed = section!.getAttribute('data-collapsed') === 'true';

            if(isCollapsed) {
                expandSection(section)
                section!.setAttribute('data-collapsed', 'false');
                trigger.textContent = trigger.getAttribute('data-expanded-text');
            } else {
                collapseSection(section);
                trigger.textContent = trigger.getAttribute('data-collapsed-text');
            }
        });
    })
});

