import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import Dropdown from "../../components/dropdown/dropdown";
import passiveEvent from "../../base/util/dom/passiveEvent";
import Autosuggestion from "../../components/autosuggestion/autosuggestion";

interface SearchHeaderDOM {
    root: HTMLElement
    form: HTMLFormElement
    formInput: HTMLInputElement
    filters: HTMLElement[]
    searchBtn: HTMLElement
    toggleBtn: HTMLElement[]
    alternativeSearch: HTMLElement | undefined
}

interface SearchHeaderAttributes {
    theme: string
    itemFilterTheme: string
    itemFilterValue: string
}

interface ElementPosition {
    top: number
    left: number
    right: number
    bottom: number
}

export default class SearchHeader {
    public static readonly Selectors = {
        root: '.dws__search-header',
        filter: '.dws__search-header__filter-item',
        filterBtn: '.dws__search-header .dws__dropdown',
        searchBtn: '.dws__search-header__form-search',
        searchForm: '.dws__search-header__form',
        searchFormInput: '.dws__search-header__form-text > .as__input',
        toggleButton: '.dws__search-header__toggleBtn',
        autosuggestion: '.dws__suc6__autosuggest',
        autosuggestionResult: '.dws__suc6__autosuggest__results',
    }

    public static readonly States = {
        isVisible: 'dws__search-header-visible'
    }

    public static readonly Classnames = {
        searchHeaderOpen: 'dws__search-header--open',
        searchHeaderDisabled: 'dws__search-header--disabled',
    }

    private static readonly Attributes: SearchHeaderAttributes = {
        theme: 'data-search-header-theme',
        itemFilterTheme: 'data-filter-item-theme',
        itemFilterValue: 'data-filter-value',
    }

    private readonly alternativeSearchDOM: HTMLElement | undefined
    private alternativeSearchPosition: ElementPosition | undefined


    private readonly dom: SearchHeaderDOM
    private readonly dropdown: Dropdown | null = null
    private autosuggestion: Autosuggestion
    private isVisible: boolean = false
    private filterOption: string | null

    public constructor(element: HTMLElement, alternative: HTMLElement | undefined = undefined) {
        this.dom = {
            root: element,
            form: element.querySelector(SearchHeader.Selectors.searchForm) as HTMLFormElement,
            formInput: element.querySelector(SearchHeader.Selectors.searchFormInput) as HTMLInputElement,
            filters: Array.prototype.slice.call(element.querySelectorAll(SearchHeader.Selectors.filter)),
            searchBtn: element.querySelector(SearchHeader.Selectors.searchBtn) as HTMLElement,
            toggleBtn: Array.prototype.slice.call(document.querySelectorAll(SearchHeader.Selectors.toggleButton)),
            alternativeSearch: alternative
        }

        if(alternative) {
            this.alternativeSearchDOM = alternative
            this.alternativeSearchPosition = this.getElementBoundaries(alternative);
        }

        const filterBtn: HTMLElement | null = element.querySelector(SearchHeader.Selectors.filterBtn)
        if(filterBtn && filterBtn instanceof HTMLElement) {
            this.dropdown = new Dropdown(filterBtn, {})
        }

        this.filterOption = null;
        this.autosuggestion = new Autosuggestion(this.dom.root, this.dom.formInput);

        this.initEvents()
    }

    private initEvents(): void {
        if(this.dom.filters.length > 0) {
            this.setFilterOption(this.dom.filters[0]);

            this.dom.filters.forEach((element) => {
                element.addEventListener('click', this.filterItemHandler.bind(this), false)
            })
        }

        // submit trigger form action --> SearchController
        // if(this.dom.searchBtn) {
        //     this.dom.searchBtn.addEventListener('click', (ev)=> {
        //         if(this.dom.form) {
        //             this.onSubmit();
        //         }
        //     }, false)
        // }

        if(this.dom.toggleBtn.length > 0) {
            this.dom.toggleBtn.forEach((element)=> {
                element.addEventListener('click', this.toggleSearchHeader.bind(this), false);
            });
        }

        document.addEventListener('scroll', this.scrollHandler.bind(this), passiveEvent);
        document.addEventListener('resize', this.resizeHandler.bind(this), passiveEvent);
    }

    private filterItemHandler(ev: Event) {
        const filterItem = ev.target as Element
        this.dropdown!.setDropdownLabel(filterItem.textContent as string)
        if(filterItem.hasAttribute(SearchHeader.Attributes.itemFilterTheme)) {
            const theme = filterItem.getAttribute(SearchHeader.Attributes.itemFilterTheme) as string
            this.dom.root.setAttribute(SearchHeader.Attributes.theme, theme)
        } else {
            this.dom.root.setAttribute(SearchHeader.Attributes.theme, '')
        }
        this.dropdown!.setInactive()

        this.setFilterOption(filterItem);
    }

    private setFilterOption(filterItem: Element) {
        if(filterItem.hasAttribute(SearchHeader.Attributes.itemFilterValue)) {
            this.filterOption = filterItem.getAttribute(SearchHeader.Attributes.itemFilterValue) as string
        }

        if (this.autosuggestion) {
            this.autosuggestion.filterOption = this.filterOption;
        }
    }

    public toggleSearchHeader(): void {
        this.isVisible ? this.setSearchHeaderOpen() : this.setSearchHeaderClose()
        this.isVisible = !this.isVisible
        document.body && document.body.classList && document.body.classList.toggle(SearchHeader.States.isVisible, this.isVisible)
    }

    private setSearchHeaderOpen() {
        this.dom.root.classList.remove(SearchHeader.Classnames.searchHeaderOpen)
    }

    private setSearchHeaderClose() {
        this.dom.root.classList.add(SearchHeader.Classnames.searchHeaderOpen)
    }

    private scrollHandler(ev: Event): void {
        if(!this.alternativeSearchDOM) {
            return
        }

        this.isVisibleViewport(this.alternativeSearchDOM, window.scrollY) ?
            this.dom.root.classList.add(SearchHeader.Classnames.searchHeaderDisabled):
            this.dom.root.classList.remove(SearchHeader.Classnames.searchHeaderDisabled)
    }

    private resizeHandler(): void {
        this.alternativeSearchPosition = this.getElementBoundaries(this.dom.alternativeSearch as HTMLElement);
    }

    private isVisibleViewport(element: HTMLElement, scroll: number): boolean {
        return scroll >= this.alternativeSearchPosition!.top && scroll < this.alternativeSearchPosition!.bottom
    }

    private getElementBoundaries(element: HTMLElement): ElementPosition {
        const boundaries = element.getBoundingClientRect()
        const viewportHeight = window.innerHeight || document.documentElement.clientHeight
        return {
            top: boundaries.top - viewportHeight,
            bottom: boundaries.bottom,
            left: boundaries.left,
            right: boundaries.right
        } as ElementPosition
    }
}

onDOMContentLoaded(() => {
    const searchHeaderDOM = document.querySelector(SearchHeader.Selectors.root);
    const searchAlternativeSearch = document.querySelector('.dws__m1-hero__search') as HTMLElement
    if(!(searchHeaderDOM instanceof HTMLElement)) {
        return;
    }

    new SearchHeader(searchHeaderDOM, searchAlternativeSearch);
});
