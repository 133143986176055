import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import passiveEvent from "../../base/util/dom/passiveEvent";

// components
import Accordion from "../../components/base-accordion/accordion";


export default class Faq {
    public static readonly Selectors = {
        root: '.dws__faq',
        toggles: '.dws__m37-accordion__toggle',
        asideLabels: '.dws__m37-accordion__label--aside'
    }

    private dom: {
        root: HTMLElement,
        toggles: Array<HTMLElement>
    }

    public static readonly States = {
        labelActive: 'dws__m37-accordion__label--active'
    }

    public constructor(element: HTMLElement) {
        this.dom = {
            root: element,
            toggles: Array.prototype.slice.call(element.querySelectorAll(Faq.Selectors.toggles)),
        }

        this.initEvents()
    }

    private initEvents(): void {
        this.dom.toggles.forEach((toggleElement) => {
            toggleElement.addEventListener('change', this.toggleChangeHandler.bind(this), passiveEvent)
        })
    }

    private toggleChangeHandler(event: Event): void {
        const toggleElement = event.target as HTMLInputElement;
        const toggleID = toggleElement.id;
        const toggleState = toggleElement.checked;

        if (!toggleID) {
            return;
        }

        Array.prototype.slice.call(this.dom.root.querySelectorAll(Faq.Selectors.asideLabels)).forEach(asideLabelElement => {
            const asideLabelForAttr = asideLabelElement.getAttribute('for');

            if (asideLabelForAttr === toggleID) {
                asideLabelElement.classList.toggle(Faq.States.labelActive, toggleState)
            } else {
                asideLabelElement.classList.remove(Faq.States.labelActive)
            }
        })
    }
}


onDOMContentLoaded(() => {
    document.querySelectorAll('.dws__m37-accordion').forEach((accordionElement) => {
        if(!(accordionElement instanceof HTMLElement)) {
            return;
        }

        new Accordion(accordionElement, {
            selectors: {
                content: '.dws__m37-accordion__content',
                toggle: '.dws__m37-accordion__toggle'
            }
        });
    });

    document.querySelectorAll(Faq.Selectors.root).forEach((faq) => {
        if(!(faq instanceof HTMLElement)) {
            return;
        }

        new Faq(faq)
    });
});
