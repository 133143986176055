// region IMPORTS
// base utilities
import assignOptions from '../../base/util/assignOptions';
import {DeepPartial} from '../../base/util/deepPartial';
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import animateHeightTransition from "../../base/util/dom/animateHeightTransition";
// components
import BaseAccordion, {
    BaseAccordionSelectors,
    BaseAccordionAttributes,
    BaseAccordionUpdateItemEvent,
    BaseAccordionItem
} from "../../components/base-accordion/base-accordion";
// endregion IMPORTS

// region INTERFACES
export interface AccordionSelectors extends BaseAccordionSelectors {
    content: string,
    label: string,
    item: string,
}

export interface AccordionAttributes extends BaseAccordionAttributes {
    accordionItemId: string,
    enableScrollTo: string,
}

export interface AccordionOptions {
    selectors: AccordionSelectors,
    attributes: AccordionAttributes,
    itemFactory: (toggle : HTMLInputElement, options : AccordionOptions) => BaseAccordionItem|null
}

export interface AccordionDOM {
    element: HTMLElement
}
 // endregion INTERFACES

/**
 * Accordion class.
 * Resets "checked" property of <input type="checkbox" class="as__accordion__toggle"> elements
 * Used on all <div class="as__accordion"> modules
 */
export default class Accordion {
    public static readonly DefaultOptions : AccordionOptions = {
        selectors: {
            item: '.as__accordion__item',
            content: '.as__accordion__content',
            label: '.as__accordion__label',
            ...BaseAccordion.DefaultOptions.selectors
        },
        attributes: {
            accordionItemId: 'data-accordion-id',
            enableScrollTo: 'data-enable-scrollto',
            ...BaseAccordion.DefaultOptions.attributes
        },
        itemFactory: Accordion.defaultItemFactory
    }

    protected dom : AccordionDOM;
    protected baseAccordion : BaseAccordion;
    protected options: AccordionOptions;
    protected readonly isScrollToEnabled : boolean;

    constructor(element : HTMLElement, options : DeepPartial<AccordionOptions> = {}) {
        this.options = assignOptions(options, Accordion.DefaultOptions);

        this.dom = {
            element: element,
        };
        this.baseAccordion = new BaseAccordion(element, {
            ...this.options,
            itemFactory: (toggle : HTMLInputElement) => this.options.itemFactory(toggle, this.options)
        });

        this.isScrollToEnabled = element.hasAttribute(this.options.attributes.enableScrollTo);

        this.baseAccordion.addEventListener('updateItem', this.onAccordionUpdateItem.bind(this));
    }

    private onAccordionUpdateItem(event : BaseAccordionUpdateItemEvent) : void {
        let toDoCount = event.item.contents.length;
        const toggleCallback = () => {
            if(--toDoCount > 0 || !this.isScrollToEnabled) {
                return;
            }

            this.scrollToAccordionItem(event.item);
        }

        event.item.contents.forEach((content) => {
            animateHeightTransition(content, event.item.open, toggleCallback);
        })
    }

    /**
     * Scroll to accordion item element
     *
     */
    private scrollToAccordionItem(item : BaseAccordionItem) {
        if(!item.open || item.triggers.length < 1) {
            return;
        }

        item.triggers[0] && item.triggers[0].scrollIntoView();
    }

    public static defaultItemFactory(toggle : HTMLInputElement, options : AccordionOptions) : BaseAccordionItem|null {
        const accordionItem = document.querySelector(
            `${options.selectors.item}[${options.attributes.accordionItemId}=${toggle.id}]`
        );

        if(!(accordionItem instanceof HTMLElement)) {
            return null;
        }

        return {
            toggle: toggle,
            triggers: Array.prototype.slice.call(accordionItem.querySelectorAll(options.selectors.label)),
            contents: Array.prototype.slice.call(accordionItem.querySelectorAll(options.selectors.content)),
            open: toggle.checked
        };
    }
}

onDOMContentLoaded(() => {

    /**
    * Usage of Accordion class with custom options object:
    */
    // const options = {
    //     selectors: {
    //         accordion: '.as__accordion--test',
    //     }
    // };
    // document.querySelectorAll(options.selectors.accordion).forEach((accordionElement) => {
    // if(!(accordionElement instanceof HTMLElement)) {
    //     return;
    // }
    //
    // const accordion = new Accordion(accordionElement, options);

    document.querySelectorAll(Accordion.DefaultOptions.selectors.accordion).forEach((accordionElement) => {
        if(!(accordionElement instanceof HTMLElement)) {
            return;
        }

        new Accordion(accordionElement);
    });
});
