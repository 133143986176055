import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

// region INTERFACES
export interface ContentTableElements {
    containerElement: HTMLElement,
    linkElements: NodeListOf<HTMLElement> | null,
}
// endregion INTERFACES

/**
 * Leaflet detail
 * scrolls to anchor onclick
 */
export default class DetailFactsheet {
    public static readonly Selectors = {
        Container: '.dws__m63b-detail-factsheet',
        Links: '.dws__m63b-detail-factsheet__footnote-link, .dws__m63b-detail-factsheet__rte ._idFootnoteLink',
    };

    private static readonly Attributes = {
        Target: 'href',
    };

    private elements: ContentTableElements;

    constructor(element : HTMLElement) {
        this.elements = {
            containerElement: element,
            linkElements: element.querySelectorAll(DetailFactsheet.Selectors.Links),
        }

        if (!this.elements.linkElements || this.elements.linkElements.length === 0 ) {
            return;
        }

        this.elements.linkElements.forEach(link => link.addEventListener('click', this.onClick.bind(this)));
    }

    /**
     * Wrapper function for click related functions
     *
     */
    private onClick(event : Event) : void {
        if (!(event.target instanceof HTMLElement)) {
            throw new Error('could not access link');
        }

        const targetElement = document.querySelector(event.target.getAttribute(DetailFactsheet.Attributes.Target) || '');

        if (!(targetElement instanceof HTMLElement)) {
            throw new Error('could not find target link element');
        }

        targetElement.scrollIntoView();
    }
}

onDOMContentLoaded(() => {
    document.querySelectorAll(DetailFactsheet.Selectors.Container).forEach(contentTableElement => {
        if(!(contentTableElement instanceof HTMLElement)) {
            return;
        }

        new DetailFactsheet(contentTableElement);
    });
});
