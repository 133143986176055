import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

onDOMContentLoaded(() => {
    if(!window.Cookiebot) {
        console.log("Cookiebot not loaded");
        return;
    }
    if(!window._mtm) {
        console.log("MatomoTagManger not loaded");
        return;
    }

    const settings = {
        events: {
            marketing: 'CookiebotConsentMarketing',
            preferences: 'CookiebotConsentPreferences',
            statistics: 'CookiebotConsentStatistics',
        }
    }

    window.addEventListener('CookiebotOnAccept', (e) => {
        if (window.Cookiebot.consent.marketing) {
            window._mtm.push({'event': settings.events.marketing});
        }
        if (window.Cookiebot.consent.preferences) {
            window._mtm.push({'event': settings.events.preferences});
        }
        if (window.Cookiebot.consent.statistics) {
            window._mtm.push({'event': settings.events.statistics});
        }
    }, false);
});