import BaseSlider from "../base-slider/base-slider";
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

onDOMContentLoaded(() => {
    document.querySelectorAll('.as__carousel').forEach((carouselElement) => {
        if(!(carouselElement instanceof HTMLElement)) {
            return;
        }

        const carousel = new BaseSlider(carouselElement, {
            selectors: {
                container: '.as__carousel__container',
                items: '.as__carousel__item'
            },
            nav: false,
            arrows: {
                adaptHeightTo: '.as__carousel__image-container',

                selectors: {
                    prev: '.as__carousel__arrow--prev',
                    next: '.as__carousel__arrow--next'
                },
                classes: {
                    hidden: 'as__carousel__arrow--hidden'
                },
                stepType: 'item'
            },
            swipe: {
                stepType: 'item'
            },
            infinite: {
                clonedSlideClass: 'as__carousel__infinite-clone'
            }
        });
    });
});
