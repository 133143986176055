import axios, {AxiosResponse} from "axios";
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import EventTarget, {createCustomEvent} from "../../base/util/dom/EventTarget";
import FlashMessage from '../../components/flash-message/flash-message';
import {
    questionTextTemplate,
    questionRadioTemplate,
    questionCheckboxTemplate,
    questionTrueFalseTemplate,
    questionContentTemplate,
    questionFinishTemplate
} from "./learn-control-quiz-templates";
import Modal from "../../components/modal/modal";

export interface M85LearnControlQuizSlideColumnLabels {
    firstColumn: string,
    lastColumn: string,
}

export interface M85LearnControlQuizQuestion {
    label: string,
    name: string,
    value: string,
    id: string,
    checked? : boolean|string
}

export interface M85LearnControlQuizSlide {
    headline: string,
    index: number|'start'|'end'|'finish',
    questionText?: string,
    questionType?: 'single-choice' | 'multiple-choice' | 'true-false',
    questions?: Array<M85LearnControlQuizQuestion>,
    infoText?: string,
    columnLabels?: M85LearnControlQuizSlideColumnLabels,
}

export interface M85LearnControlQuizStaticSlide extends M85LearnControlQuizSlide {
    content?: string
}

export interface M85LearnControlQuizStartSlide extends M85LearnControlQuizSlide {
    startContentHTML: string
}

export interface M85LearnControlQuizFinishData {
    contentTop?: string,
    slideCount: number,
    correctAnswers: number,
    correctAnswersPercentage: number,
    certificatePercentage: number,
    unansweredQuestions: number,
    solutionLink: string,
    showCertificateLink: boolean,
    certificateLink: string,
}

export interface M85LearnControlQuizFinishSlide extends M85LearnControlQuizSlide {
    finishData: M85LearnControlQuizFinishData
}

export interface M85LearnControlQuizUpdateCurrentSlideIndexEvent extends Event {
    slideData: M85LearnControlQuizSlide|M85LearnControlQuizStaticSlide|M85LearnControlQuizFinishSlide|M85LearnControlQuizStartSlide|false
}

export interface M85LearnControlQuizSlideEvaluation {
    index: number|'start'|'end'|'finish',
    isCorrect: boolean
}

export interface M85LearnControlQuizSlideEvaluationResponse {
    slideData: M85LearnControlQuizSlide|M85LearnControlQuizStaticSlide|M85LearnControlQuizFinishSlide|M85LearnControlQuizStartSlide|false,
    slideEvaluation?: M85LearnControlQuizSlideEvaluation
}

export interface M85LearnControlQuizSlideSolution extends M85LearnControlQuizSlide {
}

export interface M85LearnControlQuizSlideSolutionResponse extends M85LearnControlQuizSlideEvaluationResponse {
    slideSolution?: M85LearnControlQuizSlideSolution
}

export interface M85LearnControlQuizLabels {
    certificateButton: string,
    certificatePercentage: string,
    closeEvaluationButton: string,
    correctAnswers: string,
    correctAnswersPercentage: string,
    endButton: string,
    evaluationAnswerCorrect: string,
    evaluationAnswerIncorrect: string,
    evaluationLinkText: string,
    finishButton: string,
    headerPagination: string,
    navSelectEnd: string,
    navSelectLabel: string,
    navSelectStart: string,
    nextButton: string,
    prevButton: string,
    retryButton: string,
    solutionLinkText: string,
    startButton: string,
    unansweredQuestions: string,
}

const UPDATED_CURRENT_INDEX_EVENT = 'M85LearnControlQuiz:updatedCurrentIndex'

export default class M85LearnControlQuiz extends EventTarget {
    public static readonly Selectors = {
        Container: '.dws__m85-learn-control-quiz',
        Header: '.dws__m85-learn-control-quiz__header',
        Headline: '.dws__m85-learn-control-quiz__headline',
        PaginationWrapper: '.dws__m85-learn-control-quiz__header-pagination-wrapper',
        Pagination: '.dws__m85-learn-control-quiz__header-pagination',
        HeaderInfo: '.dws__m85-learn-control-quiz__header-info',
        HeaderInfoContent: '.dws__m85-learn-control-quiz__header-info .as__popover__content',
        ContentInner: '.dws__m85-learn-control-quiz__content-inner',
        NavSelect: '.dws__m85-learn-control-quiz__nav-select',
        NavSelectWrapper: '.dws__m85-learn-control-quiz__bottom',
        StartBtn: '.dws__m85-learn-control-quiz__start-btn',
        StartBtnWrapper: '.dws__m85-learn-control-quiz__btn-wrapper--start-btn',
        PrevBtn: '.dws__m85-learn-control-quiz__prev-btn',
        PrevBtnWrapper: '.dws__m85-learn-control-quiz__btn-wrapper--prev-btn',
        NextBtn: '.dws__m85-learn-control-quiz__next-btn',
        NextBtnWrapper: '.dws__m85-learn-control-quiz__btn-wrapper--next-btn',
        EndBtn: '.dws__m85-learn-control-quiz__end-btn',
        EndBtnWrapper: '.dws__m85-learn-control-quiz__btn-wrapper--end-btn',
        FinishBtn: '.dws__m85-learn-control-quiz__finish-btn',
        FinishBtnWrapper: '.dws__m85-learn-control-quiz__btn-wrapper--finish-btn',
        RetryBtn: '.dws__m85-learn-control-quiz__retry-btn',
        RetryBtnWrapper: '.dws__m85-learn-control-quiz__btn-wrapper--retry-btn',
        CertificateBtn: '.dws__m85-learn-control-quiz__certificate-btn',
        EvaluationTrigger: '.dws__m85-learn-control-quiz__evaluation-trigger',
        EvaluationCloseTrigger: '.dws__m85-learn-control-quiz__evaluation-close-trigger',
        EvaluationHeadline: '.dws__m85-learn-control-quiz__headline-evaluation',
        SolutionModal: '.dws__m85-learn-control-quiz__solution-modal',
        SolutionModalTrigger: '[data-modal-trigger="solution-modal"]',
        SolutionModeTrigger: '.dws__m85-learn-control-quiz__solution-mode-trigger',
        SolutionCloseTrigger: '.dws__m85-learn-control-quiz__solution-close-trigger',
    }

    public static readonly Attributes = {
        Action: 'data-ajax-action',
        InitialAction: 'data-initial-ajax-action',
        SaveDataAction: 'data-save-data-action',
        ClearDataAction: 'data-clear-data-action',
        EvaluationAction: 'data-evaluation-action',
        SolutionModeAction: 'data-solution-mode-action',
        SolutionAction: 'data-solution-action',
        VideoPlayerId: 'data-video-player-id',
        ProgressableIdentifier: 'data-progressable-identifier',
        Labels: 'data-labels',
        CertificateBtnHref: 'href'
    };

    public static readonly States = {
        IsLoading: 'dws__m85-learn-control-quiz--is-loading',
        IsQuestion: 'dws__m85-learn-control-quiz--is-question',
        HideHeaderInfo: 'dws__m85-learn-control-quiz__header-info--hidden',
        HideNavBtnWrapper: 'dws__m85-learn-control-quiz__btn-wrapper--hidden',
        HideNavBtn: 'as__hide',
        DisableNavBtn: 'as__btn--disabled',
        HideNavSelectWrapper: 'dws__m85-learn-control-quiz__bottom--hidden',
        EvaluationMode: 'dws__m85-learn-control-quiz--evaluation-mode',
        EvaluationCorrect: 'dws__m85-learn-control-quiz--evaluation-correct',
        SolutionMode: 'dws__m85-learn-control-quiz--solution-mode',
    };

    private elements: {
        container: HTMLFormElement,
        header: HTMLElement|null,
        headline: HTMLElement|null,
        paginationWrapper: HTMLElement|null,
        pagination: HTMLElement|null,
        headerInfo: HTMLElement|null,
        headerInfoContent: HTMLElement|null,
        contentInner: HTMLElement|null,
        navSelects: Array<HTMLSelectElement>,
        navSelectWrapper: HTMLElement|null,
        startBtn: HTMLElement|null,
        startBtnWrapper: HTMLElement|null,
        prevBtn: HTMLElement|null,
        prevBtnWrapper: HTMLElement|null,
        nextBtn: HTMLElement|null,
        nextBtnWrapper: HTMLElement|null,
        endBtn: HTMLElement|null,
        endBtnWrapper: HTMLElement|null,
        finishBtn: HTMLElement|null,
        finishBtnWrapper: HTMLElement|null,
        retryBtn: HTMLElement|null,
        retryBtnWrapper: HTMLElement|null,
        certificateBtn: HTMLElement|null,
        evaluationTrigger: HTMLElement|null,
        evaluationCloseTrigger: HTMLElement|null,
        evaluationHeadline: HTMLElement|null,
        solutionModal: HTMLElement|null,
        solutionModalTrigger: HTMLElement|null,
        solutionModeTrigger: HTMLElement|null,
        solutionCloseTrigger: HTMLElement|null,
    }

    private static readonly QuestionTextTemplate = questionTextTemplate;
    private static readonly QuestionRadioTemplate = questionRadioTemplate;
    private static readonly QuestionCheckboxTemplate = questionCheckboxTemplate;
    private static readonly QuestionTrueFalseTemplate = questionTrueFalseTemplate;
    private static readonly QuestionContentTemplate = questionContentTemplate;
    private static readonly QuestionFinishTemplate = questionFinishTemplate;

    private rootUrl: string = [window.location.protocol, '//', window.location.host].join('');
    private ajaxAction: string|null = null
    private initialAjaxAction: string|null = null
    private saveDataAction: string|null = null
    private clearDataAction: string|null = null
    private evaluationAction: string|null = null
    private solutionModeAction: string|null = null
    private solutionAction: string|null = null
    private videoPlayerId: string|null = null
    private progressableIdentifier: string|null = null
    private slideCount: number = 0
    private currentIndex: number|'start'|'end'|'finish' = 'start'
    private lastSavedIndex: number|'start'|'end'|'finish'|null = null
    private isSavedDataCleared: boolean = false
    private startSlideData: M85LearnControlQuizStartSlide = {index: 'start', headline: '', startContentHTML: ''}
    private labels: M85LearnControlQuizLabels|null = null;
    private evaluationMode: boolean = false
    private solutionModeUserSetting: boolean = false
    private solutionMode: boolean = false
    private solutionModal: Modal|null = null

    constructor(element: HTMLFormElement) {
        super();

        this.elements = {
            container: element,
            header: element.querySelector(M85LearnControlQuiz.Selectors.Header),
            headline: element.querySelector(M85LearnControlQuiz.Selectors.Headline),
            paginationWrapper: element.querySelector(M85LearnControlQuiz.Selectors.PaginationWrapper),
            pagination: element.querySelector(M85LearnControlQuiz.Selectors.Pagination),
            headerInfo: element.querySelector(M85LearnControlQuiz.Selectors.HeaderInfo),
            headerInfoContent: element.querySelector(M85LearnControlQuiz.Selectors.HeaderInfoContent),
            contentInner: element.querySelector(M85LearnControlQuiz.Selectors.ContentInner),
            navSelects: Array.prototype.slice.call(element.querySelectorAll(M85LearnControlQuiz.Selectors.NavSelect)),
            navSelectWrapper: element.querySelector(M85LearnControlQuiz.Selectors.NavSelectWrapper),
            startBtn: element.querySelector(M85LearnControlQuiz.Selectors.StartBtn),
            startBtnWrapper: element.querySelector(M85LearnControlQuiz.Selectors.StartBtnWrapper),
            prevBtn: element.querySelector(M85LearnControlQuiz.Selectors.PrevBtn),
            prevBtnWrapper: element.querySelector(M85LearnControlQuiz.Selectors.PrevBtnWrapper),
            nextBtn: element.querySelector(M85LearnControlQuiz.Selectors.NextBtn),
            nextBtnWrapper: element.querySelector(M85LearnControlQuiz.Selectors.NextBtnWrapper),
            endBtn: element.querySelector(M85LearnControlQuiz.Selectors.EndBtn),
            endBtnWrapper: element.querySelector(M85LearnControlQuiz.Selectors.EndBtnWrapper),
            finishBtn: element.querySelector(M85LearnControlQuiz.Selectors.FinishBtn),
            finishBtnWrapper: element.querySelector(M85LearnControlQuiz.Selectors.FinishBtnWrapper),
            retryBtn: element.querySelector(M85LearnControlQuiz.Selectors.RetryBtn),
            retryBtnWrapper: element.querySelector(M85LearnControlQuiz.Selectors.RetryBtnWrapper),
            certificateBtn: element.querySelector(M85LearnControlQuiz.Selectors.CertificateBtn),
            evaluationTrigger: null,
            evaluationCloseTrigger: element.querySelector(M85LearnControlQuiz.Selectors.EvaluationCloseTrigger),
            evaluationHeadline: element.querySelector(M85LearnControlQuiz.Selectors.EvaluationHeadline),
            solutionModal: element.querySelector(M85LearnControlQuiz.Selectors.SolutionModal),
            solutionModalTrigger: null,
            solutionModeTrigger: element.querySelector(M85LearnControlQuiz.Selectors.SolutionModeTrigger),
            solutionCloseTrigger: element.querySelector(M85LearnControlQuiz.Selectors.SolutionCloseTrigger)
        }

        window.AS = window.AS || {}

        if (window.AS.FlashMessage instanceof FlashMessage) {
            this.addEventListener('showFlashMessage', event => {
                window.AS.FlashMessage.insertFlashMessage(event.message, event.messageType)
            })
        }

        try {
            // Prevent default form submit
            this.elements.container.addEventListener('submit', (e) => { e.preventDefault(); })

            if (!(this.elements.contentInner instanceof HTMLElement)) throw new Error('1652444749 LearnControlQuiz JS – No content element found');

            this.initialAjaxAction = this.elements.container.getAttribute(M85LearnControlQuiz.Attributes.InitialAction);
            if (!this.initialAjaxAction) throw new Error('1652444794 LearnControlQuiz JS – No initial ajax action found');

            this.ajaxAction = this.elements.container.getAttribute(M85LearnControlQuiz.Attributes.Action);
            if (!this.ajaxAction) throw new Error('1652445204 LearnControlQuiz JS – No ajax action found');

            this.saveDataAction = this.elements.container.getAttribute(M85LearnControlQuiz.Attributes.SaveDataAction);
            if (!this.saveDataAction) throw new Error('1652445211 LearnControlQuiz JS – No save data action found');

            this.clearDataAction = this.elements.container.getAttribute(M85LearnControlQuiz.Attributes.ClearDataAction);
            if (!this.clearDataAction) throw new Error('1652445219 LearnControlQuiz JS – No clear data action found');

            this.evaluationAction = this.elements.container.getAttribute(M85LearnControlQuiz.Attributes.EvaluationAction);
            if (!this.evaluationAction) throw new Error('1653989835 LearnControlQuiz JS – No evaluation action found');

            this.solutionModeAction = this.elements.container.getAttribute(M85LearnControlQuiz.Attributes.SolutionModeAction);
            if (!this.solutionModeAction) throw new Error('1657118182 LearnControlQuiz JS – No solution mode action found');

            this.solutionAction = this.elements.container.getAttribute(M85LearnControlQuiz.Attributes.SolutionAction);
            if (!this.solutionAction) throw new Error('1657118182 LearnControlQuiz JS – No solution action found');

            if (!this.elements.solutionModal) throw new Error('1657706887 LearnControlQuiz JS – No solution modal found');
            this.solutionModal = new Modal(this.elements.solutionModal as Element)
            if (!this.solutionModal) throw new Error('1657706808 LearnControlQuiz JS – No solution modal class found');

            this.videoPlayerId = this.elements.container.getAttribute(M85LearnControlQuiz.Attributes.VideoPlayerId);
            if (!this.videoPlayerId) throw new Error('1653989836 LearnControlQuiz JS – No video player id found');

            this.progressableIdentifier = this.elements.container.getAttribute(M85LearnControlQuiz.Attributes.ProgressableIdentifier);
            if (!this.progressableIdentifier) throw new Error('1653989837 LearnControlQuiz JS – No progressable identifier found');

            this.loadSlideCount()
                .then(() => {
                    this.onSlideCountLoaded()
                    this.addEventListener(UPDATED_CURRENT_INDEX_EVENT, this.onCurrentIndexUpdated.bind(this));
                }).catch(error => {
                    this.handleError(error.message);
                })
        } catch (error) {
            this.handleError(error.message);
        }
    }

    private loadSlideCount() : Promise<number> {
        this.elements.container.classList.add(M85LearnControlQuiz.States.IsLoading);
        return axios.get(this.rootUrl + this.initialAjaxAction).then(response => {
            if (response.status === 200) {
                if (!response.data || !response.data.slideCount) {
                    throw new Error('1652445234 LearnControlQuiz JS – No slides exist')
                }

                this.slideCount = response.data.slideCount as number;
                this.elements.container.classList.remove(M85LearnControlQuiz.States.IsLoading);
                return response.data.slideCount
            } else {
                this.handleError(response.data.message);
            }
        }).catch((error) => {
            this.handleError(error.message);
        });
    }

    private onSlideCountLoaded() {
        if (this.slideCount <= 0) throw new Error('1652445292 LearnControlQuiz JS – No slides exist');

        this.readyNavSelect()
        this.updatePaginationText(null, true)
        // Preload first slide
        this.loadSlide(1);
        // Save start slide data for retry events
        this.saveStartSlideData()
        this.readLabels();

        if (!(this.elements.startBtn instanceof HTMLElement)) throw new Error('1652445383 LearnControlQuiz JS – No start button found');
        this.elements.startBtn.addEventListener('click', () => { this.goToSlide(1) });

        if (!(this.elements.prevBtn instanceof HTMLElement)) throw new Error('1652445390 LearnControlQuiz JS – No prev button found');
        this.elements.prevBtn.addEventListener('click', () => { this.onNavButtonClick('prev') });

        if (!(this.elements.nextBtn instanceof HTMLElement)) throw new Error('1652445402 LearnControlQuiz JS – No next button found');
        this.elements.nextBtn.addEventListener('click', () => { this.onNavButtonClick('next') });

        if (!(this.elements.endBtn instanceof HTMLElement)) throw new Error('1652445422 LearnControlQuiz JS – No end button found');
        this.elements.endBtn.addEventListener('click', () => { this.goToSlide('end') });

        if (!(this.elements.finishBtn instanceof HTMLElement)) throw new Error('1652445453 LearnControlQuiz JS – No finish button found');
        this.elements.finishBtn.addEventListener('click', () => { this.goToSlide('finish') });

        if (!(this.elements.retryBtn instanceof HTMLElement)) throw new Error('1652445486 LearnControlQuiz JS – No retry button found');
        this.elements.retryBtn.addEventListener('click', this.restart.bind(this));

        if (!(this.elements.evaluationCloseTrigger instanceof HTMLElement)) throw new Error('1653999714 LearnControlQuiz JS – No evaluation close button found');
        this.elements.evaluationCloseTrigger.addEventListener('click', this.onEvaluationCloseTriggerClick.bind(this));

        if (!(this.elements.solutionCloseTrigger instanceof HTMLElement)) throw new Error('1657706463 LearnControlQuiz JS – No solution close button found');
        this.elements.solutionCloseTrigger.addEventListener('click', this.onSolutionCloseTriggerClick.bind(this));
    }

    private readyNavSelect() {
        if (!this.elements.navSelects.length || this.slideCount <= 0) throw new Error('1652445499 LearnControlQuiz JS – Can\'t fill nav selects');

        this.elements.navSelects.forEach(navSelect => {
            const lastNavSelectOptionIndex = navSelect.children && navSelect.children.length ? navSelect.children.length - 1 : null;

            if (lastNavSelectOptionIndex) {
                for (let i = this.slideCount - 1; i >= 0 ; i--) {
                    let navSelectOptionEl = document.createElement("option");
                    navSelectOptionEl.text = (i + 1).toString();
                    navSelectOptionEl.value = (i + 1).toString();

                    navSelect.add(navSelectOptionEl, lastNavSelectOptionIndex)
                }
            } else {
                for (let i = 0; i < this.slideCount; i++) {
                    let navSelectOptionEl = document.createElement("option");
                    navSelectOptionEl.text = (i + 1).toString();
                    navSelectOptionEl.value = (i + 1).toString();

                    navSelect.add(navSelectOptionEl)
                }
            }

            navSelect.addEventListener('change', this.onNavSelectChange.bind(this));
        })
    }

    private onNavSelectChange(event : Event) {
        if (!event || !event.target || !(event.target instanceof HTMLSelectElement)) throw new Error('1652448349 LearnControlQuiz JS – No nav select found');
        let slideIndex = null as number|'start'|'end'|'finish'|null;

        if (event.target.value === 'start' || event.target.value === 'end' || event.target.value === 'finish') {
            slideIndex = event.target.value
        } else {
            slideIndex = parseInt(event.target.value, 10);
        }

        this.goToSlide(slideIndex || null);
    }

    private updatePaginationText(slideIndex: number|'start'|'end'|'finish'|null = null, updateSlideCount: boolean = false) {
        if (!(this.elements.pagination instanceof HTMLElement) || !this.elements.pagination.innerHTML) throw new Error('1652448516 LearnControlQuiz JS – No pagination element found');

        if (slideIndex !== null && this.isSlideIndexQuestion(slideIndex)) {
            this.elements.pagination.innerHTML = this.elements.pagination.innerHTML.replace(/\d+|(?:%d)+/, slideIndex.toString())
        }

        if (updateSlideCount) {
            this.elements.pagination.innerHTML = this.elements.pagination.innerHTML.replace(/[\d%d]+(?!.*?[\d%d]+)/, this.slideCount.toString())
        }
    }

    private saveStartSlideData() {
        if (this.elements.headline) {
            this.startSlideData.headline = this.elements.headline.innerText
        }

        if (this.elements.contentInner) {
            this.startSlideData.startContentHTML = this.elements.contentInner.innerHTML
        }
    }

    private readLabels() {
        const labelsString = this.elements.container.getAttribute(M85LearnControlQuiz.Attributes.Labels) || null;

        if (!labelsString) return

        this.labels = JSON.parse(labelsString);
    }

    private isSlideIndexQuestion(slideIndex: number|'start'|'end'|'finish') : boolean {
        return (typeof slideIndex == 'number')
    }

    private goToSlide(slideIndex: number|'start'|'end'|'finish'|null, afterLoadSlideCallback?: () => void) {
        if (slideIndex === null) throw new Error('1652453741 LearnControlQuiz JS – No slide index given');

        // Don't move to slide if the same index is given
        if (slideIndex === this.currentIndex) return

        this.saveCurrentSlide(this.currentIndex)
            .then(saveSuccess => {
                return this.loadSlide(slideIndex)
            })
            .then(slideData => {
                return this.loadSlideEvaluation(slideData, slideIndex)
            })
            .then(slideEvaluationResponse => {
                return this.loadSlideSolution(slideEvaluationResponse, slideIndex)
            })
            .then(slideSolutionResponse => {
                if (!slideSolutionResponse || !slideSolutionResponse.slideData) throw new Error('1654000291 LearnControlQuiz JS – No slide data found')

                this.renderSlide(slideSolutionResponse.slideData, slideSolutionResponse.slideEvaluation, slideSolutionResponse.slideSolution)
                this.currentIndex = slideIndex
                this.scrollToContainerTop();
                this.dispatchEvent(createCustomEvent(UPDATED_CURRENT_INDEX_EVENT, {
                    slideData: slideSolutionResponse.slideData
                }) as M85LearnControlQuizUpdateCurrentSlideIndexEvent);

                afterLoadSlideCallback && afterLoadSlideCallback();
            }).catch(error => {
                this.handleError(error.message);
            })
    }

    private saveCurrentSlide(slideIndex: number|'start'|'end'|'finish') : Promise<boolean> {
        if (this.lastSavedIndex === slideIndex ||
            slideIndex === 'start' ||
            this.evaluationMode ||
            this.solutionMode ||
            !this.isSlideIndexQuestion(slideIndex)) return new Promise((resolve, reject) => resolve(true))

        this.elements.container.classList.add(M85LearnControlQuiz.States.IsLoading);

        const formDataObj = new FormData(this.elements.container);
        let formDataArr = [] as any;

        for (const pair of formDataObj.entries()) {
            let formDataEntry = {} as any;
            formDataEntry[pair[0]] = pair[1];
            formDataArr.push(formDataEntry);
        }

        return axios.post(this.rootUrl + this.saveDataAction, JSON.stringify({slideIndex: slideIndex, formData: formDataArr}), {
            headers: {"Content-Type": "application/json"}
        }).then(response => {
            if (response.status === 200) {
                if (!response.data || !response.data.success) {
                    throw new Error('1652454898 LearnControlQuiz JS – Could\'t save')
                }

                this.lastSavedIndex = slideIndex
                this.elements.container.classList.remove(M85LearnControlQuiz.States.IsLoading);
                return response.data.success
            } else {
                return this.handleSaveError(response.data.message);
            }
        }).catch((error) => {
            return this.handleSaveError(error.message);
        });
    }

    private loadSlide(slideIndex: number|'start'|'end'|'finish') : Promise<M85LearnControlQuizSlide|M85LearnControlQuizStaticSlide|M85LearnControlQuizFinishSlide|M85LearnControlQuizStartSlide|false> {
        if (slideIndex === 'start') return new Promise((resolve, reject) => resolve(this.startSlideData))

        this.elements.container.classList.add(M85LearnControlQuiz.States.IsLoading);
        const loadSlidePostData = {
            slideIndex: slideIndex,
            evaluationMode: this.evaluationMode,
            solutionMode: this.solutionMode,
            videoPlayerId: this.videoPlayerId,
            progressableIdentifier: this.progressableIdentifier
        }

        return axios.post(this.rootUrl + this.ajaxAction, JSON.stringify(loadSlidePostData), {
            headers: {"Content-Type": "application/json"}
        }).then(response => {
            if (response.status === 200) {
                if (!response.data || !response.data.slide) {
                    throw new Error('1652454753 LearnControlQuiz JS – Empty response')
                }

                this.elements.container.classList.remove(M85LearnControlQuiz.States.IsLoading);
                return response.data.slide;
            } else {
                return this.handleError(response.data.message)
            }
        }).catch((error) => {
            return this.handleError(error.message)
        });
    }


    private renderSlide(slide: M85LearnControlQuizSlide|M85LearnControlQuizStaticSlide|M85LearnControlQuizFinishSlide|M85LearnControlQuizStartSlide, slideEvaluation?: M85LearnControlQuizSlideEvaluation, slideSolution?: M85LearnControlQuizSlideSolution) {
        if (!slide || !slide.index) throw new Error('1652456882 LearnControlQuiz JS – No slide index given');

        this.elements.container.classList.toggle(M85LearnControlQuiz.States.IsQuestion, this.isSlideIndexQuestion(slide.index));
        this.renderSlideHeader(slide, slideEvaluation);
        this.renderSlideContent(slide, slideSolution);
    }

    private renderSlideHeader(slide: M85LearnControlQuizSlide|M85LearnControlQuizStaticSlide|M85LearnControlQuizFinishSlide|M85LearnControlQuizStartSlide, slideEvaluation?: M85LearnControlQuizSlideEvaluation) {
        if (this.elements.headline instanceof HTMLElement) {
            this.elements.headline.innerText = slide.headline
        }

        if (this.elements.pagination instanceof HTMLElement) {
            this.updatePaginationText(slide.index)
        }

        if (this.elements.headerInfo instanceof HTMLElement) {
            this.elements.headerInfo.classList.toggle(M85LearnControlQuiz.States.HideHeaderInfo, !slide.infoText)
        }

        if (this.elements.headerInfoContent instanceof HTMLElement && slide.infoText) {
            this.elements.headerInfoContent.innerText = slide.infoText
        }

        if (this.elements.evaluationHeadline instanceof HTMLElement) {
            if (this.evaluationMode && slideEvaluation && this.labels) {
                this.elements.evaluationHeadline.innerText = slideEvaluation.isCorrect ? this.labels.evaluationAnswerCorrect : this.labels.evaluationAnswerIncorrect;
                this.elements.container.classList.toggle(M85LearnControlQuiz.States.EvaluationCorrect, slideEvaluation.isCorrect);
            } else {
                this.elements.evaluationHeadline.innerText = ''
                this.elements.container.classList.remove(M85LearnControlQuiz.States.EvaluationCorrect);
            }
        }
    }

    private renderSlideContent(slide: M85LearnControlQuizSlide|M85LearnControlQuizStaticSlide|M85LearnControlQuizFinishSlide|M85LearnControlQuizStartSlide, slideSolution?: M85LearnControlQuizSlideSolution) {
        if (!(this.elements.contentInner instanceof HTMLElement)) throw new Error('1652457936 LearnControlQuiz JS – No content element found');

        // Reset content inner html
        this.elements.contentInner.innerHTML = '';

        let slideContentHTML = '';

        if (this.isSlideIndexQuestion(slide.index)) {
            if (!this.solutionMode) {
                if (slide.questionText) {
                    slideContentHTML = M85LearnControlQuiz.QuestionTextTemplate(slide.questionText);
                }

                slideContentHTML += this.renderSlideQuestions(slide);
            } else {
                if (slideSolution) {
                    if (slideSolution.questionText) {
                        slideContentHTML = M85LearnControlQuiz.QuestionTextTemplate(slideSolution.questionText);
                    }

                    slideContentHTML += this.renderSlideQuestions(slideSolution);
                }
            }
        } else {
            if (instanceOfM85LearnControlQuizStaticSlide(slide) && slide.content) {
                slideContentHTML = M85LearnControlQuiz.QuestionContentTemplate(slide.content);
            } else if (instanceOfM85LearnControlQuizFinishSlide(slide) && slide.finishData && this.labels) {
                slideContentHTML = M85LearnControlQuiz.QuestionFinishTemplate(slide.finishData, this.labels);
            } else if (slide.index === 'start' && instanceOfM85LearnControlQuizStartSlide(slide)) {
                slideContentHTML = slide.startContentHTML;
            }
        }

        this.elements.contentInner.innerHTML = slideContentHTML;
    }

    private renderSlideQuestions(slide: M85LearnControlQuizSlide) : string {
        if (!slide.questions || !slide.questions.length) throw new Error('1652458524 LearnControlQuiz JS – No questions found')

        let slideQuestionsHtml = ''

        if (slide.questionType == 'single-choice') {
            slideQuestionsHtml = this.renderRadioElements(slide.questions)
        } else if (slide.questionType == 'multiple-choice') {
            slideQuestionsHtml = this.renderCheckboxElements(slide.questions)
        } else if (slide.questionType == 'true-false' && slide.columnLabels) {
            slideQuestionsHtml = this.renderTrueFalseElements(slide.questions, slide.columnLabels)
        }

        return slideQuestionsHtml;
    }

    private renderRadioElements(questions: Array<M85LearnControlQuizQuestion>) : string {
        let slideRadioElementsHTML = '';

        slideRadioElementsHTML += '<div class="as__radio-group">'

        questions.forEach(question => {
            slideRadioElementsHTML += M85LearnControlQuiz.QuestionRadioTemplate(question);
        });

        slideRadioElementsHTML += '</div>'

        return slideRadioElementsHTML;
    }

    private renderCheckboxElements(questions: Array<M85LearnControlQuizQuestion>) : string {
        let slideCheckboxElementsHTML = '';

        slideCheckboxElementsHTML += '<div class="as__checkbox-group">'

        questions.forEach(question => {
            slideCheckboxElementsHTML += M85LearnControlQuiz.QuestionCheckboxTemplate(question);
        });

        slideCheckboxElementsHTML += '</div>'

        return slideCheckboxElementsHTML;
    }

    private renderTrueFalseElements(questions: Array<M85LearnControlQuizQuestion>, columnLabels: M85LearnControlQuizSlideColumnLabels) : string {
        let slideTrueFalseElementsHTML = '';

        slideTrueFalseElementsHTML += '<div class="dws__m85-learn-control-quiz__true-false-question-group">'
        slideTrueFalseElementsHTML += `<div class="dws__m85-learn-control-quiz__true-false-question-column-labels"><div class="dws__m85-learn-control-quiz__true-false-question-column-label">${columnLabels.firstColumn}</div><div class="dws__m85-learn-control-quiz__true-false-question-column-label">${columnLabels.lastColumn}</div></div>`

        questions.forEach(question => {
            slideTrueFalseElementsHTML += M85LearnControlQuiz.QuestionTrueFalseTemplate(question, columnLabels);
        });

        slideTrueFalseElementsHTML += '</div>'

        return slideTrueFalseElementsHTML;
    }


    private onCurrentIndexUpdated(event : M85LearnControlQuizUpdateCurrentSlideIndexEvent) {
        this.updateNavSelectsValues();
        this.updateNavButtons();
        this.updateNavSelectWrapper();
        this.updateFinishSlide(event);
    }

    private updateNavSelectsValues() {
        if (!this.elements.navSelects.length) throw new Error('1652458841 LearnControlQuiz JS – Can\'t update nav select');

        this.elements.navSelects.forEach(navSelect => {
            // Don't update value if the value is the same or current index is finish slide
            if (navSelect.value == this.currentIndex || this.currentIndex === 'finish') return
            navSelect.value = typeof this.currentIndex == 'number' ? this.currentIndex.toString() : this.currentIndex
        });
    }

    private updateNavButtons() {
        if (this.elements.startBtnWrapper instanceof HTMLElement) {
            this.elements.startBtnWrapper.classList.toggle(M85LearnControlQuiz.States.HideNavBtnWrapper, this.currentIndex !== 'start')
        }

        if (this.elements.prevBtnWrapper instanceof HTMLElement) {
            this.elements.prevBtnWrapper.classList.toggle(M85LearnControlQuiz.States.HideNavBtnWrapper, this.currentIndex === 'start' || this.currentIndex === 'end' || this.currentIndex === 'finish')

        if (this.elements.prevBtn instanceof HTMLElement) {
            this.elements.prevBtn.classList.toggle(M85LearnControlQuiz.States.DisableNavBtn, this.currentIndex === 1)
        }}

        if (this.elements.nextBtnWrapper instanceof HTMLElement) {
            this.elements.nextBtnWrapper.classList.toggle(M85LearnControlQuiz.States.HideNavBtnWrapper, this.currentIndex === 'start' || this.currentIndex === 'end' || this.currentIndex === 'finish' || this.currentIndex === this.slideCount)
        }

        if (this.elements.endBtnWrapper instanceof HTMLElement) {
            this.elements.endBtnWrapper.classList.toggle(M85LearnControlQuiz.States.HideNavBtnWrapper, this.currentIndex === 'end' || this.currentIndex !== this.slideCount)
        }

        if (this.elements.finishBtnWrapper instanceof HTMLElement) {
            this.elements.finishBtnWrapper.classList.toggle(M85LearnControlQuiz.States.HideNavBtnWrapper, this.currentIndex !== 'end')
        }
    }

    private updateNavSelectWrapper() {
        if (this.elements.navSelectWrapper instanceof HTMLElement) {
            this.elements.navSelectWrapper.classList.toggle(M85LearnControlQuiz.States.HideNavSelectWrapper, this.currentIndex === 'finish')
        }
    }

    private updateFinishSlide(event: M85LearnControlQuizUpdateCurrentSlideIndexEvent) {
        if (this.elements.retryBtnWrapper instanceof HTMLElement) {
            this.elements.retryBtnWrapper.classList.toggle(M85LearnControlQuiz.States.HideNavBtnWrapper, this.currentIndex !== 'finish')
        }

        if (!event.slideData || !instanceOfM85LearnControlQuizFinishSlide(event.slideData)) return;

        if (this.elements.retryBtn instanceof HTMLElement) {
            this.elements.retryBtn.classList.toggle(M85LearnControlQuiz.States.HideNavBtn, event.slideData.finishData.showCertificateLink)
        }

        if (this.elements.certificateBtn instanceof HTMLElement) {
            this.elements.certificateBtn.classList.toggle(M85LearnControlQuiz.States.HideNavBtn, !event.slideData.finishData.showCertificateLink)
            this.elements.certificateBtn.setAttribute(M85LearnControlQuiz.Attributes.CertificateBtnHref, event.slideData.finishData.certificateLink)
        }

        this.elements.evaluationTrigger = this.elements.container.querySelector(M85LearnControlQuiz.Selectors.EvaluationTrigger);
        if ((this.elements.evaluationTrigger instanceof HTMLElement)) {
            this.elements.evaluationTrigger.addEventListener('click', this.onEvaluationTriggerClick.bind(this));
        }

        this.elements.solutionModalTrigger = this.elements.container.querySelector(M85LearnControlQuiz.Selectors.SolutionModalTrigger);
        if (this.elements.solutionModalTrigger instanceof HTMLElement) {
            this.elements.solutionModalTrigger.addEventListener('click', this.onSolutionModalTriggerClick.bind(this));
        }

        this.elements.solutionModeTrigger = this.elements.container.querySelector(M85LearnControlQuiz.Selectors.SolutionModeTrigger);
        if ((this.elements.solutionModeTrigger instanceof HTMLElement)) {
            this.elements.solutionModeTrigger.addEventListener('click', this.onSolutionModeTriggerClick.bind(this));
        }
    }


    private onNavButtonClick(direction: 'prev'|'next') {
        // Nav button can only be used to navigate between question slides, not start/end/finish slide
        if (typeof this.currentIndex != 'number') return

        if (direction === 'prev' && this.currentIndex > 1) {
            this.goToSlide(this.currentIndex - 1);
        }

        if (direction === 'next' && this.currentIndex !== this.slideCount) {
            this.goToSlide(this.currentIndex + 1);
        }
    }


    private restart() {
        this.clearSavedData()
            .then(() => {
                this.isSavedDataCleared = false;
                this.goToSlide('start');
            }).catch(error => {
                return this.handleSaveError(error.message);
            })
    }

    private clearSavedData() : Promise<boolean> {
        if (this.isSavedDataCleared) return new Promise((resolve, reject) => resolve(true))

        this.elements.container.classList.add(M85LearnControlQuiz.States.IsLoading);
        return axios.post(this.rootUrl + this.clearDataAction, JSON.stringify({clearSavedData: true}), {
            headers: {"Content-Type": "application/json"}
        }).then(response => {
            if (response.status === 200) {
                if (!response.data || !response.data.success) {
                    throw new Error('1652459422 LearnControlQuiz JS – Could\'t clear saved data')
                }

                this.lastSavedIndex = null
                this.isSavedDataCleared = true
                this.elements.container.classList.remove(M85LearnControlQuiz.States.IsLoading);
                return response.data.success
            } else {
                return this.handleSaveError(response.data.message);
            }
        }).catch((error) => {
            return this.handleSaveError(error.message);
        });
    }


    private onEvaluationTriggerClick(event: MouseEvent) {
        event.preventDefault();

        if (this.evaluationMode) return;

        this.evaluationMode = true;
        this.elements.container.classList.add(M85LearnControlQuiz.States.EvaluationMode);
        this.goToSlide(1);
    }

    private onEvaluationCloseTriggerClick(event: MouseEvent) {
        event.preventDefault();

        if (!this.evaluationMode) return;

        this.goToSlide('finish', () => {
            this.evaluationMode = false;
            this.elements.container.classList.remove(M85LearnControlQuiz.States.EvaluationMode);
        });
    }

    private loadSlideEvaluation(slideData: M85LearnControlQuizSlide|M85LearnControlQuizStaticSlide|M85LearnControlQuizFinishSlide|M85LearnControlQuizStartSlide|false, slideIndex: number|'start'|'end'|'finish') : Promise<M85LearnControlQuizSlideEvaluationResponse> {
        if (!this.isSlideIndexQuestion(slideIndex) || !this.evaluationMode) return new Promise((resolve, reject) => resolve({slideData: slideData}))

        this.elements.container.classList.add(M85LearnControlQuiz.States.IsLoading);

        return axios.post(this.rootUrl + this.evaluationAction, JSON.stringify({slideIndex: slideIndex}), {
            headers: {"Content-Type": "application/json"}
        }).then(response => {
            if (response.status === 200) {
                if (!response.data || !response.data.slideEvaluation) {
                    throw new Error('1653989825 LearnControlQuiz JS – Empty response')
                }

                this.elements.container.classList.remove(M85LearnControlQuiz.States.IsLoading);
                return {slideData: slideData, slideEvaluation: response.data.slideEvaluation};
            } else {
                this.handleError(response.data.message)
                return {slideData: slideData};
            }
        }).catch((error) => {
            this.handleError(error.message)
            return {slideData: slideData};
        });
    }


    private onSolutionModalTriggerClick(event: MouseEvent) {
        if (!this.solutionModeUserSetting) {
            this.solutionModal && this.solutionModal.showModal();
        } else {
            this.solutionMode = true;
            this.elements.container.classList.add(M85LearnControlQuiz.States.SolutionMode);
            this.goToSlide(1);
        }
    }

    private onSolutionCloseTriggerClick(event: MouseEvent) {
        event.preventDefault();

        if (!this.solutionMode) return;

        this.goToSlide('finish', () => {
            this.solutionMode = false;
            this.elements.container.classList.remove(M85LearnControlQuiz.States.SolutionMode);
        });
    }

    private onSolutionModeTriggerClick(event: MouseEvent) {
        event.preventDefault();

        if (this.solutionMode) return;

        axios.post(this.rootUrl + this.solutionModeAction, JSON.stringify({}), {
            headers: {"Content-Type": "application/json"}
        }).then(response => {
            if (response.status === 200) {
                if (!response.data || response.data.solutionModeUserSetting === null || response.data.solutionModeUserSetting === undefined) {
                    throw new Error('1657705427 LearnControlQuiz JS – Empty response')
                }

                this.solutionModeUserSetting = response.data.solutionModeUserSetting
                this.solutionMode = true;
                this.solutionModal && this.solutionModal.closeModal();
                this.elements.container.classList.add(M85LearnControlQuiz.States.SolutionMode);
                this.goToSlide(1);
            } else {
                this.handleSolutionModeError(response.data.message)
            }
        }).catch((error) => {
            this.handleSolutionModeError(error.message)
        });
    }

    private loadSlideSolution(slideEvaluationResponse: M85LearnControlQuizSlideEvaluationResponse, slideIndex: number|'start'|'end'|'finish') : Promise<M85LearnControlQuizSlideSolutionResponse> {
        if (!this.isSlideIndexQuestion(slideIndex) || !this.solutionMode) return new Promise((resolve, reject) => resolve(slideEvaluationResponse))

        this.elements.container.classList.add(M85LearnControlQuiz.States.IsLoading);

        return axios.post(this.rootUrl + this.solutionAction, JSON.stringify({slideIndex: slideIndex}), {
            headers: {"Content-Type": "application/json"}
        }).then(response => {
            if (response.status === 200) {
                if (!response.data || !response.data.slideSolution) {
                    throw new Error('1657705754 LearnControlQuiz JS – Empty response')
                }

                this.elements.container.classList.remove(M85LearnControlQuiz.States.IsLoading);
                return {...slideEvaluationResponse, slideSolution: response.data.slideSolution};
            } else {
                this.handleError(response.data.message)
                return slideEvaluationResponse
            }
        }).catch((error) => {
            this.handleError(error.message)
            return slideEvaluationResponse
        });
    }

    private scrollToContainerTop() {
        try {
            if (!this.elements.container) throw new Error('1658323662 LearnControlQuiz JS – No container element found')

            this.elements.container.scrollIntoView();
        } catch (error) {
            this.handleError(error)
        }
    }

    private handleError(message: string) {
        this.dispatchEvent(createCustomEvent('showFlashMessage', {
            message: message,
            messageType: 'alert-error'
        }));
        this.elements.container.classList.remove(M85LearnControlQuiz.States.IsLoading);
        console && console.error && console.error(message)
        return false
    }

    private handleSaveError(message: string) : boolean {
        this.lastSavedIndex = null;
        return this.handleError(message);
    }

    private handleSolutionModeError(message: string) : boolean {
        this.solutionMode = false;
        this.solutionModal && this.solutionModal.closeModal();
        this.elements.container.classList.remove(M85LearnControlQuiz.States.SolutionMode);
        return this.handleError(message);
    }
}

function instanceOfM85LearnControlQuizStaticSlide(object: any): object is M85LearnControlQuizStaticSlide {
    return 'content' in object;
}

function instanceOfM85LearnControlQuizFinishSlide(object: any): object is M85LearnControlQuizFinishSlide {
    return 'finishData' in object;
}

function instanceOfM85LearnControlQuizStartSlide(object: any): object is M85LearnControlQuizStartSlide {
    return 'startContentHTML' in object;
}


onDOMContentLoaded(() => {
    window.AS = window.AS || {}

    document.querySelectorAll(M85LearnControlQuiz.Selectors.Container).forEach((element) => {
        if(!(element instanceof HTMLFormElement)) {
            return;
        }

        new M85LearnControlQuiz(element);
    });
});
