import axios from "axios";
import Search from "../search/search";

interface AutosuggestionDOM {
    container: HTMLElement | null
    input: HTMLInputElement
    results: HTMLElement | null
    showResultsBtn: HTMLElement | null
    showAllResultsBtn: HTMLElement | null
}

interface ResponseData {
    id?: number
    path?: string
    title?: string
    metaTitle?: string
    content?: string
    isTopic?: boolean
    published?: boolean
    excludeFromSearch?: boolean
}

export default class Autosuggestion {
    public static readonly Selectors = {
        container: '.dws__suc6__autosuggest',
        results: '.dws__suc6__autosuggest__results',
        showResultsBtn: '.dws__search-header__form-search',
        showAllResultsBtn: '.dws__suc6__autosuggest__action-link',
    }

    public static readonly Classnames = {
        autosuggestionActive: 'dws__suc6__autosuggest--active',
    }

    private readonly autosuggestion: AutosuggestionDOM
    public filterOption: string | null
    private currentResults: ResponseData[]
    private searchTerm: string | null
    private requestInProgress: boolean = false
    private isTypingTimeout: any
    private isTypingDelay: number = 300
    public constructor(element: HTMLElement, inputElement: HTMLInputElement) {
        this.autosuggestion = {
            container: element.querySelector(Autosuggestion.Selectors.container),
            input: inputElement,
            results: element.querySelector(Autosuggestion.Selectors.results),
            showResultsBtn: element.querySelector(Autosuggestion.Selectors.showResultsBtn),
            showAllResultsBtn: element.querySelector(Autosuggestion.Selectors.showAllResultsBtn),
        }

        if (!this.autosuggestion.container || !this.autosuggestion.results) {
            throw new Error("Container or results HTML not found");
        }

        this.closeOnClickOutsideHandler = this.closeOnClickOutsideHandler.bind(this);

        this.filterOption = null;
        this.currentResults = [];
        this.searchTerm = null;

        this.initEvent()
    }

    private initEvent(): void
    {
        this.autosuggestion.input.addEventListener('keyup', this.inputHandler.bind(this));
        this.autosuggestion.input.addEventListener('click', this.inputClickHandler.bind(this));
        this.autosuggestion.input.addEventListener('keydown', () => { clearTimeout(this.isTypingTimeout) });
        this.autosuggestion.input.addEventListener('keyup', this.showAllOnEnterHandler.bind(this));
        this.autosuggestion.showResultsBtn?.addEventListener('click', this.showAllHandler.bind(this))
        this.autosuggestion.showAllResultsBtn?.addEventListener('click', this.showAllHandler.bind(this))
    }

    private inputHandler(event: Event): void {
        if (!(event.target instanceof HTMLInputElement)) {
            return;
        }

        clearTimeout(this.isTypingTimeout)
        this.setSearchTerm(event.target.value);

        this.isTypingTimeout = setTimeout(() => {
            if (!(event.target instanceof HTMLInputElement)) {
                return;
            }
            
            this.clearAutosuggestion();
            if (event.target.value.length >= 3 && this.requestInProgress == false) {
                this.renderAutosuggestion(event.target.value);
            }

            if (event.target.value.length < 3) {
                this.closeDropdown();
            }
        }, this.isTypingDelay);
    }

    private inputClickHandler(event: Event): void {
        if (!(event.target instanceof HTMLInputElement) || !event.target.value || event.target.value.length < 3) {
            return;
        }

        this.showAutosuggestion();
    }

    private showAllOnEnterHandler(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            window.location.href = Search.generateLink(this.filterOption, this.getSearchTerm());
        }
    }

    private showAllHandler(event: Event): void {
        window.location.href = Search.generateLink(this.filterOption, this.getSearchTerm());
    }

    private setSearchTerm(searchterm: string | null): void {
        this.searchTerm = searchterm;
    }

    private getSearchTerm(): string | null {
        return this.searchTerm;
    }

    private renderAutosuggestion(term: string): void {
        const options = {
            url: Search.generateApiEndpoint(this.filterOption, term),
            config: { responseType: 'json' }
        }

        this.requestInProgress = true;

        axios(options)
            .then((response) => {
                if (response.status !== 200) {
                    throw new Error('no valid response');
                }

                return response.data as ResponseData[];
            })
            .then(results => {
                if (!results) {
                    throw new Error('no valid response');
                }

                this.currentResults = results;

                if (results.length === 0) {
                    this.appendNoResultFound();
                } else {
                    results.forEach(result => this.appendResult(result));
                }

                this.showAutosuggestion();
                this.requestInProgress = false
            })
            .catch(error => {
                this.requestInProgress = false
                console && console.error && console.error(error)
            });
    }

    private closeOnClickOutsideHandler(event: MouseEvent) {
        if(!(event.target instanceof HTMLElement)){
            return;
        }

        if (this.hasTargetAsParent(event.target, this.autosuggestion.container) || event.target == this.autosuggestion.input) {
            return;
        }

        this.closeDropdown();
    }

    private hasTargetAsParent( element: HTMLElement | null, parent: Element | null) {
        if (!element || !parent) return false
        return parent.contains(element)
    }

    private showAutosuggestion(): void {
        if (!this.autosuggestion.container) {
            return;
        }

        document.addEventListener('click', this.closeOnClickOutsideHandler)
        this.autosuggestion.container.classList.add(Autosuggestion.Classnames.autosuggestionActive)
    }

    private closeDropdown() {
        if (!this.autosuggestion.container) {
            return;
        }

        this.autosuggestion.container.classList.remove(Autosuggestion.Classnames.autosuggestionActive);
        document.removeEventListener('click', this.closeOnClickOutsideHandler, false);
    }

    private clearAutosuggestion(): void {
        if (this.autosuggestion.results) {
            this.autosuggestion.results.innerHTML = '';
        }
    }

    private appendResult(result: ResponseData): void {
        const link = document.createElement('a');
        link.setAttribute('href', window.location.origin + result.path);
        link.setAttribute('class', 'dws__suc6__autosuggest__item');
        link.innerHTML = <string>result.title;

        // TODO Icon missing
        // const svg = document.createElement('svg');
        // svg.setAttribute('role', 'img');
        // svg.setAttribute('class', 'as__icon');
        // const use = document.createElement('use');
        // use.setAttribute('xlink:href', '#as__icon__result-arrow');
        // svg.append(use);

        // link.append(svg);

        if (this.autosuggestion.results) {
            this.autosuggestion.results.append(link);
        }
    }

    private appendNoResultFound(): void {
        const link = document.createElement('span');
        link.setAttribute('class', 'dws__suc6__autosuggest__no-items-found')
        const linkTextNode = document.createTextNode('Leider nichts gefunden!');

        link.append(linkTextNode);

        if (this.autosuggestion.results) {
            this.clearAutosuggestion();
            this.autosuggestion.results.append(link);
        }
    }
}
