import {DeepPartial} from "../../base/util/deepPartial";
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import passiveEvent from "../../base/util/dom/passiveEvent";

interface VideoOptions {
    mute: 0 | 1;
    autoplay: 0 | 1;
}

interface VideoDOM {
    root: HTMLElement;
    mediaContainer: HTMLElement | null;
    textContainer: HTMLElement | null;
    playBtns: Array<HTMLElement>;
}

interface VideoFormat {
    type: 'vimeo' | 'youtube'
}

export default class Video {
    private videoBaseUrl = {
        youtube: "https://www.youtube-nocookie.com/embed/%id%?modestbranding=1&wmode=transparent",
        vimeo: "https://player.vimeo.com/video/%id%"
    };

    public static readonly Selectors = {
        root: ".dws__video",
        mediaContainer: ".dws__video__media-container",
        textContainer: ".dws__video__text-container",
        playBtns: ".dws__video__preview-playBtn, .dws__btn--video"
    };

    private static readonly Attributes = {
        videoId: "data-video-id",
        videoSrc: "data-video-src",
        videoType: "data-video-type"
    };

    private static readonly States = {
        hover: 'dws__video--hover',
        loaded: 'dws__video--loaded'
    }

    private readonly dom: VideoDOM;
    private readonly videoOptions: VideoOptions;

    private defaultVideoOptions: VideoOptions = {
        mute: 0,
        autoplay: 1
    }

    public constructor(element: HTMLElement, options: DeepPartial<VideoOptions> = {}) {
        this.dom = {
            root: element,
            mediaContainer: element.querySelector(Video.Selectors.mediaContainer),
            textContainer: element.querySelector(Video.Selectors.textContainer),
            playBtns: Array.prototype.slice.call(element.querySelectorAll(Video.Selectors.playBtns))
        };

        this.videoOptions = Object.assign(this.defaultVideoOptions, options)

        this.init();
    }

    private init() {
        this.initEvents();
        this.setVideoParams();
    }

    private initEvents() {
        if (!this.dom.playBtns || !this.dom.playBtns.length) {
            return;
        }

        this.dom.playBtns.forEach(playBtn => {
            playBtn.addEventListener(
                "click",
                this.playBtnClickHandler.bind(this),
                passiveEvent
            );

            playBtn.addEventListener(
                "mouseenter",
                this.playBtnMouseEnterHandler.bind(this),
                passiveEvent
            );

            playBtn.addEventListener(
                "mouseleave",
                this.playBtnMouseLeaveHandler.bind(this),
                passiveEvent
            );
        })
    }

    public static parseParams(paramsString: string): {} {
        let parameters = {}
        if(paramsString) {
            try {
                let params = JSON.parse(paramsString);
                parameters = Object.assign(parameters, params);
            } catch (error) {
                console.error(error.message)
            }
        }
       return parameters
    }

    private playBtnClickHandler(ev: MouseEvent) {
        const iframePlayer = this.getIframePlayer()
        if(!(iframePlayer instanceof HTMLIFrameElement)) {
            return
        }

        // @ts-ignore
        this.dom.textContainer.parentNode.removeChild(this.dom.textContainer)
        this.empty(this.dom.mediaContainer as HTMLElement)
        // @ts-ignore
        this.dom.mediaContainer.append(iframePlayer)

        this.dom.root.classList.add(Video.States.loaded)
        this.playBtnMouseLeaveHandler();
    }

    private playBtnMouseEnterHandler() {
        this.dom.root.classList.add(Video.States.hover)
    }

    private playBtnMouseLeaveHandler() {
        this.dom.root.classList.remove(Video.States.hover)
    }

    private getIframePlayer(): HTMLIFrameElement | undefined {
        const videoId = this.dom.root.getAttribute(Video.Attributes.videoId)
        const videoSrc = this.dom.root.getAttribute(Video.Attributes.videoSrc)
        const videoType = this.dom.root.getAttribute(Video.Attributes.videoType)

        if(!videoId) {
            return
        }

        const iFrame = document.createElement('iframe')
        iFrame.setAttribute( "id", videoId )
        iFrame.setAttribute( "allowfullscreen", "" )
        iFrame.setAttribute( "src", this.getEmbeddedVideoUrl(videoId, videoType, this.videoOptions) )
        return iFrame
    }

    private getEmbeddedVideoUrl(id: string, type: string | null, attributes: VideoOptions): string {
        if(!type || !id) {
            return ''
        }

        // @ts-ignore
        let videoUrl = this.videoBaseUrl[type] as string
        videoUrl = videoUrl.replace('%id%', id)
        if(Object.keys(attributes).length > 0) {
            for (let key of Object.keys(attributes)) {
                // @ts-ignore
                videoUrl += `&${key}=${attributes[key]}`
            }
        }

        if(videoUrl.indexOf('?') === -1) {
            videoUrl = videoUrl.replace('&', '?')
        }

        return videoUrl
    }

    private empty(node: HTMLElement): void {
        // Get the element's children as an array
        const nodeChildren = Array.prototype.slice.call(node.childNodes);
        // Remove each child node
        nodeChildren.forEach((child) => {
            node.removeChild(child);
        });
    }

    private setVideoParams(): void {

    }
}

onDOMContentLoaded(() => {
    const videos = Array.prototype.slice.call(document.querySelectorAll(Video.Selectors.root)) as HTMLElement[];
    if(videos.length > 0) {
        videos.forEach((video) => {
            if(video.getAttribute('data-video-params') !== null) {
                console.log('tiene attributos');
                let videoOptions = Video.parseParams(video.getAttribute('data-video-params') as string)
                new Video(video, videoOptions)
            } else {
                new Video(video, {})
            }
        })
    }
});

// vimeo 76979871
// youtube mK11Bsf4nWI
