import BaseSlider, {BaseSliderGoEvent} from "../../components/base-slider/base-slider";
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

onDOMContentLoaded(() => {
    document.querySelectorAll('.dws__m3-product-teaser-slider').forEach((element) => {
        if(!(element instanceof HTMLElement)) {
            return;
        }

        let isNotInfinite = Boolean(element.getAttribute('data-not-infinite'))

        const slider = new BaseSlider(element, {
            selectors: {
                container: '.dws__m3-product-teaser-slider__container',
                items: '.dws__m3-product-teaser-slider__item'
            },
            nav: {
                selectors: {
                    items: '.dws__m3-product-teaser-slider__nav-item',
                },
                classes: {
                    active: 'dws__m3-product-teaser-slider__nav-item--active',
                    hidden: 'dws__m3-product-teaser-slider__nav-item--hidden'
                }
            },
            arrows: {
                selectors: {
                    prev: '.dws__m3-product-teaser-slider__arrow--prev',
                    next: '.dws__m3-product-teaser-slider__arrow--next'
                },
                classes: {
                    hidden: 'dws__m3-product-teaser-slider__arrow--hidden'
                }
            },
            infinite: {
                enabled: !isNotInfinite
            }
        });

        const sliderNav = slider.getDOMElement('.dws__m3-product-teaser-slider__nav--desktop');
        const sliderNavItems = slider.getDOMElement('.dws__m3-product-teaser-slider__nav--desktop .dws__m3-product-teaser-slider__nav-item');
        const elementWidth = sliderNavItems.offsetWidth;

        if(sliderNav) {
            slider.addEventListener('go', (event : BaseSliderGoEvent) => {
                if(!element.parentElement) {
                    return;
                }

                if(event.index <= 3) {
                    if(typeof sliderNav.scroll !== "function") {
                        sliderNav.scrollLeft = 0;
                        return;
                    }
                    sliderNav.scroll({
                        left: 0,
                        top: 0,
                        behavior: 'smooth'
                    });
                } else {
                    if(typeof sliderNav.scroll !== "function") {
                        sliderNav.scrollLeft = elementWidth;
                        return;
                    }
                    sliderNav.scroll({
                        left: elementWidth,
                        top: 0,
                        behavior: 'smooth'
                    })
                }
            });
        }
    });
});
