import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import Accordion from "../../components/base-accordion/accordion";

onDOMContentLoaded(() => {
    /**
     * Usage of Accordion class with custom options object:
     */
    const options = {
        selectors: {
            accordion: '.as__accordion--m45-search-result',
            label: '.dws__m45-search-result__accordion-trigger'
        }
    };
    document.querySelectorAll(options.selectors.accordion).forEach((accordionElement) => {
        if(!(accordionElement instanceof HTMLElement)) {
            return;
        }

        new Accordion(accordionElement, options);
    });
});