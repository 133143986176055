import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

export default class ArticleOverviewItem {
    protected readonly articleOverviewForm: HTMLFormElement | null;
    protected readonly articleOverviewItem: HTMLElement | null;
    protected readonly itemSelect: HTMLSelectElement | null;

    public constructor(element: HTMLElement) {
        this.articleOverviewForm = document.querySelector('form.dws__m29-article-overview');
        this.articleOverviewItem = element;
        this.itemSelect = element.querySelector('.dws__m29-article-overview__item-select select')
        this.init()
    }

    private init() {
        if (!this.articleOverviewForm || !this.articleOverviewItem || !this.itemSelect) {
            return;
        }

        this.itemSelect.addEventListener('change',  () => {
            if (!(this.articleOverviewForm instanceof HTMLFormElement)) {
                return;
            }

            this.articleOverviewForm.submit();
        });
    }
}

onDOMContentLoaded(() => {
    document.querySelectorAll('.dws__m29-article-overview__item').forEach((element) => {
        if (element instanceof HTMLElement) {
            new ArticleOverviewItem(element);
        }
    });
});