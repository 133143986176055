import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

onDOMContentLoaded(() => {
    const pdsPage: HTMLElement | null = document.querySelector('.dws__pds-page');
    if (!pdsPage) {
        return;
    }
    const buyBoxCol: HTMLElement | null = pdsPage.querySelector('.dws__m20-buybox-col');
    if (!buyBoxCol) {
        return;
    }
    const pdsHeadline: HTMLElement | null = document.querySelector('.dws__pds-headline');

    pdsPage.style.minHeight = buyBoxCol.offsetHeight - (pdsHeadline ? pdsHeadline.offsetHeight : 0) + 'px';
});