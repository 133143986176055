import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import axios from "axios";

onDOMContentLoaded(() => {
    const lawTree = document.querySelector('.dws__law-tree');
    const requestUrl = window.location.origin + '/laws/children';
    const requestConfig = { responseType: 'json' };

    const init = function (lawTree: Element | null): void {
        if (!(lawTree instanceof HTMLElement)) {
            return;
        }

        let lawTreeNodes = lawTree.querySelectorAll('.dws__law-tree__node');

        Array.from(lawTreeNodes).forEach(node => {
            node.addEventListener("click", (event) => {
                if (event.target instanceof HTMLAnchorElement) {
                    return;
                }

                if (!node.hasAttribute('data-law-children-loaded')) {

                    let lawId = node.getAttribute('data-law-id') as string;
                    let depth = node.getAttribute('data-law-depth') as string;

                    if (!lawId) {
                        throw new Error("No 'data-law-id' found in node");
                    }

                    let requestOptions = {
                        url: requestUrl + '?law=' + lawId + '&depth=' + depth,
                        config: requestConfig,
                    };

                    axios(requestOptions)
                        .then((response) => {
                            if (response.status === 200) {
                                return response.data;
                            }
                        })
                        .then(results => {

                            if (results.length === 0) {
                                return;
                            }

                            appendChildrenHtml(node, results);
                            return node;
                        })
                        .then(node => {
                            toggleLawTree(node)
                        });
                } else {
                    toggleLawTree(node);
                }
            });
        });
    }

    const appendChildrenHtml = function (referenceNode: Element, childrenHtml: any): void {
        let element = htmlToElement(childrenHtml);

        if (!element || !referenceNode || !referenceNode.nextSibling || !referenceNode.parentNode) {
            return;
        }

        referenceNode.parentNode.insertBefore(element, referenceNode.nextSibling);
        referenceNode.setAttribute('data-law-children-loaded', 'true');

        init(referenceNode.nextSibling as Element)
    }

    const htmlToElement = function (html: String) {
        let div = document.createElement('div');
        div.innerHTML = html.trim();

        return div.firstChild;
    }

    const toggleLawTree = function (treeNode: Element | undefined) {
        if (!treeNode) {
            return;
        }

        const nextGroupElement = treeNode.nextElementSibling;
        if (!(nextGroupElement instanceof HTMLElement && nextGroupElement.classList.contains('dws__law-tree__group'))) {
            return;
        }

        nextGroupElement.classList.toggle('dws__law-tree__group--hidden');
    }

    init(lawTree);
});
