import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

export interface PaymentDataOverviewElements {
    inputResetButtons: NodeList | null,
    inputCancelButtons: NodeList | null,
}

export default class PaymentDataOverview {
    public static readonly Selectors = {
        PaymentDataOverviewContainer: '.dws__m36-payment-data-overview',
        InputResetButtons: '.dws__m36-payment-data-overview-modal__reset-btn',
        InputCancelButtons: '.dws__m36-payment-data-overview-modal__cancel-btn',
    };

    private elements : PaymentDataOverviewElements;

    constructor(element: HTMLElement) {
        this.elements = {
            inputResetButtons: element.querySelectorAll(PaymentDataOverview.Selectors.InputResetButtons),
            inputCancelButtons: element.querySelectorAll(PaymentDataOverview.Selectors.InputCancelButtons),
        }

        if (!(this.elements.inputResetButtons instanceof NodeList)) {
            throw new Error('could not find input reset buttons');
        }

        if (!(this.elements.inputCancelButtons instanceof NodeList)) {
            throw new Error('could not find cancel button');
        }

        this.elements.inputResetButtons.forEach(resetButton => {
            resetButton.addEventListener('click', this.resetForm.bind(this))
        })

        this.elements.inputCancelButtons.forEach(cancelButton => {
            cancelButton.addEventListener('click', () => document.documentElement.click());
        });
    }

    private resetForm(event: Event) : void {
        let input = event.target;

        if (!(input instanceof HTMLInputElement)) {
            return;
        }

        if (!(input.form instanceof HTMLFormElement)) {
            return;
        }

        Array.prototype.slice.call(input.form.elements).forEach(element => {
            if (element.getAttribute('type') === 'text') {
                element.setAttribute('value', '');
            }

            if (element.classList.contains('as__select')) {
                Array.prototype.slice.call(element.options).forEach(option => {
                    option.removeAttribute('disabled');
                    option.removeAttribute('selected');
                });

                element.firstElementChild.setAttribute('selected', 'true');
            }
        });
    }
}

onDOMContentLoaded(() => {
    const paymentDataOverviewContainer = document.querySelector(PaymentDataOverview.Selectors.PaymentDataOverviewContainer);
    if (!(paymentDataOverviewContainer instanceof HTMLElement)) {
        return;
    }
    new PaymentDataOverview(paymentDataOverviewContainer);
});
