/**
 * Loading class to add loading indicator
 */
export default class Loading {
    private readonly dom: {
        target: Element|null,
        container: Element,
        indicator: Element
    }

    private readonly settings: {
        variant: string
    }

    constructor(element: Element | null, variant?: string) {
        this.settings = {
            variant: variant ?? 'single'
        }

        this.dom = {
            target: element,
            container: document.createElement('div'),
            indicator: document.createElement('div')
        }

        if (!element) {
            return;
        }

        this.dom.container.classList.add('as__loading');
        this.dom.indicator.classList.add('as__loading-indicator', 'as__loading-indicator--' + this.settings.variant);
        this.dom.container.appendChild(this.dom.indicator);

        this.append();
    }

    private append() {
        this.dom.target?.appendChild(this.dom.container);
    }

    public destroy() {
        this.dom.target?.removeChild(this.dom.container);
    }
}