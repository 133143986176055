import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

// region INTERFACES
export interface ContentTableElements {
    containerElement: HTMLElement,
    contentTableElement: HTMLElement | null,
    linkElements: NodeListOf<HTMLElement> | null,
    header: HTMLElement | null,
    tableElements: NodeListOf<HTMLElement> | null,
}
// endregion INTERFACES

/**
 * Leaflet detail table of contents
 * scrolls to anchor onclick
 */
export default class ContentTable {
    public static readonly Selectors = {
        Container: '.dws__m63b-detail-factsheet',
        ContentTable: '.dws__m89-content-table',
        Link: '.dws__m89-content-table__link',
        Header: '.as__header',
        Tables: '.dws__m63b-detail-factsheet__rte table'
    };

    private static readonly Attributes = {
        Target: 'href',
    };

    private static readonly TableWrapperClass = 'dws__m63b-detail-factsheet__table-wrapper';

    private elements: ContentTableElements;

    constructor(element : HTMLElement) {
        this.elements = {
            containerElement: element,
            contentTableElement: element.querySelector(ContentTable.Selectors.ContentTable),
            linkElements: element.querySelectorAll(ContentTable.Selectors.Link),
            header: document.querySelector(ContentTable.Selectors.Header),
            tableElements: element.querySelectorAll(ContentTable.Selectors.Tables)
        }

        if (this.elements.linkElements) {
            this.elements.linkElements.forEach(link => link.addEventListener('click', (event) => this.onClick.bind(this)));
        }

        if (this.elements.tableElements && this.elements.tableElements.length) {
            this.elements.tableElements.forEach(table => this.wrapTableElement(table));
        }
    }

    /**
     * Wrap table elements into wrapper div
     *
     */
    private wrapTableElement(tableElement : HTMLElement) {
        const wrapperElement = document.createElement('div');
        wrapperElement.classList.add(ContentTable.TableWrapperClass);

        tableElement.parentNode && tableElement.parentNode.insertBefore(wrapperElement, tableElement);
        wrapperElement.appendChild(tableElement);
    }

    /**
     * Wrapper function for click related functions
     *
     */
    private onClick(event : Event) : void {
        if (!(event.target instanceof HTMLElement)) {
            throw new Error('could not access link');
        }

        const targetElement = document.querySelector(event.target.getAttribute(ContentTable.Attributes.Target) || '');

        if (!(targetElement instanceof HTMLElement)) {
            throw new Error('could not find target link element');
        }

        targetElement.scrollIntoView();
    }
}

onDOMContentLoaded(() => {
    document.querySelectorAll(ContentTable.Selectors.Container).forEach(contentTableElement => {
        if(!(contentTableElement instanceof HTMLElement)) {
            return;
        }

        new ContentTable(contentTableElement);
    });
});
