export default function rearrangeDom(
    container : HTMLElement,
    newChildren : Array<Element>,
    skipOldChildren : (element : Element) => boolean = (() => false)
): void {
    const newChildrenLength = newChildren.length;
    let newChildrenIndex = 0;
    let oldChildrenIndex = 0;

    while(newChildrenIndex < newChildrenLength) {
        const newChild = newChildren[newChildrenIndex++];
        let currentChild : Element|null;

        do {
            currentChild = container.children[oldChildrenIndex++];
        } while(currentChild && skipOldChildren(currentChild));

        if(!currentChild) {
            // reached the end of container.children; so we can just append
            container.appendChild(newChild);
        } else if(newChild === currentChild) {
            // already in correct position 
        } else {
            // otherwise insert the newChild in the correct position
            container.insertBefore(newChild, currentChild);
        }
    }

    while(container.children.length > oldChildrenIndex) {
        const node = container.children[oldChildrenIndex];

        if(!node) {
            // should not happen 
            break;
        }

        if(skipOldChildren(node)) {
            oldChildrenIndex++;
            continue;
        }

        container.removeChild(node);
    }
} 