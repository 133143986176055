import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

onDOMContentLoaded(() => {
    initFileInputs(document);
});

export default function initFileInputs(element: Document|Element) {
    element.querySelectorAll('.as__file-input').forEach((fileInputContainer) => {
        const Selectors = {
            Input: 'input',
            Filenames: '.as__file-input-filenames',
        };
        const Modifier = {
            Placeholder: 'as__file-input-placeholder',
        };
        const
            fileInput = fileInputContainer.querySelector(Selectors.Input),
            filenamesElement = fileInputContainer.querySelector(Selectors.Filenames);

        if(fileInput instanceof HTMLInputElement && filenamesElement instanceof HTMLElement) {
            fileInput.addEventListener('change', () => {
                const curFiles : FileList|null = fileInput.files;

                if(curFiles instanceof FileList) {
                    let filenames = '';

                    Array.prototype.forEach.call(curFiles, (file) => {
                        filenames += filenames === '' ? file.name : ',' + file.name;
                    });

                    if(filenames !== '') {
                        filenamesElement.innerHTML = filenames;
                        filenamesElement.classList.remove(Modifier.Placeholder);
                    }
                }
            });
        }
    });
}