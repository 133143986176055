import Loading from "./loading";
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

onDOMContentLoaded(() => {
    const trigger = document.querySelector('.js-loading-example');
    let loadingLayer = null;

    trigger?.addEventListener('click', (e) => {
        loadingLayer = new Loading(e.currentTarget as Element, 'triple');
    })
});