import Hammer from 'hammerjs';
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import {DeepPartial} from "../../base/util/deepPartial";
import assignOptions from "../../base/util/assignOptions";

interface FilterTabsSelectors {
    tab: string
    tabTrigger: string
    tabsContainer: string
}

interface FilterTabsDOM {
    root: HTMLElement
    tabs: Array<HTMLElement>
    tabTriggers: Array<HTMLInputElement>
    tabsContainer: HTMLElement | null
}

interface FilterTabsClassNames {
    tab: string
}

interface FilterTabsOptions {
    tabInitialOffset: number
    tabViewportOffset: number
}

export default class FilterTabs {

    public static readonly DefaultOptions : FilterTabsOptions = {
        tabInitialOffset: 50,
        tabViewportOffset: 70
    }

    protected readonly dom: FilterTabsDOM
    protected readonly selectors: FilterTabsSelectors
    protected readonly classNames: FilterTabsClassNames
    protected readonly options: FilterTabsOptions
    protected activeTabIndex: number = 0
    protected isMobile: boolean = false

    public constructor(element: HTMLElement, options: DeepPartial<FilterTabsOptions> = {}) {
        this.options = assignOptions(options, FilterTabs.DefaultOptions);

        this.dom = {
            root: element,
            tabs: [],
            tabTriggers: [],
            tabsContainer: null
        }

        this.selectors = {
            tab: '.dws__tab',
            tabTrigger: '.dws__tab__toggle',
            tabsContainer: '.dws__filter-tabs__items'
        }

        this.classNames = {
            tab: 'dws__tab'
        }

        this.dom.tabs = this.getTabs();
        this.dom.tabTriggers = this.getTabTriggers();
        this.dom.tabsContainer = element.querySelector(this.selectors.tabsContainer)
        this.activeTabIndex = this.getActiveTriggerIndex()
        if(this.activeTabIndex > 0) {
            this.setActiveTabPosition(this.activeTabIndex, this.options.tabInitialOffset, 'auto');
        }

        this.initEvents()
    }

    protected getTabs(): Array<HTMLElement> {
        return Array.prototype.slice.call(this.dom.root.querySelectorAll(this.selectors.tab))
    }

    protected getTabTriggers(): Array<HTMLInputElement> {
        return Array.prototype.slice.call(this.dom.root.querySelectorAll(this.selectors.tabTrigger))
    }

    protected getActiveTriggerIndex(): number {
        const  activeTrigger = this.dom.tabTriggers.filter((trigger)=>{
            return trigger.checked
        })
        return this.dom.tabTriggers.indexOf(activeTrigger[0]) || -1
    }

    protected setActiveTabPosition(index: number, offset: number = this.options.tabViewportOffset, animationBehavior: ScrollBehavior = "smooth"): void {
        const activeLabel = this.dom.tabs[index]
        const activeLabelOffset = activeLabel.offsetLeft
        this.scrollTo(activeLabelOffset - offset, 0, animationBehavior)
    }

    protected setActiveTrigger(index: number): void {
        this.dom.tabTriggers.forEach((trigger) => {
            if(this.activeTabIndex == this.dom.tabTriggers.indexOf(trigger)) {
                trigger.checked = true
            } else {
                trigger.checked = false
            }
        })
    }

    protected initEvents() {

        if(this.dom.tabsContainer === null) {
            return
        }

        const tabsContainer = this.dom.tabsContainer;
        const tabsContainerHammer = new Hammer(tabsContainer);

        tabsContainerHammer.on("panstart tap touch press", (ev: any) => {
            switch (ev.type) {
                case 'panstart':
                    if(ev.additionalEvent === 'panleft') {
                        this.activeTabIndex < (this.dom.tabs.length - 1) ? this.activeTabIndex++ : null
                    } else if (ev.additionalEvent === 'panright') {
                        this.activeTabIndex > 0 ? this.activeTabIndex-- : null
                    }
                    this.setActiveTabPosition(this.activeTabIndex)
                    this.setActiveTrigger(this.activeTabIndex)
                    break
                case 'tap':
                case 'touch':
                case 'press':
                    if(ev.target instanceof HTMLLabelElement) {
                        const labelIndex = this.dom.tabs.indexOf(ev.target)
                        this.activeTabIndex = labelIndex
                        this.setActiveTabPosition(labelIndex)
                    }
                    break
                default:
                    break
            }
        });
    }

    private scrollTo(left: number, top: number = 0, behavior: ScrollBehavior = 'smooth'): void {
        this.dom.tabsContainer!.scrollTo({
            left: left,
            top: top,
            behavior: behavior
        });
    }
}

onDOMContentLoaded(() => {
    const filterTabsDOM = document.querySelector('.dws__filter-tabs');
    if (!(filterTabsDOM instanceof HTMLElement)) {
        return;
    }
    const filterTabs = new FilterTabs(filterTabsDOM, {});

});
