import assignOptions from '../../base/util/assignOptions';
import {DeepPartial} from '../../base/util/deepPartial';

export interface FlashMessageOptions {
    selectors: {
        container: string
    },
    template: (message: string, type: string) => string,
    allowMultiple: boolean,
    attributes: {
        allowMultiple: string
    },
    states: {
        hasItems: string
    }
}

export default class FlashMessage {
    public static readonly DefaultOptions : FlashMessageOptions = {
        selectors: {
            container: '.dws__flash-message'
        },
        template: (message : string, type : string ) => `<div class="dws__flash-message__message dws__flash-message__message--${type}"><svg role="img" class="as__icon dws__flash-message__icon"><use xlink:href="#as__icon__${type}"/></svg>${message}</div>`,
        allowMultiple: false,
        attributes: {
            allowMultiple: 'data-allow-multiple'
        },
        states: {
            hasItems: 'dws__flash-message--has-items'
        }
    }

    protected options: FlashMessageOptions;
    private container : HTMLElement;
    constructor(element : HTMLElement, options : DeepPartial<FlashMessageOptions> = {}) {
        this.options = assignOptions(options, FlashMessage.DefaultOptions);
        this.container = element
    }

    public insertFlashMessage(message : string, type: string = 'alert') {
        if (!message) throw new Error('No message string');

        const flashMessage = this.options.template(message, type);

        if (!this.options.allowMultiple) {
            this.container.innerHTML = ''
        }

        this.container.insertAdjacentHTML('beforeend', flashMessage);
        this.container.classList.add(this.options.states.hasItems)
    }
}
