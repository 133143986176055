// region IMPORTS
// base utilities
import {DeepPartial} from "../../base/util/deepPartial";
import assignOptions from "../../base/util/assignOptions";
import BodyNoScroll from '../../base/util/dom/BodyNoScroll';
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import passiveEvent from "../../base/util/dom/passiveEvent";
// components
import BaseAccordion, {
    BaseAccordionChangeEvent
} from "../../components/base-accordion/base-accordion";
// sub modules
import {HeaderNav, HeaderNavOptions} from "./header-nav";
// endregion IMPORTS

// region INTERFACES
export interface HeaderOptions {
    noScrollIdentifier: string,

    selectors: {
        header: string,
        toggle: string
        nav: string,
        miscNav: string,
        shopNav: string,
        accountNav: string
    },

    nav: HeaderNavOptions,
    shopNav: HeaderNavOptions,

    states:{
        minified: string,
        allowTransitions?: string
    },

    attributes?: {
        scrollOffset?: string
    },

    scrollOffset: number

    shopNavBadge: {
        selector: string,
        class: string,
        attribute: string
    }
}

// endregion INTERFACES
export default class Header {
    public static readonly DefaultOptions : HeaderOptions = {
        noScrollIdentifier: 'as__header',

        selectors: {
            header: '.as__header:not(.dws__header--reduced)',
            toggle: '.as__header__nav-icon-toggle',
            nav: '.as__header__nav-menu',
            miscNav: '.as__header__misc-nav-menu',
            shopNav: '.dws__header__shop-nav__wrapper',
            accountNav: '.dws__nav-menu__account.as__header__nav-menu'
        },

        nav: {
            selectors: {
                toggle: ':scope > .as__header__nav-menu-item > .as__header__nav-item-toggle',
                content: '.as__header__nav-item-toggle[id=%ID%] ~ .as__header__nav-menu--submenu',
                label: '.as__header__nav-item-toggle[id=%ID%] ~ .as__header__nav-item-label',
                container: '.as__header-nav'
            }
        },

        shopNav: {
            selectors: {
                toggle: ':scope > .dws__header__shop-nav-menu-item > .as__header__nav-item-toggle',
                content: '.as__header__nav-item-toggle[id=%ID%] ~ .dws__header__shop-nav-menu--submenu',
                label: '.as__header__nav-item-toggle[id=%ID%] ~ .dws__header__shop-nav-menu-item-label',
                container: '.dws__header__shop-nav'
            }
        },

        states:{
            minified: 'as__header--minified',
            allowTransitions: 'as__header__nav--allow-transitions'
        },

        attributes: {
            scrollOffset: 'data-scroll-offset'
        },

        scrollOffset: 0,

        shopNavBadge: {
            class: 'dws__header__shop-nav__badge',
            attribute: 'data-icon-badge',
            selector: 'div[data-icon-badge]',
        }
    };

    private readonly options : HeaderOptions;
    private readonly element : HTMLElement;
    private readonly headerAccordion : BaseAccordion;
    private readonly nav : HeaderNav;
    private navContainer : HTMLElement | null = null;
    private readonly miscNav : HeaderNav;
    private readonly shopNav : HeaderNav;
    private readonly accountNav : HeaderNav|null;
    private readonly scrollOffset : number;

    private readonly Direction = {
        INIT: 0,
        DOWN: 1,
        UP: 2,
    };

    private currentScroll = 0;
    private previousScroll = window.pageYOffset;
    private currentDirection = this.Direction.INIT;
    private previousDirection = this.Direction.INIT;
    private isNavOpen: boolean = false;


    constructor(element : HTMLElement, options : DeepPartial<HeaderOptions> = {}) {
        this.options = assignOptions(options, Header.DefaultOptions);

        const navElement = element.querySelector(this.options.selectors.nav);

        if(!(navElement instanceof HTMLElement)) {
            throw new Error('could not find navElement with selector "' + this.options.selectors.nav + '"');
        }

        const miscNavElement = element.querySelector(this.options.selectors.miscNav);

        if(!(miscNavElement instanceof HTMLElement)) {
            throw new Error('could not find navElement with selector "' + this.options.selectors.miscNav + '"');
        }

        const shopNavElement = element.querySelector(this.options.selectors.shopNav);

        if(!(shopNavElement instanceof HTMLElement)) {
            throw new Error('could not find navElement with selector "' + this.options.selectors.shopNav + '"');
        }

        const accountNavElement = element.querySelector(this.options.selectors.accountNav);

        this.element = element;
        this.headerAccordion = this.prepareHeaderAccordion();
        this.nav = new HeaderNav(navElement, this.options.nav);
        this.miscNav = new HeaderNav(miscNavElement, this.options.nav);
        this.shopNav = new HeaderNav(shopNavElement, this.options.shopNav);
        this.accountNav = accountNavElement instanceof HTMLElement ? new HeaderNav(accountNavElement, this.options.nav) : null;
        this.scrollOffset = this.options.scrollOffset;

        this.prepareHeaderNavContainer();
        this.renderIconBadge();

        document.addEventListener('click', this.onDocumentClick.bind(this), passiveEvent);
        window.addEventListener('scroll', this.onScroll.bind(this), passiveEvent);
        window.addEventListener('resize', this.onWindowResize.bind(this), passiveEvent);
    }

    getElement() : HTMLElement {
        return this.headerAccordion.getContainer();
    }

    private prepareHeaderNavContainer() : void {
        if (!this.options.nav.selectors.container || !this.options.states.allowTransitions) {
            return
        }

        this.navContainer = this.element.querySelector(this.options.nav.selectors.container)

        if (!(this.navContainer instanceof HTMLElement)) {
            return
        }

        this.navContainer.classList.add(this.options.states.allowTransitions);
    }

    private renderIconBadge() {
        const iconWrappers = this.element.querySelectorAll(this.options.shopNavBadge.selector);

        iconWrappers.forEach(wrapper => {
            const iconBadge = wrapper.getAttribute(this.options.shopNavBadge.attribute);
            let textNode = undefined;

            // values for "iconBadge" defined in "templates/includes/partials/header-shop-nav.html.twig"
            if (iconBadge === 'wishlist') {
                // window prop defined in "\App\EventSubscriber\CartHeaderEventSubscriber"
                textNode = window.AS.globalConfig.shop.wishListItems > 0 ? document.createTextNode(window.AS.globalConfig.shop.wishListItems) : undefined;
            }

            if (iconBadge === 'cart') {
                textNode = window.AS.globalConfig.shop.cartItems > 0 ? document.createTextNode(window.AS.globalConfig.shop.cartItems) : undefined;
            }

            if (textNode) {
                const badge = document.createElement('span');
                badge.classList.add(this.options.shopNavBadge.class);
                badge.append(textNode);
                wrapper.append(badge);
            }
        });
    }

    private onWindowResize() : void {
        let tooggleNoScroll = this.isNavOpen;

        // Disable BodyNoScroll on desktop views
        if (window.matchMedia('(min-width: 1024px)').matches) {
            tooggleNoScroll = false
        }

        BodyNoScroll.toggleNoScroll(this.options.noScrollIdentifier, tooggleNoScroll);
    }

    private onDocumentClick(event : MouseEvent) : void {
        if(!(event.target instanceof HTMLElement)) {
            return;
        }

        if(event.target.closest(this.options.selectors.shopNav)) {
            this.miscNav.close();
            this.nav.close();
            return;
        }

        if(event.target.closest(this.options.selectors.miscNav)) {
            this.shopNav.close();
            this.nav.close();
            return;
        }

        if(event.target.closest(this.options.selectors.nav)) {
            this.shopNav.close();
            this.miscNav.close();
            return;
        }

        this.nav.close();
        this.miscNav.close();
        this.shopNav.close();
    }

    private prepareHeaderAccordion() : BaseAccordion {
        const headerAccordion = new BaseAccordion(this.element, {
            selectors: {
                toggle: this.options.selectors.toggle
            }
        });

        headerAccordion.addEventListener('change', (event : BaseAccordionChangeEvent) => {
            this.isNavOpen = event.item.open;
            BodyNoScroll.toggleNoScroll(this.options.noScrollIdentifier, event.item.open);
        });

        return headerAccordion;
    }

    /**
     * Wrapper function for window scroll related functions
     *
     */
    private onScroll() : void {
        this.currentScroll = window.pageYOffset;

        // check scroll direction
        if (this.currentScroll > this.previousScroll) {
            this.currentDirection = this.Direction.DOWN;
        } else {
            this.currentDirection = this.Direction.UP;
        }

        if (this.currentDirection !== this.previousDirection && this.currentScroll > this.scrollOffset) {
            this.toggleHeader();
        }

        this.previousScroll = this.currentScroll;
    }

    /**
     * Toggle header element if current scroll top is higher than scroll offset
     *
     */
    private toggleHeader() : void {
        const header =  this.element;

        if (!(header instanceof Element)) {
            return;
        }

        if (this.currentDirection === this.Direction.UP) {
            header.classList.remove(this.options.states.minified);
        } else if (this.currentDirection === this.Direction.DOWN) {
            header.classList.add(this.options.states.minified);
        }

        this.previousDirection = this.currentDirection;
    }
}

onDOMContentLoaded(() => {
    document.querySelectorAll(Header.DefaultOptions.selectors.header).forEach((headerElement, index) => {
        if(!(headerElement instanceof HTMLElement)) {
            return;
        }

        let scrollOffset: number = Header.DefaultOptions.scrollOffset;

        if (Header.DefaultOptions.attributes && Header.DefaultOptions.attributes.scrollOffset) {
            scrollOffset = parseInt(headerElement.getAttribute(Header.DefaultOptions.attributes.scrollOffset) || Header.DefaultOptions.scrollOffset.toString(), 10);
        }

        new Header(headerElement, {
            noScrollIdentifier: Header.DefaultOptions.noScrollIdentifier + '_' + index,
            scrollOffset: scrollOffset,
            states: {
                minified: 'dws__header--minified'
            }
        });
    });
});
