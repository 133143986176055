import assignOptions, {DeepPartial} from '../../base/util/assignOptions';
import passiveEvent from '../../base/util/dom/passiveEvent';

export interface ProductCarouselPopupOptions {
    selectors: {
        root: string,
        toggle: string,
        trigger: string,
        contentWrapper: string,
    },
    attributes: {
        expanded: string
    }
}

export interface ProductCarouselPopupDOM {
    root: HTMLElement,
    toggle: HTMLInputElement | null,
    trigger: HTMLElement | null,
    contentWrapper: HTMLElement | null
}

export default class ProductCarouselPopup {
    public static readonly DefaultOptions : ProductCarouselPopupOptions = {
        selectors: {
            root: '.dws__product-carousel-tile__popup-container',
            toggle: '.dws__product-carousel-tile__popup-toggle',
            trigger: '.dws__product-carousel-tile__popup-trigger',
            contentWrapper: '.dws__product-carousel-tile__popup-wrapper'
        },
        attributes: {
            expanded: 'aria-expanded'
        }
    };

    protected readonly options : ProductCarouselPopupOptions;

    protected readonly dom : ProductCarouselPopupDOM;

    public constructor(element : HTMLElement, options : DeepPartial<ProductCarouselPopupOptions> = {}) {
        this.options = assignOptions(options, ProductCarouselPopup.DefaultOptions);

        this.dom = {
            root: element,
            toggle: element.querySelector(this.options.selectors.toggle),
            trigger: element.querySelector(this.options.selectors.trigger),
            contentWrapper: element.querySelector(this.options.selectors.contentWrapper)
        };

        if (!(this.dom.toggle instanceof HTMLInputElement) ||
            !(this.dom.trigger instanceof HTMLElement) ||
            !(this.dom.contentWrapper instanceof HTMLElement)) {
            throw new Error('Required elements not found');
        }

        document.addEventListener('click', this.onOutsideClick.bind(this), passiveEvent);
        this.dom.toggle.addEventListener('change', this.onToggleChange.bind(this), passiveEvent);
        this.dom.toggle.addEventListener('click', this.onInsideClick.bind(this), passiveEvent);
        this.dom.contentWrapper.addEventListener('click', this.onInsideClick.bind(this), passiveEvent);
    }

    private onOutsideClick() : void {
        if (!(this.dom.toggle instanceof HTMLInputElement)) {
            return;
        }

        if (!this.dom.toggle.checked) {
            return
        }

        this.dom.toggle.checked = false;
        this.onToggleChange();
    }

    private onInsideClick(event: MouseEvent) : void {
        event.stopPropagation();
    }

    private onToggleChange() : void {
        if (!(this.dom.toggle instanceof HTMLInputElement) || !(this.dom.trigger instanceof HTMLElement)) {
            return;
        }

        if (this.dom.trigger.getAttribute(this.options.attributes.expanded) === String(this.dom.toggle.checked)) {
            return
        }

        this.dom.trigger.setAttribute(this.options.attributes.expanded, String(this.dom.toggle.checked))
    }
}
