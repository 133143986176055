import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import EventTarget, {createCustomEvent} from '../../base/util/dom/EventTarget';
import FlashMessage from '../../components/flash-message/flash-message';

export default class LeafletDBProductUpdates extends EventTarget {
    public static readonly Selectors = {
        Form: '.dws__m61-leaflet-db-search__product-updates-form',
        Input: '.dws__m61-leaflet-db-search__product-updates-input'
    };

    public static readonly Attributes = {
        Action: 'action'
    };

    public static readonly States = {
        Loading: 'dws__m61-leaflet-db-search__product-updates-form--loading',
        Initialized: 'dws__m61-leaflet-db-search__product-updates-form--initialized'
    };

    private static ERROR_ALERT = 'alert-error'
    private rootUrl : string
    private ajaxAction : string|null
    private initialInputState : boolean
    private elements : {form: HTMLFormElement, input: HTMLInputElement|null};

    constructor(element: HTMLFormElement) {
        super();

        this.elements = {
            form: element,
            input: element.querySelector(LeafletDBProductUpdates.Selectors.Input),
        }

        this.rootUrl = [window.location.protocol, '//', window.location.host].join('');
        this.ajaxAction = this.elements.form.getAttribute(LeafletDBProductUpdates.Attributes.Action);

        if (!this.ajaxAction) throw new Error('No ajax action found');
        if (!(this.elements.input instanceof HTMLInputElement)) throw new Error('Could not find input checkbox');
        this.initialInputState = this.elements.input.checked;

        this.elements.form.classList.add(LeafletDBProductUpdates.States.Initialized);
        this.elements.input.addEventListener('input', this.onInputInput.bind(this));
    }

    private onInputInput(event: Event) : void {
        event.preventDefault();

        const input = event.target;

        if (!(input instanceof HTMLInputElement) ) return;
        if (this.elements.form.classList.contains(LeafletDBProductUpdates.States.Loading)) return;

        this.toggleLoadingState(true);
        const request = new XMLHttpRequest();
        request.open('post', this.rootUrl + this.ajaxAction, true);
        request.onreadystatechange = this.onXHRReadyStateChange.bind(this, request);
        request.send();
    }

    private onXHRReadyStateChange(request : XMLHttpRequest) : void {
        if (request.readyState === XMLHttpRequest.DONE) {
            let newInputState;

            try {
                if (!request.response) {
                    throw new Error('Empty response');
                } else if (request.status === 200) {
                    const response = JSON.parse(request.response);

                    if (!response.success) throw new Error('Response not successful, aborting updateCurrentState');
                    if (typeof response.receiveProductUpdateMails !== 'boolean') throw new Error('Invalid receiveProductUpdateMails');

                    newInputState = response.receiveProductUpdateMails;
                    this.dispatchEvent(createCustomEvent('showFlashMessage', {
                        message: response.message,
                        messageType: `alert-${response.messageType}`
                    }))
                } else if (request.status === 500) {
                    throw new Error('Error 500, unknown error')
                }
            } catch (error) {
                newInputState = this.initialInputState;

                if (error.message) {
                    this.dispatchEvent(createCustomEvent('showFlashMessage', {
                        message: error.message,
                        messageType: LeafletDBProductUpdates.ERROR_ALERT
                    }))
                }
            }

            if (!(this.elements.input instanceof HTMLInputElement)) throw new Error('Could not find input checkbox');
            this.elements.input.checked = newInputState;
            this.toggleLoadingState(false);
        }
    }

    private toggleLoadingState(toggleLoadingState : boolean) {
        if (toggleLoadingState) {
            this.elements.form.classList.add(LeafletDBProductUpdates.States.Loading)
        } else {
            this.elements.form.classList.remove(LeafletDBProductUpdates.States.Loading)
        }
    }
}

onDOMContentLoaded(() => {
    window.AS = window.AS || {}

    const leafletDBProductUpdatesFormElements = document.querySelectorAll(LeafletDBProductUpdates.Selectors.Form);

    Array.prototype.slice.call(leafletDBProductUpdatesFormElements).forEach(leafletDBProductUpdatesFormElement => {
        if (!(leafletDBProductUpdatesFormElement instanceof HTMLFormElement)) return;

        const leafletDBProductUpdatesForm = new LeafletDBProductUpdates(leafletDBProductUpdatesFormElement);

        if (!(window.AS.FlashMessage instanceof FlashMessage)) {
            return
        }

        leafletDBProductUpdatesForm.addEventListener('showFlashMessage', event => {
            window.AS.FlashMessage.insertFlashMessage(event.message, event.messageType)
        })
    });
});
