import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

interface FilterFormSelectors {
    sorting: string
}

interface FilterFormDOM {
    form: HTMLFormElement
    sorting: HTMLSelectElement | null
}

export default class FilterForm {
    protected readonly dom: FilterFormDOM;
    protected readonly selectors: FilterFormSelectors;

    public constructor(element: HTMLFormElement) {
        this.selectors = {
            sorting: '#dws__select--filter__sorting'
        };

        this.dom = {
            form: element,
            sorting: element.querySelector(this.selectors.sorting)
        }

        this.initEvents()
    }

    protected initEvents() {
        if (this.dom.sorting && this.dom.form) {
            this.dom.sorting.addEventListener('change', (event) => {
                event.preventDefault()
                this.dom.form?.submit()
            })
        }
    }
}


onDOMContentLoaded(() => {
    const filterDOM = document.querySelector('.dws__m17-filter__form');
    if (!(filterDOM instanceof HTMLFormElement)) {
        return;
    }
    new FilterForm(filterDOM);
});