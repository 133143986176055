// region IMPORTS
// base utilities
import assignOptions from '../../base/util/assignOptions';
import {DeepPartial} from '../../base/util/deepPartial';
import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
// endregion IMPORTS

// region INTERFACES
export interface WatchlistCartSelectors {
    element: string,
    select: string,
    selectAll: string,
    input: string,
    item: string
}

export interface WatchlistCartAttributes {
    dataName: string,
    name: string
}

export interface WatchlistCartOptions {
    selectors: WatchlistCartSelectors,
    attributes: WatchlistCartAttributes,
}

export interface WatchlistCartDOM {
    element: HTMLElement,
    items: NodeListOf<HTMLElement>,
    selectAll: NodeListOf<HTMLElement>
}

export interface WatchlistCartItemDOM {
    item: HTMLElement,
    select: HTMLInputElement|null,
    inputs: NodeListOf<HTMLInputElement>
}
 // endregion INTERFACES

/**
 * WatchlistCart class.
 */
export default class WatchlistCart {
    public static readonly DefaultOptions : WatchlistCartOptions = {
        selectors: {
            element: '.dws__m29c-watchlist-cart',
            select: '.dws__m29c-watchlist-cart__select',
            selectAll: '.dws__m29c-watchlist-cart__select-all',
            input: '.dws__m29c-watchlist-cart__input',
            item: '.dws__m29c-watchlist-cart__item'
        },
        attributes: {
            dataName: 'data-watchlist-name',
            name: 'name'
        }
    }

    protected elements : WatchlistCartDOM;
    protected options: WatchlistCartOptions;
    protected itemContexts: Array<WatchlistCartItemDOM>;

    constructor(element : HTMLElement, options : DeepPartial<WatchlistCartOptions> = {}) {
        this.options = assignOptions(options, WatchlistCart.DefaultOptions);

        this.elements = {
            element: element,
            items: element.querySelectorAll(this.options.selectors.item),
            selectAll: element.querySelectorAll(this.options.selectors.selectAll)
        };

        this.itemContexts = [];

        if (!this.setupItemContexts()) {
            console && console.warn && console.warn('no items found, item contexts could not be setup')
            return;
        }

        this.itemContexts.forEach((itemContext) => {
            if (!itemContext.select) {
                console && console.warn && console.warn('no itemcontext select found')
                return false;
            }
            itemContext.select.addEventListener('change', this.onItemSelectChange.bind(this, itemContext))
        })

        this.elements.selectAll.forEach((selectAllElement) => {
            selectAllElement.addEventListener('click', this.onSelectAllClick.bind(this));
        })
    }

    private setupItemContexts() {
        if (!this.elements.items.length) {
            return false
        }

        this.elements.items.forEach((item : HTMLElement) => {
            const select = item.querySelector(this.options.selectors.select);
            const inputs = item.querySelectorAll(this.options.selectors.input);

            const itemContext = {item: item, select: select, inputs: inputs} as WatchlistCartItemDOM

            this.itemContexts.push(itemContext)
        })

        return true
    }

    private onItemSelectChange(itemContext: WatchlistCartItemDOM) {
        if (!event || !(event.target instanceof HTMLInputElement)) {
            console && console.warn && console.warn('no item select found')
            return
        }

        this.toggleCartItemNameAttr(itemContext.inputs, event.target.checked)
    }

    private toggleCartItemNameAttr(itemContextInputs: NodeListOf<HTMLInputElement>, toggleCartItemNameAttr : boolean) {
        itemContextInputs.forEach((input) => {
            const inputNameVal = input.getAttribute(this.options.attributes.dataName);

            if (!inputNameVal) {
                console && console.warn && console.warn('no input name found')
                return false
            }

            if (toggleCartItemNameAttr) {
                input.setAttribute(this.options.attributes.name, inputNameVal)
            } else {
                input.removeAttribute(this.options.attributes.name)
            }
        })
    }

    private onSelectAllClick() {
        this.triggerAllItemsSelectChange();
    }

    private triggerAllItemsSelectChange() {
        if (!this.itemContexts.length) {
            console && console.warn && console.warn('no item contexts found')
            return
        }

        this.itemContexts.forEach((itemContext) => {
            if (!itemContext.select) {
                console && console.warn && console.warn('no item select found')
                return
            }

            if (!itemContext.select.disabled) {
                itemContext.select.checked = true
            }

            itemContext.select.dispatchEvent(new Event('change'));
        })
    }
}

onDOMContentLoaded(() => {
    document.querySelectorAll(WatchlistCart.DefaultOptions.selectors.element).forEach((watchlistCartElement) => {
        if(!(watchlistCartElement instanceof HTMLElement)) {
            return;
        }

        new WatchlistCart(watchlistCartElement);
    });
});
