import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import assignOptions, {DeepPartial} from "../../base/util/assignOptions";

export interface TextAnchorOptions {
    offset: number
}

export default class TextAnchor {
    public static Selectors = {
        root: '.dws__m81-text-anchor',
        scrollButton: '.dws__m81-text-anchor__link'
    }

    private readonly root: HTMLElement
    private scrollButton: HTMLElement | null;
    public static readonly DefaultOptions: TextAnchorOptions = {
        offset: 50
    }
    private options: TextAnchorOptions;

    constructor(element: HTMLElement, options: DeepPartial<TextAnchorOptions> = {}) {
        this.root = element
        this.scrollButton = null
        this.options = assignOptions(options, TextAnchor.DefaultOptions);

        this.init()
    }

    public init(): void {
        if(!(this.root instanceof HTMLElement)) {
            return
        }
        this.scrollButton = this.getScrollButton()
        if(this.scrollButton !== null) {
            this.initEvents()
        }
    }

    private initEvents(): void {
        this.scrollButton!.addEventListener('click', (ev) => {
            const scrollPoint = this.getScrollPoint()
            const pageOffsetY = window.pageYOffset
            window.scroll({ top: scrollPoint! + pageOffsetY - this.options.offset, left: 0, behavior: 'smooth' });
        }, false)
    }

    private getScrollButton(): HTMLElement | null {
        return this.root.querySelector(TextAnchor.Selectors.scrollButton)
    }

    private getScrollPoint(): number | undefined {
        if(!(this.root instanceof HTMLElement)) {
            return undefined
        }
        const elementBoundaries = this.root.getBoundingClientRect()
        return elementBoundaries.top + elementBoundaries.height
    }
}

onDOMContentLoaded(() => {
    document.querySelectorAll(TextAnchor.Selectors.root).forEach((element: Element) => {
        if(!(element instanceof HTMLElement)) {
            return
        }
        new TextAnchor(element)
    });
});