import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import axios from "axios";

export interface LicenseAssignmentElements {
    licenseAssignmentWrapperElement: HTMLElement | null;
    selectedAccountsLabelElement: HTMLElement | null;
    selectedAccountsBtnLabelElement: HTMLElement | null;
    subAccountsSearchInputElement: HTMLElement | null;
    searchResultsWrapperElement: HTMLElement | null;
    submitElement: HTMLElement | null;
    assignLaterButtonElement: HTMLElement | null;
    selectedAccountsSelectElements: NodeListOf<HTMLElement> | null;
    dropdownMenu: HTMLElement | null;
    dropdownBtn: HTMLElement | null;
    dropdownCheck: HTMLInputElement | null;
    assignLaterCheck: HTMLInputElement | null;
    submitFormBtn: HTMLFormElement | null;
}

export interface LicenseAssignmentValues {
    licenseQuantity: number | null;
    licenseProductId: number | null;
    saveDataAction: string | null;
}

interface SubAccount {
    id: number,
    name: string
}

class LicenseAssignment {
    private readonly elements : LicenseAssignmentElements;
    private readonly values : LicenseAssignmentValues;
    private typingTimeout: any
    private typingDelay: number = 300
    private rootUrl: string = [window.location.protocol, '//', window.location.host].join('');

    public static readonly Selectors = {
        selectedAccountsLabelElement: '.dws__dropdown__label',
        selectedAccountsBtnLabelElement: '.dws__dropdown__btn-label',
        subAccountsSearchInputElement: 'input.dws__m29-license-assignment__search',
        searchResultsWrapperElement: '.dws__m29-license-assignment__results',
        submitElement: '.dws__m29-license-assignment__box-submit',
        assignLaterButtonElement: '.dws__m29-license-assignment__box-cancel',
        selectedAccountsSelectElements: '.dws__m29-license-assignment__results input',
        dropdownMenu: '.dws__dropdown__menu',
        dropdownBtn: '.dws__dropdown__btn',
        dropdownCheck: '.dws__dropdown > input[type="checkbox"]',
        assignLaterCheck: '.dws__m29-article-overview__license-assignment__assign-later',
        submitFormBtn: '.dws__m27-sum-box__wrapper a.as__btn'
    };

    public static readonly Attributes = {
        quantity: 'data-license-quantity',
        productId: 'data-license-product-id',
        subAccountId: 'data-sub-account-id',
        customerFullname: 'data-customer-fullname',
        submitText: 'data-license-submit-text',
        submitAllText: 'data-license-submit-text-all',
        cancelText: 'data-license-cancel-text',
        cancelAllText: 'data-license-cancel-text-all',
        saveAssignmentsAction: 'data-save-data-action'
    }

    public constructor(element: HTMLElement) {
        this.elements = {
            licenseAssignmentWrapperElement: element,
            selectedAccountsLabelElement: element.querySelector(LicenseAssignment.Selectors.selectedAccountsLabelElement),
            selectedAccountsBtnLabelElement: element.querySelector(LicenseAssignment.Selectors.selectedAccountsBtnLabelElement),
            subAccountsSearchInputElement: element.querySelector(LicenseAssignment.Selectors.subAccountsSearchInputElement),
            searchResultsWrapperElement: element.querySelector(LicenseAssignment.Selectors.searchResultsWrapperElement),
            submitElement: element.querySelector(LicenseAssignment.Selectors.submitElement),
            assignLaterButtonElement: element.querySelector(LicenseAssignment.Selectors.assignLaterButtonElement),
            selectedAccountsSelectElements: element.querySelectorAll(LicenseAssignment.Selectors.selectedAccountsSelectElements),
            dropdownMenu: element.querySelector(LicenseAssignment.Selectors.dropdownMenu),
            dropdownBtn: element.querySelector(LicenseAssignment.Selectors.dropdownMenu),
            dropdownCheck: element.querySelector(LicenseAssignment.Selectors.dropdownCheck),
            assignLaterCheck: element.querySelector(LicenseAssignment.Selectors.assignLaterCheck),
            submitFormBtn: document.querySelector(LicenseAssignment.Selectors.submitFormBtn)
        }

        this.values = {
            licenseQuantity: Number(element.getAttribute(LicenseAssignment.Attributes.quantity)) ?? null,
            licenseProductId: Number(element.getAttribute(LicenseAssignment.Attributes.productId)) ?? null,
            saveDataAction: String(element.getAttribute(LicenseAssignment.Attributes.saveAssignmentsAction)) ?? null,
        }

        if (!this.values.saveDataAction) throw new Error('1714655544 M90bAssignLicense JS – No save data action found');

        this.init();
    }

    private init() {
        if (!this.elements.licenseAssignmentWrapperElement
            || !this.values.licenseQuantity
            || !this.values.licenseProductId
            || this.values.licenseQuantity === 0
            || this.values.licenseProductId === 0
            || !this.elements.selectedAccountsLabelElement
            || !this.elements.selectedAccountsBtnLabelElement
            || !this.elements.subAccountsSearchInputElement
            || !this.elements.searchResultsWrapperElement
            || !this.elements.submitElement
            || !this.elements.assignLaterButtonElement
            || !this.elements.dropdownMenu
            || !this.elements.dropdownBtn
            || !this.elements.dropdownCheck
            || !this.elements.assignLaterCheck
            || !this.elements.submitFormBtn
        ) {
            throw new Error('1699540066 Licenses Assignment: Could not initialize');
        }

        this.elements.assignLaterButtonElement.addEventListener('click', this.onCancelAssignment.bind(this));
        this.elements.subAccountsSearchInputElement.addEventListener('keydown', () => { clearTimeout(this.typingTimeout) });
        this.elements.subAccountsSearchInputElement.addEventListener('keyup', this.onSearchInputKeyUp.bind(this));

        document.addEventListener('click', event => {
            if (!(event.target instanceof HTMLElement)) return;
            if (!this.elements.licenseAssignmentWrapperElement?.contains(event.target)) {
                this.onAssignmentBoxHide();
            }
        });

        this.onSelectSubAccount();
        this.initSelectElementsEvents();
        this.updateSubmitAndCancelButtons();
        this.initFormEvent();
    }

    private saveAssignments(): void {
        const selectedSubAccounts = this.getSelectedSubAccounts();

        axios
            .post(this.rootUrl + this.values.saveDataAction,
                JSON.stringify({selectedSubAccounts: selectedSubAccounts, productId: this.values.licenseProductId}),
                {headers: {"Content-Type": "application/json"}}
            )
            .then(response => {
                if (response.status !== 200 || !response.data || !response.data.success) {
                    throw new Error('1699540068 Licenses Assignment: Could not save');
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.error('Error Response Status:', error.response.status);
                    console.error('Error Response Data:', error.response.data);
                    console.error('Error Response Headers:', error.response.headers);
                } else if (error.request) {
                    console.error('Error Request:', error.request);
                } else {
                    console.error('Error Message:', error.message);
                }
                console.error('Error Config:', error.config);
            });
    }

    private onAssignmentBoxHide(): void {
        this.elements.dropdownBtn!.setAttribute('aria-expanded', 'false')
        this.elements.dropdownMenu!.setAttribute('aria-hidden', 'true')
        this.elements.dropdownCheck!.checked = false;
    }

    private onCancelAssignment(): void {
        if (this.getSelectedSubAccounts().length > 0) {
            if (!this.elements.selectedAccountsSelectElements) return;
            let updated = false;

            this.elements.selectedAccountsSelectElements.forEach(selectElement => {
                if (selectElement instanceof HTMLInputElement && selectElement.checked) {
                    selectElement.checked = false;
                    updated = true;
                }
            })
        }

        if (this.elements.assignLaterCheck instanceof HTMLInputElement) {
            this.elements.assignLaterCheck.checked = true;
        }

        this.elements.submitElement?.click();
    }

    private onSearchInputKeyUp(event: Event): void {
        clearTimeout(this.typingTimeout);

        this.typingTimeout = setTimeout(() => {
            if (!event || !(event.target instanceof HTMLInputElement)) {
                throw new Error('1699540067 SubAccountsSearch: Could not find input element');
            }

            this.findSubAccountsByName(event.target.value ??'');
        }, this.typingDelay);
    }

    private async findSubAccountsByName(name: string): Promise<void> {
        const baseUrl = window.location.origin + '/account/find-subaccounts-by-name';

        let requestOptions = {
            url: baseUrl + '?name=' + name,
            config: { responseType: 'json' },
        };

        await axios(requestOptions)
            .then((response) => {
                if (response.status === 200) {
                    return response.data as SubAccount[];
                }
            })
            .then(subAccounts => {
                this.renderSubAccounts(subAccounts ?? []);
            })
            .catch(e => {
                if (this.elements.searchResultsWrapperElement) {
                    this.elements.searchResultsWrapperElement.innerHTML = '';
                }
            });
    }

    private renderSubAccounts(subAccounts: SubAccount[]): void {
        const selectedSubAccounts = this.getSelectedSubAccounts();

        if (this.elements.searchResultsWrapperElement) {
            this.elements.searchResultsWrapperElement.innerHTML = '';
        }

        subAccounts.forEach((subAccount, index) => {
            const wrapperDiv = document.createElement('div');
            const inputId = 'assignment-' + this.values.licenseProductId + '-' + subAccount.id;
            const inputElement = document.createElement('input');
            const nameText = document.createElement('label');
            let inputType = 'checkbox';

            if (this.values.licenseQuantity && this.values.licenseQuantity > 1) {
                inputElement.setAttribute('type', 'checkbox');
            } else {
                inputElement.setAttribute('type', 'radio');
                inputType = 'radio';
            }

            nameText.innerText = subAccount.name;
            nameText.setAttribute('for', inputId);
            inputElement.setAttribute(LicenseAssignment.Attributes.subAccountId, String(subAccount.id));
            inputElement.setAttribute(LicenseAssignment.Attributes.customerFullname, String(subAccount.name));
            inputElement.setAttribute('name', 'assignments[' + this.values.licenseProductId + '][]');
            inputElement.setAttribute('value', String(subAccount.id));
            inputElement.setAttribute('id', inputId);
            inputElement.classList.add('as__mr-2');

            if (inputType === 'checkbox') {
                wrapperDiv.classList.add('as__checkbox');
                nameText.classList.add('as__label');
                nameText.classList.add('as__checkbox__label');
                nameText.classList.add('as__checkbox-icon');
                inputElement.classList.add('as__checkbox__control');
            }

            wrapperDiv.appendChild(inputElement);
            wrapperDiv.appendChild(nameText);

            if (index === 0) {
                nameText.style.fontWeight = 'bold';
            }

            this.elements.searchResultsWrapperElement?.appendChild(wrapperDiv);

            const foundEntry = selectedSubAccounts.find(selectedSubAccount => {
                return Number(selectedSubAccount.id) === Number(subAccount.id);
            })

            if (foundEntry) {
                inputElement.checked = true;
            }
        });

        this.initSelectElements();
        this.initSelectElementsEvents();
        this.initFormEvent();
    }

    private getSelectedSubAccounts(): SubAccount[] {
        const selectedSubAccounts: SubAccount[] = [];
        if (!this.elements.selectedAccountsSelectElements) return selectedSubAccounts;

        this.elements.selectedAccountsSelectElements.forEach(selectElement => {
            if (selectElement instanceof HTMLInputElement && selectElement.checked) {
                selectedSubAccounts.push({
                    id: Number(selectElement.value),
                    name: selectElement.getAttribute(LicenseAssignment.Attributes.customerFullname) || ""
                });
            }
        })

        return selectedSubAccounts;
    }

    private initSelectElements(): void {
        if (!this.elements.licenseAssignmentWrapperElement) return;

        this.elements.selectedAccountsSelectElements = this.elements.licenseAssignmentWrapperElement.querySelectorAll(
            LicenseAssignment.Selectors.selectedAccountsSelectElements
        )
    }

    private initSelectElementsEvents(): void {
        if (!this.elements.selectedAccountsSelectElements) return;

        this.elements.selectedAccountsSelectElements.forEach(selectElement => {
            selectElement.removeEventListener('change', this.onSelectSubAccount.bind(this));
            selectElement.addEventListener('change', this.onSelectSubAccount.bind(this));
        });
    }

    private initFormEvent(): void {
        if (!this.elements.submitFormBtn) return;

        this.elements.submitFormBtn.removeEventListener('click', this.onFormSubmit.bind(this));
        this.elements.submitFormBtn.addEventListener('click', this.onFormSubmit.bind(this));
    }

    private onFormSubmit(e: MouseEvent): void {
        if (!this.elements.submitFormBtn) return;

        if ((this.getSelectedSubAccounts().length === 0)
            && !this.elements.assignLaterCheck?.checked
            && this.elements.selectedAccountsLabelElement) {
            e.preventDefault();
            this.elements.selectedAccountsLabelElement.classList.add('error');
        }

    }

    private onSelectSubAccount(): void {
        this.initSelectElements();

        let selectedAccountsCount = 0;
        let selectedAccountName = '';

        if (!this.values.licenseQuantity) return;
        if (!this.elements.selectedAccountsSelectElements) return;

        this.elements.selectedAccountsSelectElements.forEach(selectElement => {
            if (selectElement instanceof HTMLInputElement && selectElement.checked) {
                selectedAccountsCount++;
                selectedAccountName = selectElement.getAttribute(LicenseAssignment.Attributes.customerFullname) ?? '';
            }
        });

        let disabled = false;
        if (selectedAccountsCount >= this.values.licenseQuantity) {
            disabled = true;
        }

        if (this.elements.selectedAccountsBtnLabelElement) {
            if (selectedAccountsCount > 1) {
                this.elements.selectedAccountsBtnLabelElement.innerText = selectedAccountsCount + ' Unterkonten ausgewählt';
            } else if (selectedAccountName.length) {
                this.elements.selectedAccountsBtnLabelElement.innerText = selectedAccountName;
            }
        }

        if (this.elements.assignLaterCheck && ("checked" in this.elements.assignLaterCheck) && (selectedAccountsCount > 0)) {
            this.elements.assignLaterCheck.checked = false;
        }

        this.updateSelectElementsStatus(disabled);
        this.updateSubmitAndCancelButtons();
        this.saveAssignments();
    }

    private updateSelectElementsStatus(disabled: boolean = false): void {
        if (!this.elements.selectedAccountsSelectElements) return;
        if (this.values.licenseQuantity && this.values.licenseQuantity === 1) return;

        this.elements.selectedAccountsSelectElements.forEach(selectElement => {
            if (selectElement instanceof HTMLInputElement) {
                if (!disabled) {
                    selectElement.disabled = false;
                }

                if (disabled && !selectElement.checked) {
                    selectElement.disabled = true;
                }
            }
        });
    }

    private updateSubmitAndCancelButtons(): void {
        const selectedSubAccounts = this.getSelectedSubAccounts();

        this.elements.submitElement?.classList.add('as__hide');
        let submitText = String(this.elements.submitElement?.getAttribute(LicenseAssignment.Attributes.submitText)) ?? null;
        let cancelText = String(this.elements.assignLaterButtonElement?.getAttribute(LicenseAssignment.Attributes.cancelText)) ?? null;

        if (selectedSubAccounts.length === 1) {
            this.elements.submitElement?.classList.remove('as__hide');
        }

        if (selectedSubAccounts.length > 1) {
            this.elements.submitElement?.classList.remove('as__hide');
            submitText = String(this.elements.submitElement?.getAttribute(LicenseAssignment.Attributes.submitAllText)) ?? null
            cancelText = String(this.elements.assignLaterButtonElement?.getAttribute(LicenseAssignment.Attributes.cancelAllText)) ?? null
        }

        if (this.elements.submitElement && submitText) {
            this.elements.submitElement.innerText = submitText;
        }

        if (this.elements.assignLaterButtonElement && cancelText) {
            this.elements.assignLaterButtonElement.innerText = cancelText;
        }
    }
}

onDOMContentLoaded(() => {
    document.querySelectorAll('.dws__m29-article-overview__license-assignment').forEach((element) => {
        if (element instanceof HTMLElement) {
            new LicenseAssignment(element);
        }
    });
});