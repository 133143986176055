
export interface DOMContentLoadedListener {
    (): void;
}

const listeners : Array<DOMContentLoadedListener> = [];
let isDomContentLoaded = document.readyState !== 'loading';

export default function onDOMContentLoaded(listener : DOMContentLoadedListener) {
    if(isDomContentLoaded) {
        listener();
        return;
    }

    listeners.push(listener);
}

document.addEventListener('DOMContentLoaded', () => {
    isDomContentLoaded = true;
    listeners.forEach((listener) => listener());
});