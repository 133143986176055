let passiveEvent : boolean | AddEventListenerOptions = false;
let supportsPassive = false;

try {
    const opts = Object.defineProperty({}, 'passive', {
        get: function() {
            return (supportsPassive = true);
        }
    })

    window.addEventListener('x', () => {}, opts);
} catch(e) {}

if(supportsPassive) {
    passiveEvent = {passive: true};
}

export default passiveEvent;
