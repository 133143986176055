import FormValidation from "../formvalidation/formvalidation";

export default class FormMultistep {
    public static readonly Selectors = {
        Form: '.as__form-multistep',
        Step: '.as__form-multistep__step',
        NextTrigger: '.as__form-multistep__next',
        PrevTrigger: '.as__form-multistep__prev'
    };

    public static readonly States = {
        StepVisible: 'as__form-multistep__step--show'
    };

    private activeStep: number;
    private readonly stepCount: number;
    private readonly elements: { form: Element, steps: NodeListOf<HTMLElement> | null };
    private readonly events: { validate: Event };
    /**
     * hack to unbind event handler function
     * @see https://medium.com/@bigcatplichta/javascript-use-bind-to-dynamically-add-and-remove-event-listeners-d6b443877a73
     */
    private boundGoNextEventHandler: any;

    constructor(element: Element) {
        this.elements = {
            form: element,
            steps: element.querySelectorAll(FormMultistep.Selectors.Step)
        };

        this.events = {
            validate: document.createEvent('Event'),
        }
        this.events.validate.initEvent('validate', true, true);

        this.activeStep = -1;
        this.stepCount = -1;

        if (!(this.elements.form instanceof HTMLFormElement)) {
            return;
        }
        if (!(this.elements.steps instanceof NodeList)) {
            return;
        }

        this.elements.steps.forEach((step) => {
            if (step.classList.contains(FormMultistep.States.StepVisible)) {
                this.activeStep = Array.prototype.indexOf.call(this.elements.steps, step) + 1;
            }
        });

        this.stepCount = this.elements.steps.length;
        this.boundGoNextEventHandler = this.goNext.bind(this);

        this.init();
    };

    /**
     * Wrapper function for class initialization related functions
     * @private
     */
    private init() {
        if (this.activeStep === -1) {
            this.go(1);
        }
        this.registerStepEventHandler();
    }

    /**
     * Register step specific event handling
     * @private
     */
    private registerStepEventHandler() {
        if (!(this.elements.steps instanceof NodeList)) {
            return;
        }

        this.elements.steps.forEach((step) => {
            let
                nextTriggers = step.querySelectorAll(FormMultistep.Selectors.NextTrigger),
                prevTriggers = step.querySelectorAll(FormMultistep.Selectors.PrevTrigger);

            if (nextTriggers instanceof NodeList) {
                nextTriggers.forEach((trigger) => {
                    trigger.addEventListener('click', () => {
                        if (this.elements.form.classList.contains(FormValidation.Selectors.Form.replace(/^\./, ""))) {
                            this.elements.form.addEventListener('valid', this.boundGoNextEventHandler);
                            this.elements.form.dispatchEvent(this.events.validate);
                        } else {
                            this.goNext();
                        }
                    })
                });
            }

            if (prevTriggers instanceof NodeList) {
                prevTriggers.forEach((trigger) => {
                    trigger.addEventListener('click', () => {
                        this.goPrev();
                    })
                });
            }
        });
    }

    /**
     * Activate step by index
     * @param index
     * @private
     */
    private go(index: number) {
        this.elements.form.removeEventListener('valid', this.boundGoNextEventHandler);

        if ((index <= 0) || (index > this.stepCount)) {
            return;
        }

        if (!(this.elements.steps instanceof NodeList)) {
            return;
        }

        this.elements.steps.forEach((step) => {
            if (Array.prototype.indexOf.call(this.elements.steps, step) === index - 1) {
                step.classList.add(FormMultistep.States.StepVisible);
                this.activeStep = index;
            } else {
                step.classList.remove(FormMultistep.States.StepVisible);
            }
        });
    }

    /**
     * Go to next step
     * @private
     */
    private goNext() {
        this.go(this.activeStep + 1);
    }

    /**
     * Go to previous step
     * @private
     */
    private goPrev() {
        this.go(this.activeStep - 1);
    }
}

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll(FormMultistep.Selectors.Form).forEach((element) => {
        new FormMultistep(element);
    });
});
