import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

interface PersonalDataSelectors {
    paymentMethod: string
}

interface PersonalDataDOM {
    root: HTMLElement,
    paymentMethod: HTMLSelectElement | null
}

export default class PersonalData {
    protected readonly dom: PersonalDataDOM
    protected readonly selectors: PersonalDataSelectors

    public constructor(element: HTMLElement) {
        this.dom = {
            root: element,
            paymentMethod: null
        }
        this.selectors = {
            paymentMethod: '.m74-personal-data__payment-method'
        }
        this.dom.paymentMethod = element.querySelector(this.selectors.paymentMethod)

        this.init()
    }

    protected init() {
        if(this.dom.paymentMethod && this.dom.paymentMethod.form) {
            this.dom.paymentMethod.addEventListener('change', () => {
                this.dom.paymentMethod?.form?.submit();
            })
        }
    }
}

onDOMContentLoaded(() => {
    const personalDate = Array.prototype.slice.call(document.querySelectorAll('.dws__m74-personal-data'));
    personalDate.forEach((element) => {
        new PersonalData(element);
    })
})