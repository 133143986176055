// region IMPORTS
// base utilities
// endregion

// region INTERFACES
export interface RegistrationFormElements {
    container: HTMLElement | null,
    deliveryAddress: HTMLElement | null,
    deliveryAddressCheckbox: HTMLElement | null,
}
// endregion INTERFACES

export default class RegistrationForm {
    private static readonly States = {
        Hidden: 'as__hide'
    };

    public static readonly Selectors = {
        Container: '.dws__m32-registration-form',
        DeliveryAddress: '.dws__m32-registration-form__delivery-address',
        DeliveryAddressCheckbox: '.dws__m32-registration-form__delivery-address-checkbox'
    };

    public elements : RegistrationFormElements;

    constructor(element : HTMLElement | null) {
        if (!(element instanceof HTMLElement)) {
            throw new Error('could not find registration form container element');
        }

        this.elements = {
            container: element,
            deliveryAddress: element.querySelector(RegistrationForm.Selectors.DeliveryAddress),
            deliveryAddressCheckbox: element.querySelector(RegistrationForm.Selectors.DeliveryAddressCheckbox)
        }

        if (!(this.elements.deliveryAddress instanceof HTMLElement)) {
            throw new Error('could not find delivery address element');
        }

        if (!(this.elements.deliveryAddressCheckbox instanceof HTMLElement)) {
            throw new Error('could not find delivery address checkbox element');
        }

        this.elements.deliveryAddressCheckbox.addEventListener('change', () => this.showDeliveryAddress(), false)
        window.addEventListener('load', () => this.showDeliveryAddress(), false)
    }

    private showDeliveryAddress() : void {
        let value = !(this.elements.deliveryAddressCheckbox as HTMLInputElement).checked;
        this.elements.deliveryAddress?.classList.toggle(RegistrationForm.States.Hidden, value);
    }
}

document.addEventListener('DOMContentLoaded', () => {
    let registrationFormContainers = document.querySelectorAll(RegistrationForm.Selectors.Container);

    registrationFormContainers.forEach(ContainerElement => {
        if (!(ContainerElement instanceof HTMLElement)) {
            throw new Error('could not find delivery address element');
        }

        new RegistrationForm(ContainerElement);
    });
}, false);