export type DeepPartial<T> = {
    [P in keyof T]?: T[P] extends Array<infer U>
    ? Array<DeepPartial<U>>
    : T[P] extends ReadonlyArray<infer U>
    ? ReadonlyArray<DeepPartial<U>>
    : DeepPartial<T[P]>
};

export default function assignOptions<O extends object>(partialOptions : DeepPartial<O>, defaultOptions : O) : O {
    const options = {} as O;
    partialOptions = partialOptions || {};

    for(let key in defaultOptions) {
        if(!defaultOptions.hasOwnProperty(key)) {
            continue;
        }

        if(key in partialOptions) {
            if(typeof defaultOptions[key] === 'object' && typeof partialOptions[key] === 'object') {
                //@ts-ignore
                options[key] = assignOptions(partialOptions[key], defaultOptions[key]);
            } else if(typeof partialOptions[key] === 'object') {
                //@ts-ignore
                options[key] = assignOptions(partialOptions[key], {});
            } else {
                //@ts-ignore
                options[key] = partialOptions[key];
            }
        } else if(typeof defaultOptions[key] === 'object') {
            //@ts-ignore
            options[key] = assignOptions({}, defaultOptions[key]);
        } else {
            options[key] = defaultOptions[key];
        }
    }

    return options;
}
