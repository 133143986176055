// region IMPORTS
// base utilities
import animateHeightTransition from "../../base/util/dom/animateHeightTransition";
// components
import BaseAccordion, {
    BaseAccordionItem,
    BaseAccordionUpdateItemEvent
} from "../../components/base-accordion/base-accordion";
// endregion IMPORTS

// region INTERFACES
export interface HeaderNavOptions {
    selectors: {
        toggle: string,
        content: string,
        label: string,
        container?: string
    }
}
// endregion INTERFACES

export class HeaderNav {
    private readonly options : HeaderNavOptions;
    private readonly accordion : BaseAccordion;
    private readonly children : Map<BaseAccordionItem, HeaderNav>;

    constructor(element : HTMLElement, options : HeaderNavOptions) {
        this.options = options;

        this.accordion = new BaseAccordion(element, {
            selectors: {
                toggle: this.options.selectors.toggle
            },
            itemFactory: this.navItemFactory.bind(this, element)
        });

        this.accordion.addEventListener('updateItem', this.onNavUpdateItem.bind(this));

        this.children = new Map();

        this.accordion.getItems().forEach(item => {
            this.children.set(item, new HeaderNav(item.contents[0], this.options));
        });
    }

    getElement() : HTMLElement {
        return this.accordion.getContainer();
    }

    public close() {
        this.children.forEach((child) => child.close());
        this.accordion.closeAll();
    }

    private onNavUpdateItem(event : BaseAccordionUpdateItemEvent) : void {
        event.item.contents.forEach((content) => {
            animateHeightTransition(content, event.item.open);
        });

        if(event.item.open) {
            return;
        }

        const childNav = this.children.get(event.item);

        if(!childNav) {
            return;
        }

        childNav.close();
    }

    private navItemFactory(wrapper : HTMLElement, toggle : HTMLInputElement) : BaseAccordionItem|null {
        const label = wrapper.querySelector(this.options.selectors.label.replace('%ID%', toggle.id));
        const content = wrapper.querySelector(this.options.selectors.content.replace('%ID%', toggle.id));

        if(!(label instanceof HTMLElement) || !(content instanceof HTMLElement)) {
            return null;
        }

        return {
            toggle: toggle,
            triggers: [label],
            contents: [content],
            open: toggle.checked
        };
    }
}
