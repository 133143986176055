import FormAjax from "../form-ajax/form-ajax";

/**
 * FormValidation class.
 * Adds custom form validation to <form class="as__form-validate"> elements.
 * Each input element requires the "required" attribute and a wrapper element with the class ".as__input-validate".
 * Custom error text messages need to be placed in the wrapper element with the "data-error-text" attribute.
 * Custom error text messages will be appended to the wrapper element.
 * Custom RegExp can be used for validation via "data-validate-regexp" attribute on the input element.
 * In order to disable HTML5 form validation, the "novalidate" attribute will be added to the form element.
 *
 * Usage:
 * <form class="as__form-validate">
 * ...
 * <div class="as__input-validate" data-error-text="Lorem ipsum dolor sit amet">
 *     <label class="as__label">Label*</label>
 *     <input type="text" placeholder="input" class="as__input" required data-validate-regexp="^[a-zA-Z | \s]+$"/>
 * </div>
 *
 * TODO: Validate non-required input fields based on value --> new ticket
 * TODO: Validate non-required input fields if they have custom RegExp --> new ticket
 */
export default class FormValidation {

    /**
     * Default fileTypes for file validation
     */
    public static readonly DefaultFileTypes = [
        "image/apng",
        "image/jpeg",
        "image/pjpeg",
        "image/png",
        "application/pdf",
        "application/postscript"
    ];

    /**
     * Selectors for form validation
     */
    public static readonly Selectors = {
        Form: '.as__form-validate',
        FormErrorWrappers: '.as__form__error-wrapper',
        InputWrappers: '.as__input-validate',
        Inputs: '.as__input, .as__checkbox__control, .as__select, .as__radio__control, .as__textarea, .as__file-input input',
        ErrorTextContainer: '.dws__info-error-container',
        ErrorText: '.as__info-error',
        ValidState: '.as__field-valid',
        ErrorState: '.as__field-error',
        Header: '.as__header'
    };

    /**
     * Attributes for form element
     */
    private static readonly Attributes = {
        NoValidate: 'novalidate',
        ErrorText: 'data-error-text',
        Required: 'required',
        CustomRegExp: 'data-validate-regexp',
    };

    /**
     * States for form element
     */
    private static readonly ErrorStates = 'as__field-error';
    private static readonly ValidStates = 'as__field-valid';

    /**
     * RegExp pattern for mail input element
     */
    private static readonly MailRegExp = /^\S+@\S+\.\S+$/;

    /**
     * Error message HTML template string and text placeholder
     */
    private static readonly ErrorText = (errorText : string) => `<span class="as__info-error">${errorText}</span>`;

    private readonly elements : {form: Element, inputWrappers: NodeListOf<HTMLElement>, formErrorWrappers: NodeListOf<HTMLElement>};
    private readonly events : {valid: Event};
    private isFormValidArr : Array<boolean>;

    constructor(element : Element) {
        this.elements = {
            form: element,
            inputWrappers: element.querySelectorAll(FormValidation.Selectors.InputWrappers),
            formErrorWrappers: element.querySelectorAll(FormValidation.Selectors.FormErrorWrappers)
        };

        this.events = {
            valid: document.createEvent('Event')
        }
        this.events.valid.initEvent('valid', true, true);

        this.isFormValidArr = [];

        this.init();
    }

    /**
     * Get actual input elements in array by wrapper element
     */
    private getInputsByWrapper(inputWrapper: HTMLElement) : NodeListOf<HTMLElement> {
        return inputWrapper.querySelectorAll(FormValidation.Selectors.Inputs);
    }

    /**
     * Get error text of input wrapper element by data-error-text attribute
     */
    private getErrorTextByWrapper(inputWrapper : HTMLElement) : string {
        let errorText = inputWrapper.getAttribute(FormValidation.Attributes.ErrorText),
            errorTextHML = '';

        if (errorText) {
            errorTextHML = FormValidation.ErrorText(errorText);
        }

        return errorTextHML;
    }

    /**
     * Wrapper function for class initialization related functions
     */
    public init() : void {
        // Disable browser form validation via 'novalidate' attribute
        this.elements.form.setAttribute(FormValidation.Attributes.NoValidate, FormValidation.Attributes.NoValidate);
        this.elements.form.addEventListener('submit', this.onSubmit.bind(this), false);
        this.elements.form.addEventListener('reset', this.onReset.bind(this), false);
        this.elements.form.addEventListener('validate', this.validateForm.bind(this), false);
    }

    /**
     * Test function for file input types
     *
     * @param file
     * @param fileTypes
     */
    private validFileType(file : any, fileTypes : string[] = FormValidation.DefaultFileTypes) {
        return fileTypes.indexOf(file.type) != -1;
    }

    /**
     * Wrapper function for form submit related functions
     */
    private onSubmit(event: Event) : void {
        let form = event.target;
        event.preventDefault();

        if (!(form instanceof HTMLFormElement)) {
            return;
        }

        this.validateForm();

        if (this.isFormValid()
            && !this.elements.form.classList.contains(FormAjax.Selectors.Form.replace(/^\./, ""))
        ) {
            form.submit();
        } else {
            this.elements.form.addEventListener('change', this.onChange.bind(this), true);
        }
    }

    /**
     * Function for reset the form to original state (remove data / error state from fields)
     *
     * @param event
     * @private
     */
    private onReset(event: Event) {

        const errorInfoMessage = Array.prototype.slice.call(document.querySelectorAll(FormValidation.Selectors.ErrorText));
        const fieldsWithValidattionStatus = Array.prototype.slice.call(document.querySelectorAll(`${FormValidation.Selectors.ErrorState},${FormValidation.Selectors.ValidState}`));

        if(errorInfoMessage.length > 0) {
            errorInfoMessage.forEach((element)=> {
                element.parentNode.removeChild(element);
            })
        }

        if(fieldsWithValidattionStatus.length > 0) {
            fieldsWithValidattionStatus.forEach((element)=> {
                element.classList.remove(FormValidation.ErrorStates);
                element.classList.remove(FormValidation.ValidStates);
            })
        }
    }

    /**
     * Function to remove error state from input on change
     */
    private onChange(event: Event) : void {
        event.preventDefault();
        let focusedInput = event.target;

        if (!(focusedInput instanceof HTMLElement)) {
            return;
        }

        if (!focusedInput.hasAttribute(FormValidation.Attributes.Required)) {
            return;
        }

        if (!focusedInput.classList.contains(FormValidation.ErrorStates)) {
            return;
        }

        let focusedInputWrapperElement = focusedInput.closest(FormValidation.Selectors.InputWrappers);
        let form = focusedInput.closest(FormValidation.Selectors.Form);

        if (!(focusedInputWrapperElement instanceof HTMLElement)) {
            return;
        }

        if (!(form instanceof HTMLFormElement)) {
            return;
        }

        this.toggleErrorStateOfInput(focusedInput, true);
        this.toggleValidStateOfInput(focusedInput, true);
        this.toggleErrorMsgOfWrapper(focusedInputWrapperElement, true);
    }

    /**
     * Validate form and submit it after successful validation
     */
    private validateForm() : void {
        let hasScrolled = false;

        this.isFormValidArr = [];

        this.elements.inputWrappers.forEach((inputWrapper) => {
            const inputs = this.getInputsByWrapper(inputWrapper);

            inputs.forEach((input) => {
                if (!input.hasAttribute(FormValidation.Attributes.Required)) {
                    return;
                }

                // element is not visible
                if(!FormValidation.isElementVisible(input)) {
                    return;
                }

                let isInputValid = this.validateInput(input, inputWrapper);

                this.toggleErrorStateOfInput(input, isInputValid);
                this.toggleValidStateOfInput(input, isInputValid);
                this.toggleErrorMsgOfWrapper(inputWrapper, isInputValid);

                if (!hasScrolled && !isInputValid) {
                    hasScrolled = this.scrollToWrapper(inputWrapper);
                    input.focus();
                }
            });
        });

        this.toggleErrorMsgOfFormErrorWrappers(this.isFormValid());
    }

    /**
     * checks if an element is visible
     * @param element
     * @private
     */
    private static isElementVisible(element: HTMLElement): boolean {
        // accordion content
        if (element.closest('.as__accordion__content') !== null && element.closest('.as__accordion__content')?.clientHeight === 0) {
            return false;
        }
        // ckeditor
        else if (element.classList.contains('as__ckeditor')) {
            if (element.parentElement?.offsetParent === null) {
                return false;
            }
        }
        // default
        else if (element.offsetParent === null) {
            return false;
        }

        return true;
    }

    /**
     * Validate input element
     * Todo: Add type="number" validation for min, max, step
     */
    private validateInput(input: HTMLElement, inputWrapper: HTMLElement) : boolean {
        let isInputValid = false,
            isInput = input.nodeName.toUpperCase() === 'INPUT',
            isSelect = input.nodeName.toUpperCase() === 'SELECT',
            isTextarea = input.nodeName.toUpperCase() === 'TEXTAREA',
            inputType = input.getAttribute('type'),
            isMailInput = false,
            isCheckbox = false,
            isRadio = false,
            isFile = false,
            customPattern = input.getAttribute(FormValidation.Attributes.CustomRegExp);

        if (inputType) {
            isMailInput = isInput && inputType.toUpperCase() === 'EMAIL';
            isCheckbox = isInput && inputType.toUpperCase() === 'CHECKBOX';
            isRadio = isInput && inputType.toUpperCase() === 'RADIO';
            isFile = isInput && inputType.toUpperCase() === 'FILE';
        }

        if (isInput) {
            if (isMailInput) {
                isInputValid = this.validateInputMail(input, customPattern);
            } else if (isCheckbox) {
                isInputValid = this.validateInputCheckbox(input);
            } else if (isRadio) {
                isInputValid = this.validateInputRadio(inputWrapper);
            } else if (isFile) {
                isInputValid = this.validateInputFile(input);
            } else {
                isInputValid = this.validateInputText(input, customPattern);
            }
        } else if (isSelect) {
            isInputValid = this.validateInputText(input);
        } else if (isTextarea) {
            isInputValid = this.validateInputText(input, customPattern);
        }

        return isInputValid;
    }

    /**
     * Validate input mail element
     */
    private validateInputMail(input: HTMLElement, customPattern : string | null = null) : boolean {
        let isInputValid = false,
            pattern = customPattern || FormValidation.MailRegExp;

        pattern = new RegExp(pattern);

        if (input instanceof HTMLInputElement) {
            isInputValid = pattern.test(input.value);
        }

        this.isFormValidArr.push(isInputValid);
        return isInputValid;
    }


    /**
     * Validate input checkbox element
     */
    private validateInputCheckbox(input : HTMLElement) : boolean {
        let isInputValid = false;

        if (input instanceof HTMLInputElement) {
            isInputValid = input.checked;
        }

        this.isFormValidArr.push(isInputValid);
        return isInputValid;
    }

    /**
     * Validate input radio element
     */
    private validateInputRadio(inputWrapper : HTMLElement) : boolean {
        const inputRadioGroup = this.getInputsByWrapper(inputWrapper);
        let isInputValid = false;

        for (let i = 0; i < inputRadioGroup.length; i++) {
            const input = inputRadioGroup[i];

            if (!(input instanceof HTMLInputElement)) {
                continue;
            }

            if (input.checked) {
                isInputValid = true
                break;
            }
        }

        this.isFormValidArr.push(isInputValid);
        return isInputValid;
    }


    /**
     * Validate input element
     */
    private validateInputText(input: HTMLElement, customPattern : string | null = null) : boolean {
        let isInputValid = false,
            pattern = customPattern || false;

        if (
            input instanceof HTMLInputElement ||
            input instanceof HTMLSelectElement ||
            input instanceof HTMLTextAreaElement
        ) {
            isInputValid = Boolean(input.value.trim());

            if (pattern) {
                isInputValid = new RegExp(pattern).test(input.value);
            }
        }

        this.isFormValidArr.push(isInputValid);
        return isInputValid;
    }

    /**
     * Validate input element
     */
    private validateInputFile(input: HTMLElement) : boolean {
        let isInputValid = false;

        if(input instanceof HTMLInputElement) {
            const curFiles : FileList|null = input.files;

            if(curFiles instanceof FileList) {
                for (let file of Array.prototype.slice.call(curFiles)) {
                    if(!this.validFileType(file)) {
                        isInputValid = false;
                        break;
                    } else {
                        isInputValid = true;
                    }
                }
            }
        }

        this.isFormValidArr.push(isInputValid);
        return isInputValid;
    }

    /**
     * Toggle error state of input element
     */
    private toggleErrorStateOfInput(input : HTMLElement, isInputValid : boolean) : void {
        input.classList.toggle(FormValidation.ErrorStates, !isInputValid);
    }


    /**
     * Toggle valid state of input element
     */
    private toggleValidStateOfInput(input : HTMLElement, isInputValid : boolean) : void {
        input.classList.toggle(FormValidation.ValidStates, isInputValid);
    }


    /**
     * Toggle error message of input element
     */
    private toggleErrorMsgOfWrapper(inputWrapper : HTMLElement, isInputValid : boolean) : void {
        var errorText = inputWrapper.querySelector(FormValidation.Selectors.ErrorText),
            errorTextContainer = inputWrapper.querySelector(FormValidation.Selectors.ErrorTextContainer),
            errorTextString = this.getErrorTextByWrapper(inputWrapper);

        if (!isInputValid && !errorText) {
            if (errorTextContainer) {
                errorTextContainer.insertAdjacentHTML('beforeend', errorTextString);
            } else {
                inputWrapper.insertAdjacentHTML('beforeend', errorTextString);
            }
        }

        if (isInputValid && errorText && errorText.parentNode) {
           errorText.parentNode.removeChild(errorText);
        }
    }

    /**
     * Scroll view to wrapper element
     *
     */
    private scrollToWrapper(inputWrapper: HTMLElement) : boolean {
        // Use setTimeout to fix inconsistent scrollTo behaviour
        setTimeout(() => {
            inputWrapper.scrollIntoView()
        });

        return true;
    }

    /**
     * Check if form is valid by this.isFormValid array
     */
    private isFormValid() : boolean {
        for (var i = 0; i < this.isFormValidArr.length; i++) {
            if (!this.isFormValidArr[i]) {
                return false;
            }
        }
        this.elements.form.dispatchEvent(this.events.valid);

        return true;
    }

    /**
     * Toggle error message of error wrapper elements
     */
    private toggleErrorMsgOfFormErrorWrappers(isFormValid : boolean) : void {
        this.elements.formErrorWrappers.forEach((formErrorWrapper) => {
            formErrorWrapper.classList.toggle(FormValidation.ErrorStates, !isFormValid);
            this.toggleErrorMsgOfWrapper(formErrorWrapper, isFormValid)
        });
    };
}

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll(FormValidation.Selectors.Form).forEach((element) => {
        new FormValidation(element);
    });
});
