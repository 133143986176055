const xhr = new XMLHttpRequest();

const rootUrl = [window.location.protocol, '//', window.location.host].join('');

xhr.open('GET', rootUrl + '/static/icons/icons.svg', true);
xhr.addEventListener('load', () => {
    const div = document.createElement('div');

    div.className = 'as__icon__template';
    div.innerHTML = xhr.responseText;
    document.body.appendChild(div);
});
xhr.send();
