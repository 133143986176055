import onDOMContentLoaded from "./base/util/dom/onDOMContentLoaded";

// import main.less to compile less
import './main.less';

// polyfills
import 'core-js/features/object/assign';
import 'core-js/features/dom-collections';
// import 'scopedQuerySelectorShim/dist/scopedQuerySelectorShim';
import 'picturefill';
import objectFitImages = require('object-fit-images');

import 'formdata-polyfill'

onDOMContentLoaded(() => {
    objectFitImages(null);
})

import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();

// base
import './base/base';
import './base/icon/icon';
import './base/form/file';

// components
import './components/autosuggestion/autosuggestion';
import './components/base-accordion/base-accordion';
import './components/base-accordion/accordion';
import './components/base-popover/base-popover';
import './components/form-pw-toggle/form-pw-toggle';
import './components/formvalidation/formvalidation';
import './components/form-multistep/form-multistep';
import './components/form-ajax/form-ajax';
import './components/base-slider/base-slider';
import './components/modal/modal';
import './components/video/video';
import './components/dropdown/dropdown';
import './components/carousel/carousel';
import './components/product-carousel-tile/product-carousel-tile';
import './components/ckeditor/ckeditor';
import './components/loading/loading';
import './components/loading/example';
import './components/search/search';
import './components/shariff/shariff';
import './components/cookiebot/cookiebot';
import './components/tracking-manager/tracking-manager';

// modules
import './modules/m87-flash-message/flash-message.ts';
import './modules/sr1-header/header';
import './modules/sr3-footer/footer-slider';
import './modules/sr3a-top-button/top-button';
import './modules/m2a-topics-teaser/topic-teaser';
import './modules/m27-sum-box/sum-box-sticky';
import './modules/m45-search-result/search-results';
import './modules/m32-registration-form/registration-form';
import './modules/m36-payment-data-overview/payment-data-overview';
import './modules/sr1a-search-header/search-header';
import './modules/m1-hero/hero';
import './modules/m37-faq/faq';
import './modules/m26-filter-tabs/filter-tabs';
import './modules/m3-product-teaser-slider/product-teaser-slider';
import './modules/m6-carousel-cross-selling/carousel-cross-selling';
import './modules/m81-text-anchor/text-anchor';
import './modules/m29c-watchlist-cart/watchlist-cart';
import './modules/m61-leaflet-db-search/leaflet-db-search.ts';
import './modules/m63-detail-factsheet/detail-factsheet';
import './modules/m63a-favourite-list-factsheet/favourite-list-factsheet.ts';
import './modules/m63b-list-factsheet/list-factsheet.ts';
import './modules/m88-factsheet-toolbar/factsheet-toolbar';
import './modules/m89-content-table/content-table';
import './modules/m90a-add-subaccount/add-subaccount';
import './modules/m90b-assign-license/assign-license';
import './modules/m91-generate-invitation-link/generate-invitation-link';
import './modules/m74-personal-data/personal-data';
import './modules/m17-filter/filter-form'
import './modules/m85-learn-control-quiz/learn-control-quiz';
import './modules/m24-search/searchbar.ts';
import './modules/m14-product-card/product-card-lazy.ts';
import './modules/m29-article-overview/article-overview-license-assignment.ts';
import './modules/m29-article-overview/article-overview.ts';

// sites
import './sites/law/law';
import './sites/pds/pds';
