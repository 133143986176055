import axios, {AxiosResponse} from "axios";
import FormValidation from "../formvalidation/formvalidation";
import initCkeditor from "../ckeditor/ckeditor";
import initFileInputs from "../../base/form/file";
import FormMultistep from "../form-multistep/form-multistep";
import Accordion from "../base-accordion/accordion";
import Loading from "../loading/loading";
import EventTarget, {createCustomEvent} from "../../base/util/dom/EventTarget";
import FlashMessage from '../../components/flash-message/flash-message';

type CallbackFunction = (response: AxiosResponse) => void;

export default class FormAjax extends EventTarget {
    public static readonly Selectors = {
        Form: '.as__form-ajax',
        SubmitBtn: '[type="submit"]',
        ResponseContent: '.as__form-ajax__response-content',
        ShowForm: '.as__form-ajax__show-form'
    };

    public static readonly States = {
        Hide: 'as__hide',
        ResponseContent: 'as__form-ajax__response-content'
    };

    private readonly elements: { form: HTMLFormElement, submitBtn: HTMLButtonElement | null, showFormTrigger: NodeListOf<Element> | undefined, responseContent: Element | null };
    private readonly properties: { form: { url: string, callbacks: string } };
    private readonly events: { validate: Event, success: Event, error: Event  };
    private disabled: boolean;

    constructor(element: HTMLFormElement) {
        super();

        this.elements = {
            form: element,
            submitBtn: element.querySelector(FormAjax.Selectors.SubmitBtn),
            showFormTrigger: element.parentElement?.querySelectorAll(FormAjax.Selectors.ShowForm),
            responseContent: null
        }
        this.properties = {
            form: {
                url: element.dataset.ajaxUrl ?? element.action ?? '',
                callbacks: element.dataset.ajaxCallback ?? 'insertAfter|hideForm'
            }
        }
        this.events = {
            validate: document.createEvent('Event'),
            success: document.createEvent('Event'),
            error: document.createEvent('Event')
        }
        this.events.validate.initEvent('validate', true, true);
        this.events.success.initEvent('success', true, true);
        this.events.error.initEvent('error', true, true);
        this.disabled = false;

        //binding for event handler
        this.submitForm = this.submitForm.bind(this);
        this.showForm = this.showForm.bind(this);

        if (!(element instanceof HTMLFormElement)) {
            return;
        }
        if (!this.elements.submitBtn) {
            return;
        }
        if (this.properties.form.url === '') {
            return;
        }

        this.init();

        if (!(window.AS.FlashMessage instanceof FlashMessage)) {
            return
        }

        this.addEventListener('showFlashMessage', event => {
            window.AS.FlashMessage.insertFlashMessage(event.message, event.messageType)
        })
    }

    /**
     * Wrapper function for class initialization related functions
     * @private
     */
    private init() {
        this.registerEventHandler();
    }

    private registerEventHandler() {
        this.elements.form.addEventListener('submit', (event) => {
            if (!this.disabled) {
                if (this.elements.form.classList.contains(FormValidation.Selectors.Form.replace(/^\./, ""))) {
                    this.elements.form.addEventListener('valid', this.submitForm);
                    this.elements.form.dispatchEvent(this.events.validate);
                } else {
                    this.submitForm();
                }
            }

            return event.preventDefault();
        });

        if(this.elements.showFormTrigger) {
            this.elements.showFormTrigger.forEach((item) => {
                item.addEventListener('click', this.showForm);
            });
        }
    }

    private submitForm() {
        this.elements.form.removeEventListener('valid', this.submitForm);

        const formData = new FormData(this.elements.form);
        this.disableFormSubmit();

        let loading = new Loading(this.elements.form, 'triple');
        axios.post(this.properties.form.url, formData, {
            headers: {"Content-Type": "multipart/form-data"}
        })
            .then((response) => {
                if (response.status === 200) {
                    this.properties.form.callbacks.split('|').forEach((callback) => {
                        if (typeof this[callback as keyof FormAjax] === 'function') {
                            const func: any = this[callback as keyof FormAjax];
                            func(response);
                        }
                    })
                    this.enableFormSubmit();
                    this.elements.form.dispatchEvent(this.events.success);
                } else {
                    this.handleAjaxError(response.data.message);
                }
            })
            .catch((error) => {
                this.handleAjaxError(error.message);
            })
            .then(function () {
                loading.destroy();
            });
    }

    private disableFormSubmit() {
        if (this.elements.submitBtn) {
            this.elements.submitBtn.disabled = true;
        }
        this.disabled = true;
    };

    private enableFormSubmit() {
        if (this.elements.submitBtn) {
            this.elements.submitBtn.disabled = false;
        }
        this.disabled = false;
    };

    private removeResponseContent() {
        if (!this.elements.responseContent) {
            return;
        }

        this.elements.responseContent.parentElement?.removeChild(this.elements.responseContent);
    }

    private showForm() {
        this.elements.form.classList.remove(FormAjax.States.Hide);
        this.elements.responseContent?.classList.add(FormAjax.States.Hide);
    }

    private initEventsAfterDomManipulation() {
        if (!this.elements.responseContent) {
            return;
        }

        if (this.elements.responseContent === this.elements.form) {
            return;
        }

        //internal
        const showFormTrigger = this.elements.responseContent.querySelectorAll(FormAjax.Selectors.ShowForm);
        showFormTrigger.forEach((item) => {
            item.addEventListener('click', this.showForm);
        });

        //external
        initCkeditor(this.elements.responseContent);
        initFileInputs(this.elements.responseContent);
        this.elements.responseContent.querySelectorAll(FormValidation.Selectors.Form).forEach((element) => {
            new FormValidation(element);
        });
        this.elements.responseContent.querySelectorAll(FormAjax.Selectors.Form).forEach((element) => {
            const form = new FormAjax(element as HTMLFormElement);
            form.addEventListener('showFlashMessage', (event) => {
                this.dispatchEvent(createCustomEvent('showFlashMessage', event));
            })
        });
        this.elements.responseContent.querySelectorAll(FormMultistep.Selectors.Form).forEach((element) => {
            new FormMultistep(element);
        });
        this.elements.responseContent.querySelectorAll(Accordion.DefaultOptions.selectors.accordion).forEach((accordionElement) => {
            new Accordion(accordionElement as HTMLElement);
        });
    }

    private handleAjaxError(message: string) {
        this.dispatchEvent(createCustomEvent('showFlashMessage', {
            message: message,
            messageType: 'alert-error'
        }));

        this.elements.form.dispatchEvent(this.events.error);
    }

    /**
     * Callback functions after ajax request.
     * If the callback should be called, add the function name to the data-ajax-callback attribute of the form.
     */
    private hideForm: CallbackFunction = (response) => {
        this.elements.form.classList.add(FormAjax.States.Hide);
    }

    private insertAfter: CallbackFunction = (response) => {
        let div = document.createElement('div');

        this.removeResponseContent();

        div.classList.add(FormAjax.States.ResponseContent);
        div.innerHTML = response.data;
        this.elements.form.parentElement?.appendChild(div);
        this.elements.responseContent = div;

        this.initEventsAfterDomManipulation();
    }
}

document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll(FormAjax.Selectors.Form).forEach((element) => {
        new FormAjax(element as HTMLFormElement);
    });
});