import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import ProductCarouselPopup from "../../components/product-carousel-tile/product-carousel-tile"


onDOMContentLoaded(() => {
    document.querySelectorAll('.dws__m63b-list-factsheet').forEach((factSheetList) => {
        if(!(factSheetList instanceof HTMLElement)) {
            return;
        }

        factSheetList.querySelectorAll(ProductCarouselPopup.DefaultOptions.selectors.root).forEach(popupItem => {
            if (!(popupItem instanceof HTMLElement)) {
                return;
            }

            new ProductCarouselPopup(popupItem);
        })
    });
});