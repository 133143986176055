import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";
import {DeepPartial} from "../../base/util/deepPartial";
import assignOptions from "../../base/util/assignOptions";
import passiveEvent from "../../base/util/dom/passiveEvent";

interface DropdownOptions {
    Selectors: {
        root: string
    }
}

interface DropdownDOM {
    dropdownBtn: HTMLElement | null
    dropdownBtnLabel: HTMLElement | null
    dropdownMenu: HTMLElement | null
    dropdownCheck: HTMLInputElement | null
}

export default class Dropdown {

    public static readonly DefaultOptions = {
        Selectors: {
            root: '.dws__dropdown',
            initialization: '.dws__dropdown[data-dropdown-init]',
            trigger: '.dws__dropdown__btn',
            triggerLabel: '.dws__dropdown__btn-label',
            menu: '.dws__dropdown__menu',
            checkbox: '.dws__dropdown > input[type="checkbox"]'
        }
    }

    private readonly options : DropdownOptions
    private active: boolean = false

    private readonly dom: DropdownDOM

    public constructor(element: HTMLElement, options: DeepPartial<DropdownOptions> = {}) {
        this.options = assignOptions(options, Dropdown.DefaultOptions)

        this.dom = {
            dropdownBtn: element.querySelector(Dropdown.DefaultOptions.Selectors.trigger),
            dropdownBtnLabel: element.querySelector(Dropdown.DefaultOptions.Selectors.triggerLabel),
            dropdownMenu: element.querySelector(Dropdown.DefaultOptions.Selectors.menu),
            dropdownCheck: element.querySelector(Dropdown.DefaultOptions.Selectors.checkbox),
        }

        this.initEvents()

    }

    protected initEvents():void {
        if(!(this.dom.dropdownBtn) || !(this.dom.dropdownMenu)) {
            return
        }

        this.dom.dropdownBtn.addEventListener('click', (ev) => {
            if(this.active) {
                this.setInactive()
            } else {
                this.setActive()
            }
            this.active = this.active!
        }, passiveEvent)

        document.addEventListener('click', this.onDocumentClick.bind(this), passiveEvent);

    }

    private onDocumentClick(ev: MouseEvent) {
        if(!(ev.target instanceof HTMLElement)) {
            return;
        }

        if(ev.target.closest(Dropdown.DefaultOptions.Selectors.root)) {
            return;
        }

        this.active = false
        this.setInactive()
    }

    public setActive(): void {
        this.dom.dropdownBtn!.setAttribute('aria-expanded', 'true')
        this.dom.dropdownMenu!.setAttribute('aria-hidden', 'false')
    }

    public setInactive(): void {
        this.dom.dropdownBtn!.setAttribute('aria-expanded', 'false')
        this.dom.dropdownMenu!.setAttribute('aria-hidden', 'true')
        this.dom.dropdownCheck!.checked = false;
    }

    public setDropdownLabel(label: string): void {
        if (this.dom.dropdownBtnLabel && label) {
            this.dom.dropdownBtnLabel.textContent = label
        }
    }

}

onDOMContentLoaded(() => {
    document.querySelectorAll(Dropdown.DefaultOptions.Selectors.initialization).forEach((dropdownElement) => {
        if(!(dropdownElement instanceof HTMLElement)) {
            return
        }
        new Dropdown(dropdownElement, {})
    });
});
