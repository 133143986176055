import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

onDOMContentLoaded(() => {
    initCkeditor(document);
});

export default function initCkeditor(element: Document|Element) {
    element.querySelectorAll('.as__ckeditor').forEach((ckeElement) => {
        if(!(ckeElement instanceof HTMLElement)) {
            return;
        }

        if (document.initCkeditor) {
            document.initCkeditor(ckeElement);
        } else {
            // following code is compiling ckeditor.js into main.ts
            // import('./../../../../public/static/js/ckeditor.js')
            //     .then((ckeditorModule) => {
            //         ckeditorModule.initCkeditor(ckeElement)
            //     })

            loadScript('./../../../../static/js/ckeditor.js')
                .then(() => {
                    if (!document.initCkeditor) {
                        throw new Error('1669132416 – initCkeditor method is not defined');
                    }

                    document.initCkeditor(ckeElement);
                })
                .catch(err => {
                    console && console.error && console.error(err)
                });
        }
    });
}

// Src: https://stackoverflow.com/questions/7718935/load-scripts-asynchronously
// this function will work cross-browser for loading scripts asynchronously
function loadScript(src : string) {
    return new Promise(function (resolve, reject) {
        var s;
        s = document.createElement('script');
        s.src = src;
        s.onload = resolve;
        s.onerror = reject;
        document.head.appendChild(s);
    });
}
