import onDOMContentLoaded from "../../base/util/dom/onDOMContentLoaded";

export interface FormPWToggleElements {
    pwInputElement: HTMLElement | null,
    toggleElement: HTMLElement | null
}

export default class FormPWToggle {
    public static readonly Selectors = {
        Container: '.dws__pw-toggle__container',
        PWInput: '.dws__pw-toggle__input',
        Toggle: '.dws__pw-toggle__toggle'
    };

    public static readonly InputTypes = {
        Password: 'password',
        Text: 'text'
    };

    public static readonly InputAttribute = 'type'

    private elements : FormPWToggleElements;

    constructor(element: Element) {
        this.elements = {
            pwInputElement: element.querySelector(FormPWToggle.Selectors.PWInput),
            toggleElement: element.querySelector(FormPWToggle.Selectors.Toggle)
        }

        if (!(this.elements.toggleElement instanceof HTMLElement)) {
            throw new Error('could not toggle element');
        }

        this.elements.toggleElement.addEventListener('click', this.toggleInputType.bind(this));
    }

    public toggleInputType() : void {
        if (!(this.elements.pwInputElement instanceof HTMLElement)) {
            throw new Error('could not find password input element');
        }

        const inputAttr = this.elements.pwInputElement.getAttribute(FormPWToggle.InputAttribute);

        if (inputAttr === null) {
            return
        }

        if (inputAttr === FormPWToggle.InputTypes.Password) {
            this.elements.pwInputElement.setAttribute(FormPWToggle.InputAttribute, FormPWToggle.InputTypes.Text);
        } else {
            this.elements.pwInputElement.setAttribute(FormPWToggle.InputAttribute, FormPWToggle.InputTypes.Password);
        }
    }
}

onDOMContentLoaded(() => {
    document.querySelectorAll(FormPWToggle.Selectors.Container).forEach((element) => {
        new FormPWToggle(element);
    });
});
